import React, { useEffect, useState } from "react";
import axios from "axios";
import { Label } from "@mui/icons-material";

export const HttpServiceContext = React.createContext();

const apiURL = "https://api.vrddhim.org";

export const HttpServiceProvider = ({ children }) => {
  const [loginedUserId, setLoginedUserId] = useState("");
  const [loginedType, setLoginedType] = useState("");
  const [accessToken, setAccessToken] = useState("");

  useEffect(() => {
    if (!loginedUserId) setLoginedUserId(getCookie("userid"));
  }, []);

  function getCookie(cookieName) {
    const name = cookieName + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(";");
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i].trim();
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return null;
  }

  async function setAuthToken(token) {
    if (!token) {
      token = getCookie("token");
    }
    setAccessToken(token);
    if (token) {
      axios.defaults.headers["Authorization"] = `Bearer ${token}`;
    } else {
      // delete axios.defaults.headers.common["Authorization"];
    }
  }

  async function submitRegistrationForm(formData) {
    let url = `${apiURL}/register`;
    return axios
      .post(url, formData, { headers: { "Content-Type": "application/json" }, credentials: "include" })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  // async function submitLoginForm(payload, label) {
  //   let url = `${apiURL}/login?label=${label}`;
  //   return axios
  //     .post(url, payload, { headers: { "Content-Type": "application/json" }, credentials: "include" })
  //     .then((response) => {
  //       const token = response.data.token;
  //       // setAuthToken(token);
  //       // document.cookie = `token=${token}; path=/; Secure; SameSite=Strict`;
  //       return response;
  //     })
  //     .catch((error) => {
  //       return error.response;
  //     });
  // }

  async function submitLoginForm(payload, label) {

    let url = `${apiURL}/login?label=${label}`;
    return axios
      .post(url, payload, { headers: { "Content-Type": "application/json" }, credentials: "include" })
      .then((response) => {
        const token = response.data.token;
        // setAuthToken(token); // token handling logic if needed
        // document.cookie = `token=${token}; path=/; Secure; SameSite=Strict`;
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  async function checkIsLogined() {
    const token = getCookie("token");
    setAuthToken(token);

    let url = `${apiURL}/check_session`;
    return axios
      .get(url, { credentials: "include" })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  async function logoutUser() {
    const token = getCookie("token");
    setAuthToken(token);
    let label = "user";

    let url = `${apiURL}/logout?label=${label}`;
    return axios
      .get(url, { credentials: "include" })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }

  // async function sendOtp(email, label) {
  //   let url = `${apiURL}/send-otp`;
  //   try {
  //     const response = await axios.post(
  //       url,
  //       { email, label },
  //       {
  //         headers: { "Content-Type": "application/json" },
  //         withCredentials: true,
  //       }
  //     );
  //     return response.data;
  //   } catch (error) {
  //     console.error("Error sending OTP:", error.response ? error.response.data : error.message);
  //     return { error: error.response ? error.response.data : error.message };
  //   }
  // }

  // async function verifyOtp(email, otp, label) {
  //   let url = `${apiURL}/verify-otp`;
  //   try {
  //     const response = await axios.post(
  //       url,
  //       { email, otp, label },
  //       {
  //         headers: { "Content-Type": "application/json" },
  //         withCredentials: true,
  //       }
  //     );
  //     return response.data;
  //   } catch (error) {
  //     console.error("Error verifying OTP:", error.response ? error.response.data : error.message);
  //     return { error: error.response ? error.response.data : error.message };
  //   }
  // }

  async function getCourses(userId) {
    let url = `${apiURL}/admin/fetch/courses`;
    if (userId) {
      url += `?userid=${userId}`;
    }
    return axios
      .get(url, { headers: { "Content-Type": "application/json" }, credentials: "include" })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }

  async function getBatches(courseId) {
    console.log(`Fetching batches for course ID: ${courseId}`);
    let url = `${apiURL}/admin/fetch/batches/${courseId}`;

    try {
      const response = await axios.get(url, { withCredentials: true });
      console.log("API Response:", response);
      return response.data;
    } catch (error) {
      console.error("Error fetching batches:", error.response ? error.response.data : error.message);
      return { error: error.message || "An error occurred while fetching batches" };
    }
  }
  async function deleteBatch(batchId) {
    let url = `${apiURL}/admin/delete/batches/${batchId}`;
    return axios
      .delete(url, { credentials: "include" })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }
  async function addBatch(batchData) {
    const url = `${apiURL}/admin/add/batches`;

    return axios
      .post(url, batchData, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }

  async function editBatches(editedBatch) {
    let url = `${apiURL}/admin/edit/batches`;
    try {
      const response = await axios.put(url, editedBatch, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      console.error("Error updating batch:", error.response ? error.response.data : error.message);
      throw new Error(error.response ? error.response.data : error.message);
    }
  }

  async function editCourses(courses, courseId) {
    let url = `${apiURL}/admin/edit/courses/${courseId}`;
    return axios
      .put(url, courses, { headers: { "Content-Type": "application/json" }, credentials: "include" })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }

  async function addCourses(courses) {
    let url = `${apiURL}/admin/add/courses`;
    return axios
      .post(url, courses, { headers: { "Content-Type": "application/json" }, credentials: "include" })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }

  async function deleteCourse(courseId) {
    let url = `${apiURL}/admin/delete/courses/${courseId}`;
    return axios
      .delete(url, { credentials: "include" })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }

  async function getProfile() {
    let url = `${apiURL}/profile`;
    if (loginedType) {
      url += `?label=${loginedType}`;
    }
    return axios
      .get(url, { headers: { "Content-Type": "application/json" }, credentials: "include" })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }

  async function getUserDetails(userId, label) {
    let url = `${apiURL}/user/details`;
    if (userId) {
      url += `?userId=${userId}`;
    }
    if (label) {
      url += `&label=${label}`;
    }
    return axios
      .get(url, { headers: { "Content-Type": "application/json" }, credentials: "include" })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }

  async function updateProfile(profileData) {
    let url = `${apiURL}/edit_profile`;
    return axios
      .put(url, profileData, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      })
      .then((response) => response.data)
      .catch((error) => {
        return error.response;
      });
  }
  //////this is for the changing the profile password , only!!!
  async function changePassword(payload) {
    let url = `${apiURL}/edit_profile`;
    return axios
      .put(url, payload, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }
  async function forgotPasswordEmailVerify(email) {
    let url = `${apiURL}/forgot/password`;
    return axios
      .post(url, email, { headers: { "Content-Type": "application/json" }, credentials: "include" })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }
  async function verifyResetToken(token) {
    let url = `${apiURL}/reset/password?token=${token}`;
    return axios
      .get(url, { headers: { "Content-Type": "application/json" }, credentials: "include" })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }

  async function resetPassword(payload) {
    let url = `${apiURL}/reset/password`;
    return axios
      .post(url, payload, { headers: { "Content-Type": "application/json" }, credentials: "include" })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }

  async function sendMentorEmail(payload) {
    let url = `${apiURL}/mentor/email`;
    return axios
      .post(url, payload, { headers: { "Content-Type": "application/json", Authorization: `Bearer ${getCookie("token")}` }, credentials: "include" })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }

  //USER MANAGEMENT

  async function getRegisteredUsers() {
    let url = `${apiURL}/admin/users`;
    return axios
      .get(url, { headers: { "Content-Type": "application/json" }, credentials: "include" })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }

  async function deleteUsers(userId) {
    let url = `${apiURL}/admin/users/delete/${userId}`;
    return axios
      .delete(url, { headers: { "Content-Type": "application/json" }, credentials: "include" })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }

  async function blockUsers(userId) {
    let url = `${apiURL}/admin/users/block/${userId}`;
    return axios
      .put(url, { headers: { "Content-Type": "application/json" }, credentials: "include" })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }

  async function getUserEnrollments(userId) {
    let url = `${apiURL}/admin/users/enrollments/${userId}`;
    return axios
      .get(url, { headers: { "Content-Type": "application/json" }, credentials: "include" })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }

  async function getTrashedUsers() {
    let url = `${apiURL}/admin/users/trash`;
    return axios
      .get(url, { headers: { "Content-Type": "application/json" }, credentials: "include" })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }

  async function mentorRegistrationSubmit(payload) {
    let url = `${apiURL}/register/mentor`;
    return axios
      .post(url, payload, { headers: { "Content-Type": "application/json", Authorization: `Bearer ${getCookie("token")}` }, credentials: "include" })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }

  async function updatePaymentStatus(payload) {
    let url = `${apiURL}/update/payment/status`;
    return axios
      .put(url, payload, { headers: { "Content-Type": "application/json", Authorization: `Bearer ${getCookie("token")}` }, credentials: "include" })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }

  async function getMentorsData() {
    let url = `${apiURL}/fetch/mentors`;
    return axios
      .get(url, { headers: { "Content-Type": "application/json" }, credentials: "include" })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching mentors data:", error);
      });
  }

  async function getMentorsBatchDetails(mentor_id) {
    let url = `${apiURL}/mentor/fetch/batches?mentor_id=${mentor_id}`;
    return axios
      .get(url, { headers: { "Content-Type": "application/json" }, credentials: "include" })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching mentors data:", error);
      });
  }

  //Webrtc
  async function joinWebrtClass(payload) {
    let url = `${apiURL}/participants`;
    return axios
      .post(url, payload, { headers: { "Content-Type": "application/json", Authorization: `Bearer ${getCookie("token")}` }, credentials: "include" })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }

  //chat here

  async function fetchMessages(roomId) {
    let url = `${apiURL}/chat/messages?room_id=${roomId}`;
    return axios
      .get(url, { headers: { "Content-Type": "application/json" }, credentials: "include" })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }

  async function handleSendMessage(roomId, message) {
    let url = `${apiURL}/chat/messages`;
    const payload = { room_id: roomId, message };
    return axios
      .post(url, payload, { headers: { "Content-Type": "application/json" }, credentials: "include" })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }

  async function getParticipants(roomId) {
    let url = `${apiURL}/rooms/${roomId}/participants`;
    return axios
      .get(url, { headers: { "Content-Type": "application/json" }, credentials: "include" })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching mentors data:", error);
      });
  }

  async function verifyRegistration(token) {
    let url = `${apiURL}/register/success/${token}`;
    return axios
      .get(url, { headers: { "Content-Type": "application/json" }, credentials: "include" })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching mentors data:", error);
      });
  }

  async function getInvoiceData(paymentId) {
    let url = `${apiURL}/fetch/invoice?payment_id=${paymentId}`;
    return axios
      .get(url, { headers: { "Content-Type": "application/json" }, credentials: "include" })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching mentors data:", error);
      });
  }

  return (
    <HttpServiceContext.Provider
      value={{
        joinWebrtClass,
        setLoginedUserId,
        loginedUserId,
        getTrashedUsers,
        getUserEnrollments,
        blockUsers,
        fetchMessages,
        logoutUser,
        handleSendMessage,
        deleteUsers,
        getRegisteredUsers,
        setAuthToken,
        getCookie,
        deleteBatch,
        setAuthToken,
        submitRegistrationForm,
        submitLoginForm,
        getBatches,
        editBatches,
        getCourses,
        editCourses,
        updateProfile,
        addCourses,
        changePassword,
        addBatch,
        sendMentorEmail,
        deleteCourse,
        getProfile,
        forgotPasswordEmailVerify,
        verifyResetToken,
        resetPassword,
        checkIsLogined,
        getCookie,
        // verifyOtp,
        // sendOtp,
        mentorRegistrationSubmit,
        updatePaymentStatus,
        setLoginedType,
        loginedType,
        getMentorsData,
        getUserDetails,
        getMentorsBatchDetails,
        getParticipants,
        verifyRegistration,
        getInvoiceData,
      }}
    >
      {children}
    </HttpServiceContext.Provider>
  );
};
