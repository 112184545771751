import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

function UserBarChart() {
  const options = {
    chart: {
      type: "bar",
    },
    title: {
      text: "User Engagement Metrics",
      style: {
        color: "#333",
        fontWeight: "bold",
        fontSize: "18px",
      },
    },
    xAxis: {
      categories: ["January", "February", "March", "April", "May"],
      labels: {
        style: {
          color: "#333",
        },
      },
    },
    yAxis: {
      title: {
        text: "Metrics",
      },
      labels: {
        style: {
          color: "#333",
        },
      },
    },
    legend: {
      itemStyle: {
        color: "#333",
      },
    },
    series: [
      {
        name: "Logins",
        data: [100, 150, 200, 180, 210],
        color: "rgba(75, 192, 192, 0.8)",
      },
      {
        name: "Course Enrollments",
        data: [80, 120, 160, 140, 190],
        color: "rgba(153, 102, 255, 0.8)",
      },
      {
        name: "Class Participation",
        data: [90, 130, 170, 160, 200],
        color: "rgba(255, 159, 64, 0.8)",
      },
    ],
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: "{point.y}",
          style: {
            color: "#333",
          },
        },
      },
    },
  };

  return (
    <div>
      <h2>No of users enrolled </h2>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}

export default UserBarChart;
