import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo1 from "../../assets/images/vrddhim-logo.png";
import boxImage from "../../assets/images/loginsignupcover.png";
import { HttpServiceContext } from "../../config/httpContext";
import TermsAndConditions from "../termsandconditions";
import Tandc from "./tandc";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Signup = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    phone_number: "",
    country_code: "91",
    password: "",
    confirm_password: "",
  });
  const [errors, setErrors] = useState({});
  const [termsPage, setTermsPage] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const httpService = useContext(HttpServiceContext);
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleTerms = () => {
    setTermsPage(true);
  };

  const handleModalClose = () => {
    setTermsPage(false);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.username) newErrors.username = "Username is required";
    if (!formData.email) newErrors.email = "Email Address is required";
    if (!formData.phone_number) newErrors.phone_number = "Phone number is required";
    if (!formData.password) newErrors.password = "Password is required";
    if (formData.password !== formData.confirm_password) newErrors.confirm_password = "Passwords do not match";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    try {
      let response = await httpService.submitRegistrationForm(formData);
      if (!response?.data.error) {
        Swal.fire({
          title: "Registration Successful!",
          text: "Check your email for verification.",
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
          willClose: () => {
            navigate(`/mail-verification?email=${formData.email}`);
          },
        });
      } else {
        setErrors({ global: response.data.error });
        Swal.fire({
          title: "Error!",
          text: response.data.error,
          icon: "error",
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error("Error during registration:", error);
      const errorMessage =
        error.response && error.response.status === 409 ? "Username or email already exists." : "Registration failed. Please try again.";
      setErrors({ global: errorMessage });
      Swal.fire({
        title: "Error!",
        text: errorMessage,
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <div className="row border rounded-5 background-primary shadow box-area">
        <div className="col-md-6 d-flex justify-content-center align-items-center flex-column left-box">
          <div className="featured-image">
            <img src={boxImage} className="img-fluid" alt="Featured" />
          </div>
        </div>

        <div className="col-md-6 right-box">
          <div className="col-12 my-2 justify-content-around d-flex">
            <img src={logo1} alt="Vrddhim Logo" className="logo-img" style={{ width: "90px", height: "120px" }} />
          </div>
          <div className="row align-items-center">
            <div className="header-text mb-2">
              <h4 className="fc-grey text-center">Create an Account</h4>
              <div className="d-flex justify-content-center align-items-center mt-2 fc-grey">
                <small> Already Have an account?</small>
                <a href="/login" className="fc-grey p-2">
                  Login
                </a>
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="input-group mb-2 d-block">
                <input
                  type="text"
                  name="username"
                  className="form-control fs-6 w-100"
                  placeholder="Username"
                  onChange={handleChange}
                  value={formData.username}
                />
                {errors.username && <div className="text-danger float-end mb-2">{errors.username}</div>}
              </div>
              <div className="input-group mb-2 d-block">
                <input
                  type="email"
                  name="email"
                  className="form-control fs-6 w-100"
                  placeholder="Email Address"
                  onChange={handleChange}
                  value={formData.email}
                />
                {errors.email && <div className="text-danger float-end mb-2">{errors.email}</div>}
              </div>
              <div className="input-group mb-2">
                <select
                  name="country_code"
                  className="form-select fs-6"
                  value={formData.country_code}
                  onChange={handleChange}
                  style={{ width: "20%" }}
                >
                  <option value="91">+91</option>
                  <option value="1">+1</option>
                  <option value="44">+44</option>
                  <option value="61">+61</option>
                  <option value="81">+81</option>
                </select>
                <input
                  type="text"
                  name="phone_number"
                  className="form-control fs-6"
                  placeholder="Phone Number"
                  onChange={handleChange}
                  value={formData.phone_number}
                  style={{ width: "80%" }}
                />

                {errors.phone_number && <div className="text-danger float-end mb-2">{errors.phone_number}</div>}
              </div>
              <div className="input-group mb-2 d-block position-relative">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  className="form-control fs-6 w-100"
                  placeholder="Password"
                  onChange={handleChange}
                  value={formData.password}
                />
                <span
                  onClick={() => setShowPassword(!showPassword)}
                  style={{ cursor: "pointer", position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)" }}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
              <div className="input-group mb-2 d-block position-relative">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirm_password"
                  className="form-control fs-6 w-100"
                  placeholder="Confirm Password"
                  onChange={handleChange}
                  value={formData.confirm_password}
                />
                <span
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  style={{ cursor: "pointer", position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)" }}
                >
                  {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
                {errors.password && <div className="text-danger float-end mb-2">{errors.password}</div>}
                {errors.confirm_password && <div className="text-danger float-end mb-2">{errors.confirm_password}</div>}
                {errors.global && <div className="text-danger mb-3 float-end mb-2">{errors.global}</div>}
              </div>

              <div className="input-group mb-3">
                <div className="form-check display-flex align-items-center">
                  <input type="checkbox" className="form-check-input" checked={isChecked} onChange={handleCheckboxChange} id="formCheck" />
                  <label className="form-check-label text-secondary pt-1">
                    <small className="mx-1">
                      Agree to
                      <span onClick={handleTerms}> Terms and Conditions</span>
                    </small>
                  </label>
                </div>

                {/* New Checkbox for WhatsApp Updates */}
                <div className="form-check display-flex align-items-center mt-1">
                  <input type="checkbox" className="form-check-input" id="whatsappUpdates" />
                  <label className="form-check-label text-secondary pt-1">
                    <small className="mx-1">
                      Receive updates via <strong>WhatsApp</strong>
                    </small>
                  </label>
                </div>
              </div>

              <div className="input-group">
                <button type="submit" className="btn btn-lg bttn-primary w-100 fs-6" disabled={!isChecked}>
                  Register
                </button>
              </div>
            </form>
            <div>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <Modal show={termsPage} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="background-primary">
          <div className="terms-modal">
            <Tandc handleCheckboxChange={handleCheckboxChange} isChecked={isChecked} />
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};

export default Signup;
