import React from "react";
import Navbar from "./navbar";
import Footer from "./home/vrddhim-footer";

const ContactUs = () => {
  window.scrollTo(0, 0);

  return (
    <>
      <Navbar></Navbar>
      <section className="vrddhim-contact-us" id="contact">
        <div className="container my-3">
          <div className="row">
            <div className="col-lg-9 align-self-center">
              <div className="row">
                <div className="col-lg-12">
                  <form id="contact" action="" method="post">
                    <div className="row">
                      <div className="col-lg-12">
                        <h2 className="elegant-heading text-center py-1">Connect with the Team Vrddhim</h2>
                      </div>
                      <div className="col-lg-4">
                        <fieldset>
                          <input name="name" type="text" id="name" placeholder="YOUR NAME...*" required />
                        </fieldset>
                      </div>
                      <div className="col-lg-4">
                        <fieldset>
                          <input name="email" type="text" id="email" pattern="[^ @]*@[^ @]*" placeholder="YOUR EMAIL..." required />
                        </fieldset>
                      </div>
                      <div className="col-lg-4">
                        <fieldset>
                          <input name="subject" type="text" id="subject" placeholder="SUBJECT...*" required />
                        </fieldset>
                      </div>
                      <div className="col-lg-12">
                        <fieldset>
                          <textarea name="message" className="vrddhim-form-control" id="message" placeholder="YOUR MESSAGE..." required></textarea>
                        </fieldset>
                      </div>
                      <div className="col-lg-12">
                        <fieldset>
                          <button type="submit" id="form-submit" className="vrddhim-button bttn-primary">
                            SEND MESSAGE NOW
                          </button>
                        </fieldset>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="vrddhim-right-info">
                <ul>
                  <li>
                    <h6>Phone Number</h6>
                    <span>
                      <a href="tel:+919008358981">+91 9008358981</a>
                    </span>
                  </li>
                  <li>
                    <h6>Email Address</h6>
                    <span>
                      <a href="mailto:info@vrddhim.org">info@vrddhim.org</a>
                    </span>
                  </li>

                  <li>
                    <h6>Website URL</h6>
                    <span>
                      <a href="https://vrddhim.org" target="_blank" rel="noopener noreferrer">
                        https://vrddhim.org
                      </a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
};

export default ContactUs;
