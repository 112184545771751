// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.react-calendar {
  background-color: #f1f9f1;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.react-calendar__navigation {
  background-color: #a8d5a5;
  color: #fff;
  font-weight: bold;
}

.react-calendar__tile {
  padding: 1em;
  border-radius: 8px;
}

.react-calendar__tile--now {
  background-color: #e3f9e1;
  border: 2px solid #a8d5a5;
}

.react-calendar__tile--active {
  background-color: #a8d5a5;
  color: white;
}

.highlight {
  background-color: #d4f0d4 !important;
  color: #333;
  font-weight: bold;
  border: 2px solid #4caf50;
}

@media (max-width: 768px) {
  .react-calendar {
    font-size: 0.9em;
    padding: 15px;
  }
}
@media (max-width: 576px) {
  .react-calendar {
    font-size: 0.8em;
    padding: 10px;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/pages/calendarpage.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;AACF;;AAEA;EACE,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,uCAAA;AACF;;AAEA;EACE,yBAAA;EACA,WAAA;EACA,iBAAA;AACF;;AAEA;EACE,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,yBAAA;EACA,yBAAA;AACF;;AAEA;EACE,yBAAA;EACA,YAAA;AACF;;AAEA;EACE,oCAAA;EACA,WAAA;EACA,iBAAA;EACA,yBAAA;AACF;;AAEA;EACE;IACE,gBAAA;IACA,aAAA;EACF;AACF;AAEA;EACE;IACE,gBAAA;IACA,aAAA;EAAF;AACF","sourcesContent":[".calendar-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: #fff;\n}\n\n.react-calendar {\n  background-color: #f1f9f1;\n  padding: 20px;\n  border-radius: 10px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n}\n\n.react-calendar__navigation {\n  background-color: #a8d5a5;\n  color: #fff;\n  font-weight: bold;\n}\n\n.react-calendar__tile {\n  padding: 1em;\n  border-radius: 8px;\n}\n\n.react-calendar__tile--now {\n  background-color: #e3f9e1;\n  border: 2px solid #a8d5a5;\n}\n\n.react-calendar__tile--active {\n  background-color: #a8d5a5;\n  color: white;\n}\n\n.highlight {\n  background-color: #d4f0d4 !important;\n  color: #333;\n  font-weight: bold;\n  border: 2px solid #4caf50;\n}\n\n@media (max-width: 768px) {\n  .react-calendar {\n    font-size: 0.9em;\n    padding: 15px;\n  }\n}\n\n@media (max-width: 576px) {\n  .react-calendar {\n    font-size: 0.8em;\n    padding: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
