import React, { useState } from "react";
import Navbar from "./navbar";
import Footer from "./home/vrddhim-footer";

const HelpSupport = () => {
  window.scrollTo(0, 0);

  const [issueType, setIssueType] = useState("");
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Logic to submit the ticket (e.g., API call)
    setSubmitted(true);
  };

  return (
    <>
      <Navbar />{" "}
      <div className="container py-5">
        <h2 className="text-center elegant-heading">Help & Support</h2>
        <p className="graceful-paragraph text-center">
          If you are facing any issues or have questions, please select the issue type and provide details to raise a ticket. Our support team will
          get back to you soon.
        </p>

        {submitted ? (
          <div className="alert alert-success">Your ticket has been submitted successfully! We will get back to you soon.</div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="issueType" className="form-label">
                Select Issue Type
              </label>
              <select id="issueType" className="form-select" value={issueType} onChange={(e) => setIssueType(e.target.value)} required>
                <option value="">Choose...</option>
                <option value="account">Account Related</option>
                <option value="course">Course Related</option>
                <option value="payment">Payment Related</option>
                <option value="technical">Technical Issue</option>
                <option value="other">Other</option>
              </select>
            </div>

            <div className="mb-3">
              <label htmlFor="description" className="form-label">
                Description
              </label>
              <textarea
                id="description"
                className="form-control"
                rows="4"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Please describe your issue in detail..."
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email Address
              </label>
              <input
                type="email"
                id="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Your email"
                required
              />
            </div>

            <button type="submit" className="btn bttn-primary">
              Submit Ticket
            </button>
          </form>
        )}
      </div>
      <Footer />
    </>
  );
};

export default HelpSupport;
