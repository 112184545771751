import React, { useEffect } from "react";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import DataGridFooter from "./course-overview-footer";
import ActionMenu from "./course-overview-actionmenu";
import { rows } from "../data/TaskOverviewData";
import "../../styles/pages/course-overview-table.module.scss";

const columns = [
  {
    field: "task",
    headerName: "Subjects",
    editable: false,
    align: "left",
    flex: 1,
    minWidth: 180,
  },
  {
    field: "members",
    headerName: "Mentors",
    editable: false,
    sortable: false,
    align: "left",
    flex: 1,
    minWidth: 120,
    renderCell: (params) => (
      <Stack height={1} alignItems="center">
        <AvatarGroup max={3}>
          {params.value.map((avatar) => (
            <Avatar key={avatar} alt="avatar_img" src={avatar} />
          ))}
        </AvatarGroup>
      </Stack>
    ),
  },
  {
    field: "progress",
    headerName: "Progress",
    editable: false,
    align: "left",
    flex: 1.5,
    minWidth: 140,
    renderCell: (params) => (
      <Stack alignItems="center" gap={1} pr={1} height={1} width={1}>
        <Typography variant="body2" style={{ minWidth: 30 }}>
          {params.value}%
        </Typography>
        <LinearProgress
          variant="determinate"
          value={params.value}
          style={{
            width: "100%",
            height: 5,
            borderRadius: 4,
            transition: "width 0.4s ease-in-out",
          }}
        />
      </Stack>
    ),
  },
  {
    field: "status",
    headerName: "Status",
    headerAlign: "center",
    editable: false,
    flex: 1,
    minWidth: 100,
    renderCell: (params) => {
      const color =
        params.value === "in progress" ? "primary" : params.value === "completed" ? "success" : params.value === "pending" ? "warning" : "info";
      return (
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          height={1}
          style={{
            transition: "transform 0.3s ease",
            transform: "scale(1)",
            "&:hover": { transform: "scale(1.05)" },
          }}
        >
          <Chip label={params.value} size="small" color={color} />
        </Stack>
      );
    },
  },
  {
    field: "timeLeft",
    headerName: "Time Left",
    headerAlign: "right",
    align: "right",
    editable: false,
    flex: 1,
    minWidth: 80,
  },
  {
    field: "action",
    headerAlign: "right",
    align: "right",
    editable: false,
    sortable: false,
    flex: 0.5,
    minWidth: 70,
    renderHeader: () => <ActionMenu />,
    renderCell: () => <ActionMenu />,
  },
];

const TaskOverviewTable = ({ searchText = "" }) => {
  const apiRef = useGridApiRef();

  useEffect(() => {
    if (searchText) {
      const filters = searchText.split(/\b\W+\b/).filter((word) => word !== "");
      apiRef.current.setQuickFilterValues(filters);
    }
  }, [searchText]);

  return (
    <DataGrid
      apiRef={apiRef}
      density="standard"
      columns={columns}
      rows={rows}
      rowHeight={70}
      disableColumnResize
      disableColumnMenu
      disableColumnSelector
      disableRowSelectionOnClick
      initialState={{
        pagination: { paginationModel: { pageSize: 5 } },
      }}
      autosizeOptions={{
        includeOutliers: true,
        includeHeaders: false,
        outliersFactor: 1,
        expand: true,
      }}
      slots={{
        pagination: DataGridFooter,
      }}
      checkboxSelection
      pageSizeOptions={[5]}
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "#f5f5f5",
        borderRadius: "8px",
        boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.2)",
        transition: "all 0.3s ease-in-out",
      }}
    />
  );
};

export default TaskOverviewTable;
