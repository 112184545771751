import React from "react";
import "../../styles/pages/coverImage.scss";
import coverimage from "../../assets/images/blog-cover.webp";

const BlogCover = () => {
  return (
    <section>
      <div className="bg-holder" style={{ backgroundImage: `url(${coverimage})` }}></div>
    </section>
  );
};

export default BlogCover;
