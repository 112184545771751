import React from "react";

const NotFound = () => {
  return (
    <div className="container d-flex justify-content-center align-items-center py-5 vh-100" style={{ backgroundColor: "#f9f9f9" }}>
      <div className="text-center">
        <h1 className="display-1" style={{ fontSize: "8rem", color: "#6c757d" }}>
          404
        </h1>
        <h2 className="mb-4" style={{ color: "#343a40" }}>
          Oops! Page Not Found
        </h2>
        <p className="lead mb-4" style={{ color: "#6c757d" }}>
          The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
        </p>

        <a href="/home" className="btn bttn-primary btn-lg">
          Go Back to Homepage
        </a>
      </div>
    </div>
  );
};

export default NotFound;
