import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../navbar";
import Footer from "../home/vrddhim-footer";
import "../../styles/pages/CourseCard.scss";
import { Modal } from "react-bootstrap";
import Login from "../authentication/login";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { HttpServiceContext } from "../../config/httpContext";
import cardImage from "../../assets/images/vrddhimCard.jpg";

const CoursesSection = () => {
  const [courses, setCourses] = useState([]);
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const navigate = useNavigate();
  window.scrollTo(0, 0);

  const httpService = useContext(HttpServiceContext);

  useEffect(() => {
    console.log("Courses:", courses);
    console.log("Enrolled Courses:", enrolledCourses);
  }, [courses, enrolledCourses]);

  useEffect(() => {
    checkLoginStatus();
  }, []);

  useEffect(() => {
    if (httpService.loginedUserId) {
      getCourseData();
    }
  }, [httpService.loginedUserId]);

  useEffect(() => {
    getCourseData();
    checkLoginStatus();
    if (!httpService.loginedUserId) {
      let userId = httpService.getCookie("userid");
      if (!userId) setShowLoginModal(true);
    }
  }, []);

  const checkLoginStatus = async () => {
    let response = await httpService.checkIsLogined();
    if (response?.data?.user_id) {
      httpService.setLoginedUserId(response.data.user_id);
      document.cookie = `userid=${response.data.user_id}; path=/  ; SameSite=Strict`;
      setIsLoggedIn(true);
    } else {
      console.error("Error checking login status:", response?.data.error);
    }
  };

  async function getCourseData() {
    let courseList = await httpService.getCourses(httpService.loginedUserId);
    console.log("Fetched courses:", courseList);
    if (courseList) {
      const formattedCourses = courseList.courses.map((item) => ({
        course_id: item.course_id,
        course_name: item.course_name,
        description: item.description,
        price: item.price,
        startDate: item.startDate,
        image: cardImage,
        visibility: item.visibility,
        enrolled: item.enrolled,
      }));

      const enrolledCourses = formattedCourses.filter((course) => course.enrolled === true);
      const nonEnrolledCourses = formattedCourses.filter((course) => course.enrolled === false);

      setEnrolledCourses(enrolledCourses);
      setCourses(nonEnrolledCourses);
      setLoading(false);
    }
  }
  // async function getCourseData() {
  //   let courseList = await httpService.getCourses(httpService.loginedUserId);
  //   console.log("Fetched courses:", courseList);

  //   if (courseList && courseList.courses) {
  //     const formattedCourses = courseList.courses.map((item) => ({
  //       course_id: item.course_id,
  //       course_name: item.course_name,
  //       description: item.description,
  //       price: item.price,
  //       startDate: item.startDate,
  //       image: cardImage,
  //       visibility: item.visibility,
  //       enrolled: item.enrolled,
  //     }));

  //     const enrolledCourses = formattedCourses.filter((course) => course.enrolled === true);
  //     const nonEnrolledCourses = formattedCourses.filter((course) => course.enrolled === false);

  //     setEnrolledCourses(enrolledCourses);
  //     setCourses(nonEnrolledCourses);

  //     console.log("Enrolled Courses:", enrolledCourses);
  //     console.log("Non-Enrolled Courses:", nonEnrolledCourses);

  //     setLoading(false);
  //   } else {
  //     console.error("No courses found in the response");
  //   }
  // }

  const handleCourseClick = (course) => {
    if (isLoggedIn) {
      navigate(`/course-detail/${course.course_id}`, {
        state: { courseDetails: course },
      });
    } else {
      setShowLoginModal(true);
    }
  };

  function closeModal() {
    setShowLoginModal(false);
  }

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
        <p>Loading courses...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-container">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div>
      <Navbar />
      {enrolledCourses.length !== 0 && (
        <div className="container my-3">
          <div className=" cards-container ">
            <div className="elegant-heading fs-4 text-center">Enrolled Courses</div>
            <div className="row">
              {enrolledCourses.map(
                (course) =>
                  course.visibility && (
                    <div key={course.course_id} className="col-md-6 col-lg-4 mb-4 d-flex justify-content-center">
                      <div className="course-card">
                        <img src={course.image} alt={course.course_name} className="course-card-image" />

                        <div className="card-content p-4">
                          <h4 className="MuiTypography-h5">{course.course_name}</h4>
                          <div className="line-under-starts-from"></div>
                          <p>{course.description}</p>
                          <div className="enroll-box">
                            <button className="btn bttn-primary" onClick={() => handleCourseClick(course)}>
                              View <i className="fas fa-angle-right arrow-icon"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>
        </div>
      )}

      {courses.length !== 0 && (
        <div className="container my-3">
          <section className="courses-page" id="courses">
            <div className="cards-container">
              <div className="text-center fs-4 p-3 elegant-heading">Explore our most popular courses designed for your success.</div>

              <div className="row">
                {courses.map(
                  (course) =>
                    course.visibility && (
                      <div key={course.course_id} className="col-md-6 col-lg-4 mb-4 d-flex justify-content-center">
                        <div className="course-card">
                          <img src={course.image} alt={course.course_name} className="course-card-image" />
                          <div className="card-content p-4">
                            <h4 className="MuiTypography-h5">{course.course_name}</h4>
                            <div className="line-under-starts-from"></div>
                            <p>{course.description}</p>
                            <div className="enroll-box">
                              <button className="btn bttn-primary" onClick={() => handleCourseClick(course)}>
                                Register <i className="fas fa-angle-right arrow-icon"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                )}
              </div>
            </div>
          </section>
        </div>
      )}
      {!httpService.loginedUserId && (
        <div className="container cards-container my-4">
          <h2 className="elegant-heading text-center">You Must be loged in to view Courses</h2>
        </div>
      )}

      <Modal size="lg" show={showLoginModal} onHide={() => setShowLoginModal(false)} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Login></Login>
        </Modal.Body>
      </Modal>

      <Footer marginTop="0px" />
    </div>
  );
};

export default CoursesSection;
