import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";
import "./styles/style.scss";
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import HomePage from "./components/home/vrddhim-homepage";
import MentorDashboard from "./components/MentorDashboard";
import Signup from "./components/authentication/signup";
import Login from "./components/authentication/login";
import Forgotpasword from "./components/authentication/forgotpassword";
import MailVerification from "./components/authentication/MailVerification";
import EmailVerified from "./components/authentication/EmailVerified";
import Newpassword from "./components/authentication/resetPassword";
import ContactUs from "./components/contactUs";
import CourseSection from "./components/courses/coursesSection";
import ClassLive from "./components/courses/classlive";
import CourseDetails from "./components/courses/courseDetail";
import Profile from "./components/profile";
import Notification from "./components/home/vrd-notification";
import Enrollment from "./components/courses/Enrollment";
import Payments from "./components/courses/Payments";
import ClassLiveMentors from "./components/courses/classLiveMentors";
import PaymentSuccess from "./components/courses/PaymentSuccess";

// Admin components
import Sidebar from "./components/common/admin-sidebar";
import Header from "./components/common/admin-navigationbar";
import Dashboard from "./components/admin-overview/admin-overview";
import Users from "./components/users-management/users-management";
import CourseManagement from "./components/course-management/course-management";
import Analytics from "./components/site-analytics/site-analytics";
import AdminNotification from "./components/common/admin-notification";
import AdminProfile from "./components/common/admin-profile";
import NotFound from "./components/common/page-not-found";
// import ChatComponent from "./components/chatcomponent";
import Footer from "./components/common/admin-footer";
import PaymentsAnalytics from "./components/payments-analytics/payments-analytics";

// Authentication components
import SignupPage from "./components/auth/signuppage";

import AdminMentors from "./components/mentors-management/mentors-management";
import MentorRegistration from "./components/mentors-management/MentorRegistration";
import TermsAndConditions from "./components/termsandconditions";

import VrddhimBlogpage from "./components/blog/vrddhim-blogpage";
import VrddhimAboutpage from "./components/about/vrddhim-aboutpage";
import { useContext, useEffect, useState } from "react";
import EnrolledCourseDetail from "./components/courses/enrolledCourseDetail";
import { HttpServiceContext } from "./config/httpContext";
import Invoice from "./components/payments-analytics/Invoice";
import { Modal } from "react-bootstrap";
import PrivacyPolicy from "./components/privacypolicy";
import CookieSetting from "./components/cookiesettings";
import HelpSupport from "./components/helpandsupport";
import Locations from "./components/locations";
import CalendarPage from "./components/calender";
import Tandc from "./components/authentication/tandc";
import LeaveClass from "./components/courses/leaveclass";
import RegistrationComingSoon from "./components/courses/comingsoon";
import Videosdk from "./components/courses/videosdk";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const [homeRoute, setHomeRoute] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(true);
  const httpService = useContext(HttpServiceContext);

  const isAuthRoute = location?.pathname === "/admin/login";
  const noFooterRoutes = ["/admin/profile", "/admin/notifications", "/admin/chat", "/admin/users"];
  const showFooter = !noFooterRoutes.includes(location?.pathname);
  const isAdminRoute = location?.pathname.startsWith("/admin") && !isAuthRoute;

  // Function to check login status
  const checkLoginStatus = async () => {
    let response = await httpService.checkIsLogined();
    if (response?.data?.user_id) {
      httpService.setLoginedUserId(response.data.user_id);
      document.cookie = `userid=${response.data.user_id}; path=/  ; SameSite=Strict`;

      if (location?.pathname.startsWith("/admin/login")) {
        navigate("/admin");
        setShowLoginModal(false);
      }
    } else {
      console.error("Error checking login status:", response?.data?.error);
    }
  };

  useEffect(() => {
    setHomeRoute(
      location?.pathname === "/" ||
        isAdminRoute ||
        location.pathname.startsWith("/liveclass/") ||
        location.pathname.startsWith("/register/success/") ||
        location.pathname.startsWith("/register") ||
        location.pathname.startsWith("/login") ||
        location.pathname.startsWith("/forgotpassword")
    );

    const loginedType = location?.pathname.startsWith("/admin") ? "admin" : "user";
    httpService.setLoginedUserId(httpService.getCookie("userid"));
    httpService.setLoginedType(loginedType);
    document.cookie = `loginedType=${loginedType}; path=/; SameSite=Strict`;
  }, [location]);

  useEffect(() => {
    checkLoginStatus();
    if (!httpService.loginedUserId) {
      let userId = httpService.getCookie("userid");
      if (!userId) setShowLoginModal(true);
    }
  }, []);

  const closeModal = () => {
    setShowLoginModal(false);
  };
  return (
    <>
      <div className="app">
        {!showLoginModal && isAdminRoute && <Sidebar />}
        <div className={`main-content ${!homeRoute ? "webUI" : ""} ${isAuthRoute ? "auth-content" : ""}`}>
          {!showLoginModal && isAdminRoute && <Header />}
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/register" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/mentor/login" element={<Login />} />
            <Route path="/courses" element={<CourseSection />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/course-detail/:courseId" element={<CourseDetails />} />
            <Route path="/liveclass/*" element={<ClassLive />} />
            <Route path="/liveclasssdk/*" element={<Videosdk />} />
            <Route path="/leaveclass" element={<LeaveClass />} />
            <Route path="/forgotpassword" element={<Forgotpasword />} />
            <Route path="/mail-verification" element={<MailVerification />} />
            <Route path="/register/success/*" element={<EmailVerified />} />
            <Route path="/forgotpassword/newpassword" element={<Newpassword />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/notification" element={<Notification />} />
            <Route path="/about" element={<VrddhimAboutpage />} />
            <Route path="/blog" element={<VrddhimBlogpage />} />
            <Route path="/enrollment" element={<Enrollment />} />
            <Route path="/payments" element={<Payments />} />
            <Route path="/liveclass/mentors" element={<ClassLiveMentors />} />
            <Route path="/payment-success" element={<PaymentSuccess />} />
            <Route path="/termsandconditions" element={<TermsAndConditions />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/cookiesettings" element={<CookieSetting />} />
            <Route path="/helpandsupport" element={<HelpSupport />} />
            <Route path="/locations" element={<Locations />} />
            <Route path="/calendar" element={<CalendarPage />} />
            <Route path="/comingsoon" element={<RegistrationComingSoon />} />

            <Route path="/tandc" element={<Tandc />} />
            <Route path="/enrolled/course" element={<EnrolledCourseDetail />} />
            <Route path="/invoice" element={<Invoice />} />

            {/* Admin Routes */}
            <Route path="/admin/*" element={<Dashboard />} />
            <Route path="/admin/dashboard" element={<Dashboard />} />
            <Route path="/admin/users" element={<Users />} />
            <Route path="/admin/courses" element={<CourseManagement />} />
            <Route path="/admin/mentors" element={<AdminMentors />} />
            <Route path="/admin/payments" element={<PaymentsAnalytics />} />
            <Route path="/admin/analytics" element={<Analytics />} />
            <Route path="/admin/notifications" element={<AdminNotification />} />
            <Route path="/admin/profile" element={<AdminProfile />} />
            {/* <Route path="/admin/chat" element={<ChatComponent />} /> */}
            <Route path="/admin/login" element={<Login />} />
            <Route path="/register/admin" element={<SignupPage />} />

            {/* Mentor Routes */}
            <Route path="/register/mentor/*" element={<MentorRegistration />} />
            <Route path="/mentor/dashboard" element={<MentorDashboard />} />

            {/* Catch-all route for 404 */}
            <Route path="*" element={<NotFound />} />
          </Routes>
          {isAdminRoute && showFooter && <Footer />}
        </div>
      </div>
      {/* {!httpService.loginedUserId && (
        <div className="container cards-container my-4">
          <h2 className="elegant-heading text-center">You Must be logged in to gain access to admin panel</h2>
        </div>
      )} */}
      {isAdminRoute && (
        <Modal size="lg" show={showLoginModal} onHide={closeModal} centered>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Login />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default App;
