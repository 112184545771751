import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import { Autoplay, EffectFade } from "swiper/modules";
import { Link } from "react-router-dom";
import "../../styles/pages/landing.scss";
import backgroundImage from "../../assets/images/vrddhim-landing.jpg";

function Landing() {
  return (
    <section className="py-0">
      <Swiper
        modules={[Autoplay, EffectFade]}
        loop={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        effect="fade"
        speed={3000}
      >
        <SwiperSlide>
          <div
            className="bg-holder-here"
            style={{
              backgroundImage: `url(${backgroundImage})`,
            }}
          >
            <div className="overlay d-flex align-items-center justify-content-center">
              <div className="text-center text-white wow fadeInUp">
                <h3 className=" mb-2 text-white">Training Academy for English Excellence</h3>
                <h2 className="mb-2">
                  Master the <span className="fw-bold">Art Of Communication at Vrddhim & Excel</span>
                </h2>
                <Link to="/courses">
                  <button className="btn  border-rounded bttn-primary">Join Us Now!</button>
                </Link>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
}

export default Landing;
