import { useLayoutEffect } from "react";
import Navbar from "../navbar";
import CoreStrength from "./vrddhim-corestrength";
import Footer from "./vrddhim-footer";
import Joinus from "./vrddhim-joinus";
import Landing from "./vrddhim-landing";
import VrddhimStory from "./vrddhim-story";
import Testimonials from "./vrddhim-testimonials";
import ChatBox from "./whatsappchat";
// import Notification from "./vrd-notification";

function HomePage() {
  window.scrollTo(0, 0);

  return (
    <>
      <Navbar />
      <Landing />
      <CoreStrength />
      <VrddhimStory />
      {/* <Notification /> */}
      <Joinus />
      <ChatBox />
      <Testimonials />
      <Footer />
    </>
  );
}

export default HomePage;
