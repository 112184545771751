import React from "react";
import "../../styles/pages/BlogPage.scss";
import growthImage from "../../assets/images/growth-progress.jpg";
import teamImage from "../../assets/images/our-team.jpg";
import crowdImage from "../../assets/images/Crowd.png";
import jackImage from "../../assets/images/jack.jpg";
import successfullImage from "../../assets/images/Successfull.jpg";

const VrddhimBlog = () => {
  return (
    <section className="blog-section">
      <div className="container my-5 story-container">
        <h2 className="elegant-heading text-center">Unlocking the Art of English Mastery with “Vrddhim”</h2>
        <div>
          <p>
            In today&#39;s globalized world, the ability to communicate effectively in English is more than just a skill—it&#39;s a gateway to
            success. Whether in business, academics, or social interactions, fluency in English empowers individuals to express their ideas clearly,
            build strong relationships, and seize new opportunities.
          </p>
          <p>
            At Vrddhim, we understand the profound impact of this business language. Mastering English can add a great value on one&#39;s personal and
            professional life. This is why we have dedicated ourselves with our enriched exposure &amp; knowledge in L&amp;D to providing an
            unparalleled learning experience that goes beyond traditional language instruction to support all our Vrddhim learners to achieve their
            English learning goals with great ease &amp; confidence.
          </p>
        </div>
      </div>
      <div className="story-container container">
        <section id="vrddhim-blog" className="container mb-4 mt-4">
          <h2 className="elegant-heading text-center">The Vrddhim Approach to Language Mastery</h2>
          <div className="row align-items-center">
            <div className="col-md-6">
              <p className=" text-justify">
                Let me share one of our true experiences - This goes back to 2006; we were conducting campus to corporate training to final year MBA
                &amp; Engineering students for one of the renowned colleges in South India.
              </p>
              <p className=" text-justify">
                It was a crowd of 200 students, an amazing bunch of excited students. We were almost at the end of the session, one loud voice emerged
                out of the crowd “Hello Madam”, I have a question for you (this was asked in a local language), This gentleman didn’t seem to like the
                idea of learning or the importance of English being emphasised.
              </p>
            </div>
            <div className="col-md-6">
              <div className="vrddhim-img-wrapper">
                <img src={crowdImage} alt="Growth and Progress" />
              </div>
            </div>
          </div>
        </section>

        <section id="vrddhim-blog" className="container mb-4 mt-4">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="vrddhim-img-wrapper">
                <img src={jackImage} alt="Jack and Progress" />
              </div>
            </div>
            <div className="col-md-6">
              <p className=" text-justify">He asked, you think I can survive &amp; become successful in my career only if I knew English well?</p>
              <p className=" text-justify">
                So, I answered – This is a fantastic question sir, let me give the crowd a scenario to answer your question. Scenario: You’re driving
                your car on a highway &amp; you have a flat tyre. What would you do?
              </p>
              <ul className="custom-bullet-list">
                <li>1. Hitchhike</li>
                <li>2. Call for a mechanic</li>
                <li>3. Take the jack, fix the tyre &amp; get going without anyone’s help</li>
              </ul>
            </div>
          </div>
        </section>

        <section id="vrddhim-blog" className="container mb-4 mt-4">
          <div className="row align-items-center">
            <div className="col-md-6">
              <p className="subtitle mb-4">95% of the students from the crowd opted option 3</p>
              <p className="description">
                So English is like your jack, if you know how to use it, life becomes easier. With the right knowledge of the business language or
                English gives you the confidence both professionally &amp; personally which in turn opens out doors for great opportunities.
              </p>
              <p className="description">
                This was the answer given to that gentleman. Today he works in the US with one of the top IT companies. At Vrddhim we have created
                various real time techniques to use this Jack &amp; have you experience wonderful opportunities &amp; feel confident.
              </p>
            </div>
            <div className="col-md-6">
              <div className="vrddhim-img-wrapper">
                <img src={successfullImage} alt="Success" />
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default VrddhimBlog;
