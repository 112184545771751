import React, { useState, useEffect } from "react";
import UserBarChart from "../charts/userBarChart";
import UserLineChart from "../charts/userLineChart";
import CourseBarChart from "../charts/courseBarChart";
import CourseLineChart from "../charts/CourseLineChart";

import styles from "../../styles/pages/site-analytics.module.scss";

function Analytics() {
  const [loading, setLoading] = useState(true);

  const userData = {
    logins: [150, 200, 300, 250, 320],
    enrollments: [80, 120, 160, 140, 190],
    participation: [90, 130, 170, 160, 200],
  };

  const labels = ["January", "February", "March", "April", "May"];

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={styles.analyticsContainer}>
      <div className={styles.headerContainer}>
        <h2 className="eloquent-title">Analytics</h2>
      </div>
      <div className={styles.chartWrapper}>
        {/* User Charts */}
        <div className={styles.chartSection}>
          <div className={styles.chartCard}>
            <UserBarChart data={userData} labels={labels} loading={loading} />
          </div>
          <div className={styles.chartCard}>
            <UserLineChart data={userData} labels={labels} loading={loading} />
          </div>
        </div>

        <div className={styles.sectionGap}></div>

        {/* Course Charts */}
        <div className={styles.chartSection}>
          <div className={styles.chartCard}>
            <CourseBarChart />
          </div>
          <div className={styles.chartCard}>
            <CourseLineChart />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Analytics;
