import React, { useState, useEffect, useContext } from "react";
import { Table, Button, Modal, Form, Card } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../../styles/pages/courseManagement.scss";
import AdminBatches from "./AdminBatches";
import { HttpServiceContext } from "../../config/httpContext";

const CourseManagement = () => {
  const [courses, setCourses] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [editCourse, setEditCourse] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [modalTitle, setModalTitle] = useState("");
  const [newCourse, setNewCourse] = useState({
    course_name: "",
    description: "",
    price: "",
    mentor_id: "",
    mentor_name: "",
    visibility: "",
  });
  const [mentorsData, setMentorsData] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState(null);

  const httpService = useContext(HttpServiceContext);
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    getCourses();
    getMentorsData();
  }, []);

  // Fetch mentors data
  const getMentorsData = async () => {
    try {
      const response = await httpService.getMentorsData();
      if (response) {
        const mentorNames = response.map((mentor) => mentor.mentor_name);
        setMentorsData(mentorNames);
      }
    } catch (error) {
      console.error("Error fetching mentors data:", error);
      MySwal.fire("Error", "Failed to fetch mentors data.", "error");
    }
  };

  // Fetch courses data
  const getCourses = async () => {
    try {
      const data = await httpService.getCourses();
      if (data) {
        setCourses(data.courses);
        setShowAddModal(false);
        setNewCourse({
          course_name: "",
          description: "",
          price: "",
          mentor_id: "",
          mentor_name: "",
          visibility: "",
        });
      } else {
        setCourses([]);
      }
    } catch (error) {
      console.error("Error fetching courses:", error);
      MySwal.fire("Error", "Failed to fetch courses.", "error");
    }
  };

  // Add a new course
  const addCourses = async () => {
    try {
      const data = await httpService.addCourses(JSON.stringify(newCourse));
      if (data) {
        setCourses(data.courses);
        setShowAddModal(false);
        MySwal.fire("Success", "Course added successfully!", "success");
        setNewCourse({
          course_name: "",
          description: "",
          price: "",
          mentor_id: "",
          mentor_name: "",
          visibility: "",
        });
      } else {
        MySwal.fire("Error", "Failed to add course.", "error");
      }
    } catch (error) {
      console.error("Error adding course:", error);
      MySwal.fire("Error", "An error occurred while adding the course.", "error");
    }
  };

  // Edit an existing course

  const editCourses = async () => {
    // Check if any field was actually changed
    if (
      newCourse.course_name === editCourse.course_name &&
      newCourse.description === editCourse.description &&
      newCourse.price === editCourse.price &&
      newCourse.mentor_id === editCourse.mentor_id &&
      newCourse.mentor_name === editCourse.mentor_name &&
      newCourse.visibility === editCourse.visibility
    ) {
      MySwal.fire("No Changes", "No changes were made to the course.", "error");
      return;
    }

    try {
      const data = await httpService.editCourses(JSON.stringify(newCourse), editCourse.course_id);
      if (data) {
        setCourses(courses.map((course) => (course.course_id === editCourse.course_id ? { ...course, ...newCourse } : course)));
        setShowAddModal(false);
        MySwal.fire("Success", "Course updated successfully!", "success");
        setEditCourse(null);
        setNewCourse({
          course_name: "",
          description: "",
          price: "",
          mentor_id: "",
          mentor_name: "",
          visibility: "",
        });
      } else {
        MySwal.fire("Error", "Failed to update course.", "error");
      }
    } catch (error) {
      console.error("Error editing course:", error);
      MySwal.fire("Error", "An error occurred while updating the course.", "error");
    }
  };

  // Handle showing the edit modal
  const handleShowEditModal = (course) => {
    setEditCourse(course);
    setNewCourse({
      course_name: course.course_name,
      description: course.description,
      price: course.price,
      mentor_id: course.mentor_id ?? "",
      mentor_name: course.mentor_name ?? "",
      visibility: course.visibility ?? "",
    });
    setModalTitle("Edit Course");
    setShowAddModal(true);
  };

  // Handle showing the batches modal
  const handleShowBatchesModal = (course) => {
    setSelectedCourse(course);
    setModalTitle(`Batches for ${course.course_name}`);
    setSelectedCourseId(course.course_id);
  };

  // Handle toggling course visibility with SweetAlert2 confirmation
  const handleToggleCourseVisibility = (course) => {
    MySwal.fire({
      title: "Are you sure?",
      text: `Do you want to ${course.visibility ? "hide" : "show"} the course: "${course.course_name}"?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, proceed!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const updatedVisibility = !course.visibility;
          const updatedCourse = { ...course, visibility: updatedVisibility };
          const data = await httpService.editCourses(JSON.stringify(updatedCourse), course.course_id);
          if (data) {
            setCourses(courses.map((c) => (c.course_id === course.course_id ? updatedCourse : c)));
            MySwal.fire("Updated!", `Course has been ${updatedVisibility ? "shown" : "hidden"}.`, "success");
          } else {
            MySwal.fire("Error", "Failed to update visibility.", "error");
          }
        } catch (error) {
          console.error("Error toggling visibility:", error);
          MySwal.fire("Error", "An error occurred while updating visibility.", "error");
        }
      }
    });
  };

  // Delete a course with SweetAlert2 confirmation
  const deleteCourse = (courseId) => {
    const course = courses.find((c) => c.course_id === courseId);
    if (!course) return;

    const hasBatches = course.batches && course.batches.length > 0;

    let text = "Are you sure you want to delete this course?";
    if (hasBatches) {
      text = `There are batches associated with the course: "${course.course_name}". Are you sure you want to delete it?`;
    }

    MySwal.fire({
      title: "Confirm Deletion",
      text: text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await httpService.deleteCourse(courseId);
          setCourses(courses.filter((c) => c.course_id !== courseId));
          MySwal.fire("Deleted!", "The course has been deleted.", "success");
        } catch (error) {
          console.error("Error deleting course:", error);
          MySwal.fire("Error", "Failed to delete the course.", "error");
        }
      }
    });
  };

  // Handle closing the batches modal
  const handleCloseAdminBatchesModal = () => {
    setSelectedCourse(null);
    setSelectedCourseId(null);
  };

  return (
    <div className="course-management">
      <h2 className="eloquent-title">Course Management</h2>

      <Button
        className="bttn-primary"
        onClick={() => {
          setModalTitle("Add Course");
          setShowAddModal(true);
          setEditCourse(null);
          setNewCourse({
            course_name: "",
            description: "",
            price: "",
            mentor_id: "",
            mentor_name: "",
            visibility: "",
          });
        }}
      >
        Add Course
      </Button>

      <Card className="my-4">
        <Card.Body>
          <Card.Title>Courses List</Card.Title>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Description</th>
                <th>Price</th>
                <th>Mentor ID</th>
                <th>Mentor Name</th>
                <th>Visibility</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {courses?.length > 0 ? (
                courses.map((course) => (
                  <tr key={course.course_id}>
                    <td>{course.course_id}</td>
                    <td>{course.course_name}</td>
                    <td>{course.description}</td>
                    <td>{course.price}</td>
                    <td>{course.mentor_id}</td>
                    <td>{course.mentor_name}</td>
                    <td>
                      <Button variant="link" onClick={() => handleToggleCourseVisibility(course)}>
                        {course.visibility ? <FaEye /> : <FaEyeSlash />}
                      </Button>
                    </td>
                    <td>
                      <Button variant="info" onClick={() => handleShowEditModal(course)} className="me-2">
                        Edit
                      </Button>
                      <Button className="bttn-primary me-2" onClick={() => handleShowBatchesModal(course)}>
                        Load Class
                      </Button>
                      <Button variant="danger" onClick={() => deleteCourse(course.course_id)}>
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8">No courses available</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      {/* Add/Edit Course Modal */}
      <Modal className="mt-20vh" show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formCourseName">
              <Form.Label>Course Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter course name"
                value={newCourse.course_name}
                onChange={(e) => setNewCourse({ ...newCourse, course_name: e.target.value })}
              />
            </Form.Group>

            <Form.Group controlId="formCourseDesc" className="mt-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter course description"
                value={newCourse.description}
                onChange={(e) => setNewCourse({ ...newCourse, description: e.target.value })}
              />
            </Form.Group>

            <Form.Group controlId="formCoursePrice" className="mt-3">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter course price"
                value={newCourse.price}
                onChange={(e) => setNewCourse({ ...newCourse, price: e.target.value })}
              />
            </Form.Group>

            <Form.Group controlId="formMentorName" className="mt-3">
              <Form.Label>Mentor Name</Form.Label>
              <Form.Control as="select" value={newCourse.mentor_name} onChange={(e) => setNewCourse({ ...newCourse, mentor_name: e.target.value })}>
                <option value="">Select a mentor</option>
                {mentorsData.map((mentor, index) => (
                  <option key={index} value={mentor}>
                    {mentor}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formVisibility" className="mt-3">
              <Form.Label>Visibility</Form.Label>
              <Form.Check
                type="checkbox"
                label="Visible"
                checked={newCourse.visibility}
                onChange={(e) => setNewCourse({ ...newCourse, visibility: e.target.checked })}
              />
            </Form.Group>

            <Button className="bttn-primary mt-4" onClick={modalTitle === "Add Course" ? addCourses : editCourses}>
              {modalTitle === "Add Course" ? "Add Course" : "Update Course"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Admin Batches Modal */}
      <Modal show={selectedCourseId !== null} onHide={handleCloseAdminBatchesModal}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{selectedCourse && <AdminBatches courseId={selectedCourse.course_id} onClose={handleCloseAdminBatchesModal} />}</Modal.Body>
      </Modal>
    </div>
  );
};

export default CourseManagement;
