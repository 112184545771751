import React, { useState, useEffect } from "react";
import PaymentsChart from "../charts/payment-overview-chart";
import Invoice from "./Invoice";
import styles from "../../styles/pages/payments-analytics.module.scss";
import { Dropdown, Card, Tooltip, OverlayTrigger } from "react-bootstrap";

function PaymentsAnalytics() {
  const [payments, setPayments] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await fetch("https://api.vrddhim.org/admin/fetch/payments");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setPayments(data);
      } catch (error) {
        console.error("Failed to fetch payments:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPayments();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const summaryData = {
    totalPayments: payments.reduce((sum, payment) => sum + payment.amount, 0),
    completedPayments: payments.filter((p) => p.status === "Completed").length,
    pendingPayments: payments.filter((p) => p.status === "Pending").length,
  };

  const handleInvoiceClick = (payment) => {
    setSelectedPayment(payment);
  };

  return (
    <div className={styles.paymentsContainer}>
      <h2 className="eloquent-title">Payments</h2>

      <div className={styles.summaryWrapper}>
        <Card className={styles.summaryCard}>
          <Card.Body>
            <Card.Title>Total Payments</Card.Title>
            <Card.Text>${summaryData.totalPayments}</Card.Text>
          </Card.Body>
        </Card>
        <Card className={styles.summaryCard}>
          <Card.Body>
            <Card.Title>Completed Payments</Card.Title>
            <Card.Text>{summaryData.completedPayments}</Card.Text>
          </Card.Body>
        </Card>
        <Card className={styles.summaryCard}>
          <Card.Body>
            <Card.Title>Pending Payments</Card.Title>
            <Card.Text>{summaryData.pendingPayments}</Card.Text>
          </Card.Body>
        </Card>
      </div>

      <div className={styles.actionsButton}>
        <Dropdown className="text-end">
          <Dropdown.Toggle variant="secondary" id="filter-dropdown">
            Filter
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href="#/action-1">Filter by Date</Dropdown.Item>
            <Dropdown.Item href="#/action-2">Filter by Status</Dropdown.Item>
            <Dropdown.Item href="#/action-3">Filter by Payment Method</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <div className={styles.chartWrapper}>
        <div className={`${styles.chart} ${styles.chartPrimary}`}>
          <PaymentsChart />
        </div>
        <div className={`${styles.chart} ${styles.chartSecondary}`}>
          <PaymentsChart />
        </div>
      </div>

      <div className={styles.tableWrapper}>
        <table className={styles.detailsTable}>
          <thead>
            <tr>
              <th>ID</th>
              <th>Username</th>
              <th>Payment ID</th>
              <th>Date</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Payment Method</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {payments.map((payment) => (
              <tr key={payment.payment_id}>
                <td>{payment.payment_id}</td>
                <td>{payment.username}</td>
                <td>{payment.order_id}</td>
                <td>{new Date(payment.date).toLocaleString()}</td>
                <td>${payment.amount}</td>
                <td className={styles[`${payment.status.toLowerCase()}Status`]}>{payment.status}</td>
                <td>{payment.payment_method || "N/A"}</td>
                <td>
                  <button className={styles.button} onClick={() => handleInvoiceClick(payment)}>
                    INVOICE
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className={styles.recentActivity}>
        <h3>Recent Activity</h3>
        <ul>
          <li>New payment of $500 added today via PayPal.</li>
          <li>Payment of $150 failed on August 3rd using Credit Card.</li>
          <li>Payment of $400 completed on August 4th via GPay.</li>
        </ul>
      </div>

      {selectedPayment && <Invoice payment={selectedPayment} onClose={() => setSelectedPayment(null)} />}
    </div>
  );
}

export default PaymentsAnalytics;
