import React from "react";
import "../../styles/pages/adminFooter.scss";
function Footer() {
  const year = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-content">
        Copyright © {year} All rights reserved <br />
        Powered By{" "}
        <a className="footer-link" href="https://hollasinnovations.com" target="_blank" rel="noopener noreferrer">
          Holla's Innovations
        </a>
      </div>
    </footer>
  );
}

export default Footer;
