import Navbar from "../navbar";
import Footer from "../home/vrddhim-footer";
import BlogCover from "./vrddhim-blogcover";
import VrddhimBlog from "./vrddhim-blog";
function VrddhimBlogpage() {
  window.scrollTo(0, 0);
  return (
    <>
      <Navbar />
      <BlogCover></BlogCover>
      <VrddhimBlog></VrddhimBlog>
      <Footer />
    </>
  );
}

export default VrddhimBlogpage;
