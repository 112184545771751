// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.testimonial-section {
  padding: 3rem 1rem;
}

.testimonial-card {
  border-radius: 12px; /* Adjust this value for rounder or less rounded corners */
}

.row {
  display: flex;
  justify-content: center;
}

.testimonial-wrapper {
  max-width: 400px;
  margin-bottom: 2rem;
}

.reviewer-image {
  width: 150px;
  height: 150px;
}
.reviewer-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.rating .fa-star {
  font-size: 3rem;
}

.testimonial-content {
  font-size: 1rem;
  color: #333;
  margin-top: 1rem;
}

.reviewer-name {
  font-size: 1rem;
  color: #555;
  font-weight: 600;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-2 {
  margin-top: 1rem !important;
}

.mt-3 {
  margin-top: 1.5rem !important;
}

.text-warning {
  color: #f0b40d;
}`, "",{"version":3,"sources":["webpack://./src/styles/pages/testimonials.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AACA;EAEE,mBAAA,EAAA,0DAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;AACF;AAAE;EACE,WAAA;EACA,YAAA;EACA,iBAAA;AAEJ;;AAGE;EACE,eAAA;AAAJ;;AAIA;EACE,eAAA;EACA,WAAA;EACA,gBAAA;AADF;;AAIA;EACE,eAAA;EACA,WAAA;EACA,gBAAA;AADF;;AAIA;EACE,2BAAA;AADF;;AAIA;EACE,2BAAA;AADF;;AAIA;EACE,6BAAA;AADF;;AAIA;EACE,cAAA;AADF","sourcesContent":[".testimonial-section {\n  padding: 3rem 1rem;\n}\n.testimonial-card {\n  // background-color: #f1f9f1;\n  border-radius: 12px; /* Adjust this value for rounder or less rounded corners */\n}\n\n.row {\n  display: flex;\n  justify-content: center;\n}\n\n.testimonial-wrapper {\n  max-width: 400px;\n  margin-bottom: 2rem;\n}\n\n.reviewer-image {\n  width: 150px;\n  height: 150px;\n  img {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n  }\n}\n\n.rating {\n  .fa-star {\n    font-size: 3rem;\n  }\n}\n\n.testimonial-content {\n  font-size: 1rem;\n  color: #333;\n  margin-top: 1rem;\n}\n\n.reviewer-name {\n  font-size: 1rem;\n  color: #555;\n  font-weight: 600;\n}\n\n.mt-5 {\n  margin-top: 3rem !important;\n}\n\n.mt-2 {\n  margin-top: 1rem !important;\n}\n\n.mt-3 {\n  margin-top: 1.5rem !important;\n}\n\n.text-warning {\n  color: #f0b40d;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
