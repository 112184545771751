import Navbar from "../navbar";
import Footer from "../home/vrddhim-footer";
import AboutCover from "./vrddhim-aboutcover";
import VrddhimDescription from "./vrddhim-description";
import VrddhimAboutcards from "./vrddhim-aboutcards";
import WhyChoosevrddhim from "./whychoose-vrddhim";

function VrddhimAboutpage() {
  window.scrollTo(0, 0);

  return (
    <>
      <Navbar />
      <AboutCover />
      <VrddhimDescription />
      <VrddhimAboutcards />
      <WhyChoosevrddhim />
      <Footer />
    </>
  );
}

export default VrddhimAboutpage;
