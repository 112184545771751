export const rows = [
  {
    id: 1,
    task: 'Easy English',
    members: ['https://images.pexels.com/photos/39866/entrepreneur-startup-start-up-man-39866.jpeg?auto=compress&cs=tinysrgb&w=600', 'https://images.pexels.com/photos/39866/entrepreneur-startup-start-up-man-39866.jpeg?auto=compress&cs=tinysrgb&w=600'],
    progress: 75,
    status: 'in progress',
    timeLeft: '2 days',
  },
  {
    id: 2,
    task: 'Rhymes',
    members: ['https://images.pexels.com/photos/927451/pexels-photo-927451.jpeg?auto=compress&cs=tinysrgb&w=600', 'https://images.pexels.com/photos/927451/pexels-photo-927451.jpeg?auto=compress&cs=tinysrgb&w=600'],
    progress: 40,
    status: 'in progress',
    timeLeft: '1 week',
  },
  {
    id: 3,
    task: 'English accent Developement',
    members: ['https://images.pexels.com/photos/845457/pexels-photo-845457.jpeg?auto=compress&cs=tinysrgb&w=600', 'https://images.pexels.com/photos/845457/pexels-photo-845457.jpeg?auto=compress&cs=tinysrgb&w=600'],
    progress: 100,
    status: 'completed',
    timeLeft: '0 days',
  },
  {
    id: 4,
    task: 'English With us',
    members: ['https://images.pexels.com/photos/762080/pexels-photo-762080.jpeg?auto=compress&cs=tinysrgb&w=600', 'https://images.pexels.com/photos/762080/pexels-photo-762080.jpeg?auto=compress&cs=tinysrgb&w=600'],
    progress: 20,
    status: 'pending',
    timeLeft: '3 days',
  },
  {
    id: 5,
    task: 'Syllables',
    members: ['https://images.pexels.com/photos/925786/pexels-photo-925786.jpeg?auto=compress&cs=tinysrgb&w=600', 'https://images.pexels.com/photos/925786/pexels-photo-925786.jpeg?auto=compress&cs=tinysrgb&w=600'],
    progress: 60,
    status: 'in progress',
    timeLeft: '5 days',
  },
  {
    id: 6,
    task: 'A an the',
    members: ['https://images.pexels.com/photos/415263/pexels-photo-415263.jpeg?auto=compress&cs=tinysrgb&w=600', 'https://images.pexels.com/photos/415263/pexels-photo-415263.jpeg?auto=compress&cs=tinysrgb&w=600'],
    progress: 85,
    status: 'in progress',
    timeLeft: '4 days',
  },
  {
    id: 7,
    task: 'Vowles',
    members: ['https://images.pexels.com/photos/821411/pexels-photo-821411.jpeg?auto=compress&cs=tinysrgb&w=600', 'https://images.pexels.com/photos/821411/pexels-photo-821411.jpeg?auto=compress&cs=tinysrgb&w=600'],
    progress: 30,
    status: 'pending',
    timeLeft: '1 week',
  },
  {
    id: 8,
    task: 'English',
    members: ['/img/avatar2.png', '/img/avatar3.png'],
    progress: 50,
    status: 'in progress',
    timeLeft: '6 days',
  },
  {
    id: 9,
    task: 'English',
    members: ['/img/avatar5.png', '/img/avatar6.png'],
    progress: 90,
    status: 'in progress',
    timeLeft: '2 days',
  },
  {
    id: 10,
    task: 'English',
    members: ['/img/avatar1.png', '/img/avatar3.png'],
    progress: 65,
    status: 'in progress',
    timeLeft: '4 days',
  },
  {
    id: 11,
    task: 'English',
    members: ['/img/avatar2.png', '/img/avatar4.png'],
    progress: 40,
    status: 'pending',
    timeLeft: '5 days',
  },
  {
    id: 12,
    task: 'English',
    members: ['/img/avatar5.png', '/img/avatar1.png'],
    progress: 70,
    status: 'in progress',
    timeLeft: '3 days',
  },
  {
    id: 13,
    task: 'English',
    members: ['/img/avatar2.png', '/img/avatar6.png'],
    progress: 95,
    status: 'completed',
    timeLeft: '0 days',
  },
  {
    id: 14,
    task: 'English',
    members: ['/img/avatar3.png', '/img/avatar4.png'],
    progress: 55,
    status: 'in progress',
    timeLeft: '1 week',
  },
  {
    id: 15,
    task: 'English',
    members: ['/img/avatar1.png', '/img/avatar5.png'],
    progress: 80,
    status: 'in progress',
    timeLeft: '2 days',
  },
  {
    id: 16,
    task: 'English',
    members: ['/img/avatar2.png', '/img/avatar3.png'],
    progress: 25,
    status: 'pending',
    timeLeft: '10 days',
  },
  {
    id: 17,
    task: 'English',
    members: ['/img/avatar4.png', '/img/avatar6.png'],
    progress: 35,
    status: 'in progress',
    timeLeft: '1 week',
  },
  {
    id: 18,
    task: 'English',
    members: ['/img/avatar1.png', '/img/avatar2.png'],
    progress: 50,
    status: 'in progress',
    timeLeft: '3 days',
  },
  {
    id: 19,
    task: 'English',
    members: ['/img/avatar3.png', '/img/avatar5.png'],
    progress: 60,
    status: 'in progress',
    timeLeft: '4 days',
  },
  {
    id: 20,
    task: 'English',
    members: ['/img/avatar4.png', '/img/avatar6.png'],
    progress: 85,
    status: 'in progress',
    timeLeft: '2 days',
  },
];
