import React from "react";
import Slider from "react-slick";
import "../../styles/pages/testimonials.scss";
import { FaStar } from "react-icons/fa";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonials = () => {
  const reviews = [
    {
      id: 1,
      name: "Bharati, Homemaker.",
      text: "Hello, I’m a homemaker who is pursuing to start my own startup. The General English course at Vrddhim has been a game-changer for me. My confidence in speaking and understanding English has improved tremendously. My trainer was very supportive, and the sessions are engaging and practical. I highly recommend it to anyone looking to enhance their English skills.",
      rating: 5,
    },
    {
      id: 2,
      name: "Sai Vijay, Marketing Manager- Realtor.",
      text: "Hi Folks, This Sai Vijay, I’m a budding realtor. I would like to Thank team Vrddhim, I’ve gained the confidence to conduct meetings and write professional emails in the business language with ease. The business English course was tailored to my industry, and my trainer was incredibly knowledgeable. It has made a significant impact on my career. “Looking forward to join the other course that Vrddhim has to offer.",
      rating: 5,
    },
    {
      id: 3,
      name: "Sachin, Project Manager – IT.",
      text: "Hi All, I’m Sachin, a Project Manager and I’m glad to share my experience with Vrddhim. I took up the leadership skill course it has been an incredible learning and I couldn’t be happier with my results post implementing the skills I acquired from this course. My trainer was well experienced she had an international exposure to the subject which was superb value add to the course. She provided clear guidance, practical assessments and personalized feedback that helped me get to my higher designation. Thanks a ton, Vrddhim!",
      rating: 5,
    },
    {
      id: 4,
      name: "Rahul, web designer - Entrepreneurs.",
      text: "Hi, This is Rahul, I’m a web designer running my own startup. The Marketing & Sales Course at Vrddhim was exactly what I needed to feel more comfortable speaking to my clients &amp; pitching my business. The trainer made learning very interesting and interactive by giving practical situational examples to have a real time understanding of the language usage. I now find it so much easier to communicate with both my clients who are both nonnative native speakers.",
      rating: 5,
    },
    {
      id: 5,
      name: "Gagan, HR Manager- Banking Sector",
      text: "Hi, We partnered with Vrddhim for Corporate Business English training for our employees. The customized curriculum focused on the specific needs of our team and improved both their communication and presentation skills. The employees feel very motivated to apply the skills acquired from the program. The progress has been Super impressive.",
      rating: 5,
    },
    {
      id: 6,
      name: "Prayaag, School Student.",
      text: "Hello all, I’m Prayaag, a 9 th grader. The young learner program at Vrddhim has been an invaluable experience for my academic growth. The sessions have helped me enhance my essay-writing skills. I have completely overcome my stage fear &amp; now I feel very confident leading my house team at school with pride. The motivation confidence I have acquired from this course is going to an tremendous impact on my academic journey. Thank you, team “Vrddhim”.",
      rating: 5,
    },
    {
      id: 7,
      name: "Shaz, Blogger -Traveler",
      text: "Hi, I’m Shaz a travel blogger. I took up General English course at Vrddhim and they customised it to suit my English for travel this learning experience has completely changed the way I experience travel now. I feel confident navigating new cities, talking to locals, and even handling emergencies. The sessions were practical and fun, focusing on real-world conversations! My trainer was excellent. Thank you Vrddhim will come back for yet another enriching learning experience.",
      rating: 5,
    },
    {
      id: 8,
      name: "Kiran, TL - IT",
      text: "Hello, This is Kiran, my main ask was for “The pronunciation and accent reduction”. Vrddhim catered to my needs through the Business English course which has helped me sound more natural in English. My colleagues and friends have noticed a huge difference in the way I speak, and I feel more confident during presentations. I recommend this to anyone looking to refine their speaking skills. Fantastic sessions, Thank you team Vrddhim.",
      rating: 5,
    },
    {
      id: 9,
      name: "Marget., IT Specialist",
      text: "Hi, I’m Margret. After completing “Get your Dream Job “- English for Job Interviews course at Vrddhim ,I was able to secure a job offer! The mock interviews, feedback sessions, and focus on professional vocabulary gave me the edge I needed. It was a fantastic experience that led to real results. “Thank you, team Vrddhim!",
      rating: 5,
    },
    {
      id: 10,
      name: "Guna, Parent.",
      text: "Hello, My Daughter absolutely loved the “Young Learners English course” at Vrddhim. The trainer was patient enough, creative, and know how to make learning enjoyable for young learners. Her confidence in speaking and understanding English has grown so much in just a few months. Looking forward for many more session team Vrdhhim, Thank you.",
      rating: 5,
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="" style={{ backgroundColor: "#ffffff", padding: "50px 0px" }}>
      <div className="container testimonial-section story-container my-3">
        <h2 className="text-center mb-2 elegant-heading" style={{ color: "#595959" }}>
          Testimonials
        </h2>
        <Slider {...settings}>
          {reviews.map((review) => (
            <div key={review.id} className="col-md-6 d-flex justify-content-center">
              <div className="testimonial-card d-flex flex-column align-items-center">
                <div className="reviewer-image">
                  <img src="https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png" className="rounded-circle" alt="Reviewer" />
                </div>
                <div className="rating mt-2">
                  {Array.from({ length: review.rating }).map((_, index) => (
                    <FaStar key={index} className="text-warning" />
                  ))}
                </div>
                <div className="review-text mt-3">
                  <p className="testimonial-content text-center">"{review.text}"</p>
                  <p className="text-center reviewer-name">— {review.name}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Testimonials;
