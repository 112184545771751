// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mentor-registration-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mentor-registration-form {
  background: #f1f9f1;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  width: 400px;
}

.mentor-registration-form h2 {
  text-align: center;
  margin-bottom: 1.5rem;
  color: #595959;
}

.mentor-registration-form label {
  margin-bottom: 0.5rem;
  color: #595959;
  display: block;
}

.mentor-registration-form input {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}`, "",{"version":3,"sources":["webpack://./src/components/mentors-management/MentorRegistration.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAEA;EACE,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,yCAAA;EACA,YAAA;AACF;;AAEA;EACE,kBAAA;EACA,qBAAA;EACA,cAAA;AACF;;AAEA;EACE,qBAAA;EACA,cAAA;EACA,cAAA;AACF;;AAEA;EACE,WAAA;EACA,gBAAA;EACA,mBAAA;EACA,sBAAA;EACA,kBAAA;EACA,gCAAA;AACF","sourcesContent":[".mentor-registration-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.mentor-registration-form {\n  background: #f1f9f1;\n  padding: 2rem;\n  border-radius: 10px;\n  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);\n  width: 400px;\n}\n\n.mentor-registration-form h2 {\n  text-align: center;\n  margin-bottom: 1.5rem;\n  color: #595959;\n}\n\n.mentor-registration-form label {\n  margin-bottom: 0.5rem;\n  color: #595959;\n  display: block;\n}\n\n.mentor-registration-form input {\n  width: 100%;\n  padding: 0.75rem;\n  margin-bottom: 1rem;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  transition: all 0.3s ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
