// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-container {
  margin-top: 50px;
}

.profile-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.profile-avatar img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 3px solid #000;
}

.profile-name {
  color: #000;
  font-size: 1.5rem;
}

.profile-role {
  color: #666;
}

.profile-info {
  margin-bottom: 20px;
}

.profile-info .list-group-item {
  border: none;
  padding: 10px 0;
}

.profile-actions .action-button {
  margin: 5px;
  border: 1px solid #000;
  color: #000;
  background-color: #fff;
}

.profile-actions .action-button:hover {
  background-color: #000;
  color: #fff;
}

.profile-socials {
  margin-top: 20px;
}

.social-icon {
  margin: 0 10px;
  color: #000;
  font-size: 1.5rem;
}

.social-icon:hover {
  color: #666;
}`, "",{"version":3,"sources":["webpack://./src/styles/pages/admin-profile.scss"],"names":[],"mappings":"AACA;EACE,gBAAA;AAAF;;AAGA;EACE,sBAAA;EACA,sBAAA;EACA,kBAAA;EACA,wCAAA;AAAF;;AAGA;EACE,YAAA;EACA,aAAA;EACA,kBAAA;EACA,sBAAA;AAAF;;AAGA;EACE,WAAA;EACA,iBAAA;AAAF;;AAGA;EACE,WAAA;AAAF;;AAGA;EACE,mBAAA;AAAF;;AAGA;EACE,YAAA;EACA,eAAA;AAAF;;AAGA;EACE,WAAA;EACA,sBAAA;EACA,WAAA;EACA,sBAAA;AAAF;;AAGA;EACE,sBAAA;EACA,WAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,cAAA;EACA,WAAA;EACA,iBAAA;AAAF;;AAGA;EACE,WAAA;AAAF","sourcesContent":["\n.profile-container {\n  margin-top: 50px;\n}\n\n.profile-card {\n  background-color: #fff;\n  border: 1px solid #ddd;\n  border-radius: 8px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n\n.profile-avatar img {\n  width: 120px;\n  height: 120px;\n  border-radius: 50%;\n  border: 3px solid #000;\n}\n\n.profile-name {\n  color: #000; \n  font-size: 1.5rem;\n}\n\n.profile-role {\n  color: #666; \n}\n\n.profile-info {\n  margin-bottom: 20px;\n}\n\n.profile-info .list-group-item {\n  border: none; \n  padding: 10px 0; \n}\n\n.profile-actions .action-button {\n  margin: 5px;\n  border: 1px solid #000; \n  color: #000; \n  background-color: #fff; \n}\n\n.profile-actions .action-button:hover {\n  background-color: #000; \n  color: #fff; \n}\n\n.profile-socials {\n  margin-top: 20px;\n}\n\n.social-icon {\n  margin: 0 10px;\n  color: #000; \n  font-size: 1.5rem; \n}\n\n.social-icon:hover {\n  color: #666; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
