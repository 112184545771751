import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../navbar";
import Footer from "../home/vrddhim-footer";
import courseData from "../../assets/courseData.json";
import "../../styles/pages/CourseDetails.scss";

const CourseDetails = () => {
  window.scrollTo(0, 0);

  const location = useLocation();
  const navigate = useNavigate();
  const [courses, setCourses] = useState(courseData);
  const courseDetails = location.state?.courseDetails || {};

  const { course_name } = courseDetails;

  const courseInfo = courses.find((course) => course.course_name === course_name);

  const handleEnrollClick = () => {
    navigate("/enrollment", { state: { courseDetails } });
  };

  return (
    <>
      <Navbar />
      <section className="coursedetails-page" id="coursedetails">
        <div className="coursedetails-container">
          <div>
            <button className="btn bttn-primary" onClick={() => navigate("/courses")}>
              {/* <i className="fas fa-angle-left arrow-icon mx-2"></i> */}
              Back
            </button>
          </div>
          <div className="coursedetails-row">
            <div className="coursedetails-col-lg-12">
              <div className="coursedetails-single-item">
                {courses.map((course) => {
                  console.log(course, course.course_name);
                  return (
                    course.course_name == courseDetails.course_name && (
                      <div key={course.course_id}>
                        <div className="coursedetails-thumb position-relative">
                          <img className="position-absolute" src={require(`../../assets/images${course.image}`)} alt={course.course_name} />
                          <p className="position-absolute text-center course-quote fc-white ">{course.quote}</p>
                        </div>
                        <div className="coursedetails-down-content">
                          <h2 className="p-1 mb-3 elegant-heading text-center">{course.course_name}</h2>
                          <p>
                            <strong className="lh-2">Duration:</strong> {course.duration}
                          </p>
                          <p>
                            <strong className="lh-2">Fees:</strong> {course.fees}
                          </p>
                          <p>
                            <strong className="lh-2">Eligibility:</strong> {course.eligibility}
                          </p>
                          <p>
                            <strong className="lh-2">Objective of this Course:</strong> {course.objective}
                          </p>
                          <p>
                            <strong className="lh-2">The course aims to:</strong>
                            {course.aims.map((aim, index) => (
                              <p>
                                {index + 1}. {aim}
                              </p>
                            ))}
                          </p>
                          <p>
                            <strong className="lh-2">Learners gain from this Course:</strong>{" "}
                            {course.gain.map((gain, index) => (
                              <p>
                                {index + 1}. {gain}
                              </p>
                            ))}
                          </p>
                          <div className="d-flex align-items-center justify-content-between">
                            <div>Note* : You will get the detail course curriculam after the payment</div>
                            {!courseDetails.enrolled && (
                              <button className="coursedetails-main-button-green" onClick={handleEnrollClick}>
                                Enroll Now!
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default CourseDetails;
