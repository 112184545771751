import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import MentorCard from "./mentors-overview-card";

function Mentors() {
  const [mentors, setMentors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMentors = async () => {
      try {
        const response = await fetch("https://api.vrddhim.org/fetch/mentors");
        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
        const backendData = await response.json();

        const mergedData = backendData.map((mentorFromBackend) => {
          return {
            mentor_id: mentorFromBackend.mentor_id,
            name: mentorFromBackend.mentor_name,
            email: mentorFromBackend.mentor_email,
            tasks: mentorFromBackend.completed_session,
            taskCompletion: (mentorFromBackend.completed_session / 20) * 100,
            rating: 4.5,
            reviews: 10,
            image: "/path/to/default-avatar.jpg",
            role: "Senior Mentor",
          };
        });

        setMentors(mergedData);
        setLoading(false);
      } catch (err) {
        console.error("Failed to fetch mentors:", err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchMentors();
  }, []);

  // Slider
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (loading) {
    return <div>Loading mentors...</div>;
  }

  if (error) {
    return <div>Error fetching mentors: {error}</div>;
  }

  return (
    <div className="mentors-carousel">
      <Slider {...settings}>
        {mentors.map((mentor) => (
          <div key={mentor.mentor_id} className="mentor-card-wrapper">
            <MentorCard mentor={mentor} />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Mentors;
