import React, { useContext, useEffect, useState } from "react";
import "./PaymentSuccess.scss";
import { useNavigate } from "react-router-dom";
import { HttpServiceContext } from "../../config/httpContext";
import Invoice from "../payments-analytics/Invoice";
import { Modal } from "react-bootstrap";

const PaymentSuccess = ({ razorpayment_id, enrolledBatch, courseDetails }) => {
  const navigate = useNavigate();
  const [userDetail, setUserDetail] = useState();
  const [paymentTime, setPaymentTime] = useState();
  const [showInvoice, setShowInvoice] = useState(false);
  const [showSuccessModel, setShowSuccessModel] = useState(true);
  const [transactionId, setTransactionId] = useState(razorpayment_id);
  const httpService = useContext(HttpServiceContext);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchProfileAndTransactionId = async () => {
      try {
        const profileResponse = await httpService.getProfile();
        if (profileResponse) {
          setUserDetail(profileResponse);
        } else {
          console.log("Failed to fetch profile");
        }
        const today = new Date().toLocaleString();
        setPaymentTime(today);
      } catch (error) {
        console.error("Error fetching profile or payment status:", error);
      }
    };

    fetchProfileAndTransactionId();
  }, [httpService]);

  const handleModalClose = () => {
    setShowSuccessModel(false);
    navigate("/enrolled/course", { state: { courseDetails } });
  };

  return (
    <div>
      <Modal show={showSuccessModel} onHide={handleModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="background-primary">
          <div className="popup-content">
            <h2 className="text-center elegant-heading">Payment Successful</h2>
            <p>
              <strong>Transaction ID:</strong> {transactionId}
            </p>
            <p>
              <strong>Amount:</strong> ₹ {enrolledBatch.price}
            </p>
            <p>
              <strong>Date:</strong> {paymentTime}
            </p>
            <p>
              <strong>Username:</strong> {userDetail?.username}
            </p>
            <p>
              <strong>Course Name:</strong> {enrolledBatch.course_name}
            </p>
            <p>
              <strong>Batch Name:</strong> {enrolledBatch.batch_name}
            </p>
            <p>
              <strong>Start Time:</strong> {enrolledBatch.start_time}
            </p>
            <p>
              <strong>End Time:</strong> {enrolledBatch.end_time}
            </p>
            <p>
              <strong>Batch Date:</strong> {enrolledBatch.batch_date}
            </p>
            <div className="fs-7 fw-bold mt-1"> *You will receive an email with class details 30 minutes before the session starts.</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn bttn-primary" onClick={() => setShowInvoice(true)}>
            Invoice
          </button>
        </Modal.Footer>
      </Modal>
      {showInvoice && (
        <Invoice transactionId={transactionId} userDetail={userDetail} courseDetails={courseDetails} onClose={() => setShowInvoice(false)} />
      )}
    </div>
  );
};

export default PaymentSuccess;
