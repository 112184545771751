// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subjects_carousel_carouselContainer__P26Cm {
  width: 100%;
  padding: 20px;
}

.subjects_carousel_slick-slide__F8KdD {
  padding: 0 20px;
}

.subjects_carousel_cardContainer__Ss\\+9w {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/styles/pages/subjects_carousel.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AACF","sourcesContent":[".carouselContainer {\n  width: 100%;\n  padding: 20px;\n}\n\n.slick-slide {\n  padding: 0 20px;\n}\n\n.cardContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"carouselContainer": `subjects_carousel_carouselContainer__P26Cm`,
	"slick-slide": `subjects_carousel_slick-slide__F8KdD`,
	"cardContainer": `subjects_carousel_cardContainer__Ss+9w`
};
export default ___CSS_LOADER_EXPORT___;
