import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { HttpServiceContext } from "../../config/httpContext";
import { Modal, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [resetToken, setResetToken] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const httpService = useContext(HttpServiceContext);

  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    let token = queryParams.get("token");
    if (token) {
      token = token.replace(/"/g, "").trim();
      setResetToken(token);
    }
  }, []);

  useEffect(() => {
    if (resetToken) {
      verifyToken();
    }
  }, [resetToken]);

  async function verifyToken() {
    let result = await httpService.verifyResetToken(JSON.stringify(resetToken));
    if (result.message !== "Token is valid. You can now reset your password.") {
      setError(result.message);
    }
  }

  async function handleReset() {
    let payload = { resetToken, new_password: newPassword };
    let result = await httpService.resetPassword(JSON.stringify(payload));

    if (result.message === "Your password has been reset!") {
      setShowModal(true);
      Swal.fire({
        title: "Success!",
        text: result.message,
        icon: "success",
        timer: 2000,
        showConfirmButton: false,
        willClose: () => navigate("/login"),
      });
    } else {
      setError(result.message);
      Swal.fire({
        title: "Error!",
        text: result.message,
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  }

  const handleResetPassword = async () => {
    if (!newPassword) {
      setError("Please enter a new password.");
      Swal.fire({
        title: "Error!",
        text: "Please enter a new password.",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      });
      return;
    }
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      Swal.fire({
        title: "Error!",
        text: "Passwords do not match.",
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      });
      return;
    }
    handleReset();
  };

  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <div className="row background-primary border rounded-5 shadow box-area1">
        <div className="col-12 p-5">
          <div className="row align-items-center">
            <div className="header-text mb-4 fc-grey text-center">
              <h2>Reset Password</h2>
              <p className="fc-grey mt-2">Enter your new password to complete the reset and secure your account.</p>
            </div>
            <div className="input-group mb-3">
              <input
                type={showNewPassword ? "text" : "password"}
                className="form-control form-control-lg fs-6"
                placeholder="Enter Your New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <span className="input-group-text" onClick={() => setShowNewPassword(!showNewPassword)} style={{ cursor: "pointer" }}>
                {showNewPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
            <div className="input-group mb-3">
              <input
                type={showConfirmPassword ? "text" : "password"}
                className="form-control form-control-lg fs-6"
                placeholder="Confirm Your New Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <span className="input-group-text" onClick={() => setShowConfirmPassword(!showConfirmPassword)} style={{ cursor: "pointer" }}>
                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>

            {error && <div className="text-danger mb-2">{error}</div>}

            <div className="input-group mb-3">
              <button className="btn bttn-primary btn-lg w-100 fs-6" onClick={handleResetPassword}>
                Reset Password
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Password Reset</Modal.Title>
        </Modal.Header>
        <Modal.Body>Your password has been successfully changed.</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => navigate("/login")}>
            OK
          </Button>
        </Modal.Footer>
      </Modal> */}
    </div>
  );
};

export default ResetPassword;
