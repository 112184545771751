import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import logo from "../../assets/images/vrddhim-logo.png";
import boxImage from "../../assets/images/loginsignupcover.png";
import { HttpServiceContext } from "../../config/httpContext";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Swal from "sweetalert2";
import "./login.scss";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const clientId = "624626311414-oah00r09190g6c8d82hcp04brcnl7q3f.apps.googleusercontent.com";
  const httpService = useContext(HttpServiceContext);

  const [usernameOrEmail, setUsernameOrEmail] = useState("");
  const [googleLogin, setGoogleLogin] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [label, setLabel] = useState("user");
  const currentPath = location.pathname || "";

  useEffect(() => {
    if (location.pathname === "/login" || location.pathname === "/courses") {
      setLabel("user");
      httpService.setLoginedType("user");
      document.cookie = `loginedType=user; path=/; SameSite=Strict`;
    } else if (location.pathname === "/mentor/login") {
      setLabel("mentor");
      httpService.setLoginedType("mentor");
      document.cookie = `loginedType=mentor; path=/; SameSite=Strict`;
    } else if (location.pathname === "/admin/login" || location.pathname.startsWith("/admin/*")) {
      setLabel("admin");
      httpService.setLoginedType("admin");
      document.cookie = `loginedType=admin; path=/; SameSite=Strict`;
    }
  }, [location]);

  const onSuccess = async (response) => {
    const tokenId = response.tokenId;
    if (googleLogin) {
      try {
        const res = await fetch("https://api.vrddhim.org/google/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ tokenId }),
          credentials: "include",
        });

        if (!res.ok) throw new Error("Network response was not ok");

        const result = await res.json();
        console.log("Google Login Successful: ", result);

        Swal.fire({
          title: "Success!",
          text: "Google login successful! Redirecting...",
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
          willClose: () => {
            navigate(`/${currentPath}`);
          },
        });
      } catch (error) {
        setError(error.message || "Google login failed. Please try again.");
      }
    }
  };

  const onFailure = (response) => {
    console.log("Login Failed: ", response);
    if (googleLogin) {
      setError("Google login failed. Please try again.");
    }
  };

  const handleLogin = async () => {
    if (!usernameOrEmail || !password) {
      setError("Username/Email and Password are required.");
      return;
    }

    const isEmail = usernameOrEmail.includes("@");
    let payload = {
      [isEmail ? "email" : "username"]: usernameOrEmail,
      password,
    };

    let response = await httpService.submitLoginForm(payload, label);
    if (response?.data?.message === "Login successful") {
      document.cookie = `token=${response?.data.token}; path=/; SameSite=Strict`;
      httpService.setAuthToken(response?.data.token);
      httpService.setLoginedUserId(
        `${
          label === "user"
            ? response?.data?.user_id
            : label === "admin"
            ? response?.data.admin_id
            : label === "mentor"
            ? response?.data.mentor_id
            : ""
        }`
      );

      httpService.setLoginedType(label);

      Swal.fire({
        title: "Success!",
        text: "Login successful! Redirecting...",
        icon: "success",
        timer: 3000,
        showConfirmButton: false,
        willClose: () => {
          if (label === "mentor") navigate("/mentor/dashboard");
          else if (label === "user") navigate("/");
          else if (label === "admin") navigate("/admin/dashboard");
          else window.location.reload();
        },
      });
    } else if (response?.data.error) {
      setError(response?.data.error);
    } else {
      setError(response?.message || "An error occurred. Please try again.");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={`container d-flex justify-content-center align-items-center ${location.pathname !== "/courses" ? "vh-100" : ""}`}>
      <div className="row border rounded-5 shadow box-area background-primary">
        <div className="col-md-6 right-box">
          <div className="col-12 my-2 d-flex justify-content-center">
            <img src={logo} alt="Vrddhim Logo" className="logo-img" style={{ width: "90px", height: "120px" }} />
          </div>
          <div className="row fc-white">
            <div className="header-text mb-2">
              <h4 className="text-center mb-2 fc-grey">Login</h4>
            </div>
            <div className="input-group mb-2">
              <input
                type="text"
                id="usernameOrEmail"
                className="form-control w-100 fs-6"
                placeholder="Username or Email"
                value={usernameOrEmail}
                onChange={(e) => setUsernameOrEmail(e.target.value)}
              />
            </div>
            <div className="input-group mb-2 d-block position-relative">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control w-100 fs-6"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleLogin}
              />
              <span
                className="position-absolute top-50 end-0 translate-middle-y pe-3"
                onClick={togglePasswordVisibility}
                style={{ cursor: "pointer", zIndex: 1 }}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>

              {error && <div className="text-danger float-end mb-2">{error}</div>}
            </div>
            <div className="mb-2">
              <button onClick={handleLogin} className="btn bttn-primary btn-lg w-100 fs-6">
                Login
              </button>
            </div>
            <div className="d-flex align-items-center justify-content-center flex-md-row">
              <a href="/register" className="p-2">
                Create New Account
              </a>
              <p className="">|</p>
              <a href="/forgotpassword" className="p-2">
                Forgot Password
              </a>
            </div>
            <hr />
            <div className="input-group mb-3">
              <div className="w-100">
                <GoogleLogin
                  clientId={clientId}
                  buttonText="Login with Google"
                  onClick={() => setGoogleLogin(true)}
                  onSuccess={onSuccess}
                  onFailure={onFailure}
                  cookiePolicy={"single_host_origin"}
                  render={(renderProps) => (
                    <button onClick={renderProps.onClick} className="gsi-material-button w-100 bttn-primary">
                      <div className="gsi-material-button-content-wrapper bttn-primary">
                        <div className="gsi-material-button-label">Sign in with Google</div>
                      </div>
                    </button>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        {location.pathname !== "/courses" && ( //location.pathname !== "/admin" &&
          <div className="col-md-6 d-flex justify-content-center align-items-center flex-column left-box">
            <div className="featured-image">
              <img src={boxImage} className="img-fluid" alt="Featured" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
