import React, { useContext, useEffect, useState } from "react";
import "./MentorDashboard.scss";
import { FaCalendarAlt, FaUsers, FaClock, FaSpinner } from "react-icons/fa";
import Navbar from "./navbar";
import Footer from "./home/vrddhim-footer";
import { HttpServiceContext } from "../config/httpContext";

const MentorDashboard = () => {
  const [todaysClass, setTodaysClass] = useState(null);
  const [upcomingClasses, setUpcomingClasses] = useState([]);
  const [batches, setBatches] = useState();
  const [name, setName] = useState();
  const [error, setError] = useState(null);
  const httpService = useContext(HttpServiceContext);

  useEffect(() => {
    checkLoginStatus();
    setTimeout(() => {
      getMentorData();
      fetchProfile();
    }, 500);
  }, []);

  const checkLoginStatus = async () => {
    let response = await httpService.checkIsLogined();
    if (response?.data?.user_id) {
      httpService.setLoginedUserId(response.data.user_id);
      document.cookie = `userid=${response.data.user_id}; path=/  ; SameSite=Strict`;
    } else {
      console.error("Error checking login status:", response?.data.error);
    }
  };

  async function getMentorData() {
    let response = await httpService.getMentorsBatchDetails(httpService.loginedUserId);
    if (response) {
      setBatches(response.batches);
    }
  }

  async function fetchProfile() {
    let response = await httpService.getProfile();
    if (response) {
      setName(response.mentor_name);
    } else {
      setError("Failed to fetch profile");
    }
  }

  function handleProceedClick() {}
  return (
    <div>
      <Navbar />
      <div className="container cards-container my-3">
        <h2 className="elegant-heading text-center"> Welcome {name}</h2>
        <div className="enrollment-page">
          <div className="batch-list row justify-content-center">
            {batches?.length > 0 ? (
              batches.map((batch) => (
                <div className="batch-card bdr-primary col-5" key={batch.batch_id}>
                  <div className="batch-card-header">
                    <h5 className="batch-title">{batch.batch_name}</h5>
                    <hr className="divider" />
                  </div>
                  <div className="batch-card-body">
                    <div className="p-2">
                      <p className="batch-course-name">{batch.course_name}</p>
                      <p className="batch-date">
                        <FaCalendarAlt className="icon" />
                        {batch.batch_date}
                      </p>
                      <p className="batch-time">
                        <FaClock className="icon" />
                        {batch.start_time} - {batch.end_time}
                      </p>
                      <p className="batch-enrollment mb-2">
                        <FaUsers className="icon" />
                        {batch.enrolled}/{batch.max_students} students enrolled
                      </p>
                    </div>
                    <button className="payment-button" onClick={() => handleProceedClick(batch)}>
                      Join Now
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p>As of now, there are no classes scheduled for you to take as a mentor.</p>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MentorDashboard;
