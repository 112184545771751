import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

function CourseLineChart() {
  const options = {
    chart: {
      type: "line",
    },
    title: {
      text: "Course Engagement Trends",
      style: {
        color: "#333",
        fontWeight: "bold",
        fontSize: "18px",
      },
    },
    xAxis: {
      categories: ["2023 Q1", "2023 Q2", "2023 Q3", "2023 Q4", "2024 Q1"],
      labels: {
        style: {
          color: "#333",
        },
      },
    },
    yAxis: {
      title: {
        text: "Number of Users",
      },
      labels: {
        style: {
          color: "#333",
        },
      },
    },
    legend: {
      itemStyle: {
        color: "#333",
      },
    },
    series: [
      {
        name: "User Registrations",
        data: [200, 300, 400, 350, 500],
        color: "rgba(255, 99, 132, 0.8)",
      },
    ],
    plotOptions: {
      series: {
        marker: {
          enabled: true,
          radius: 3,
        },
        dataLabels: {
          enabled: true,
          format: "{point.y}",
          style: {
            color: "#333",
          },
        },
      },
    },
  };

  return (
    <div>
      <h2>No of courses visited</h2>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}

export default CourseLineChart;
