import React from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import Navbar from "./navbar";
import Footer from "./home/vrddhim-footer";

const PrivacyPolicy = () => {
  window.scrollTo(0, 0);

  return (
    <section className="privacy-policy" style={{ backgroundColor: "#f1f9f1" }}>
      <Navbar />
      <div className="container">
        <div className="container my-3">
          <h2 className="elegant-heading text-center">Privacy Policy</h2>

          <div className="card shadow-lg mb-4">
            <div className="card-body">
              <h4 className="text-secondary mb-3">
                <i className="bi bi-shield-lock-fill"></i> 1. Introduction
              </h4>
              <p className="graceful-paragraph text-dark">
                At <strong>Vrddhim</strong>, we are committed to protecting your privacy. This Privacy Policy outlines the types of personal data we
                collect, how we use it, and your rights regarding your information. By using our platform, you agree to the collection and use of
                information in accordance with this policy.
              </p>
            </div>
          </div>

          <div className="card shadow-lg mb-4">
            <div className="card-body">
              <h4 className="text-secondary mb-3">
                <i className="bi bi-people-fill"></i> 2. Data Collection
              </h4>
              <ul className="list-group mb-4">
                <li className="list-group-item">
                  <strong className="badge bg-primary me-2">1</strong>
                  <strong>Personal Information:</strong> We collect personal data such as your name, email, phone number, and payment details when you
                  register or make purchases on the platform.
                </li>
                <li className="list-group-item">
                  <strong className="badge bg-primary me-2">2</strong>
                  <strong>Usage Data:</strong> We collect information about how you interact with the platform, including the pages you visit, your IP
                  address, browser type, and device information.
                </li>
                <li className="list-group-item">
                  <strong className="badge bg-primary me-2">3</strong>
                  <strong>Cookies and Tracking Technologies:</strong> We use cookies to enhance your experience. You can choose to disable cookies,
                  but some features may not function properly without them.
                </li>
              </ul>
            </div>
          </div>

          <div className="card shadow-lg mb-4">
            <div className="card-body">
              <h4 className="text-secondary mb-3">
                <i className="bi bi-shield-check"></i> 3. How We Use Your Data
              </h4>
              <p className="graceful-paragraph text-dark">We use the collected data for various purposes:</p>
              <ul className="list-group mb-4">
                <li className="list-group-item">
                  <strong className="badge bg-success me-2">1</strong> To provide and maintain the platform.
                </li>
                <li className="list-group-item">
                  <strong className="badge bg-success me-2">2</strong> To process payments and manage your subscriptions.
                </li>
                <li className="list-group-item">
                  <strong className="badge bg-success me-2">3</strong> To improve and personalize your experience on the platform.
                </li>
                <li className="list-group-item">
                  <strong className="badge bg-success me-2">4</strong> To communicate with you regarding updates, promotions, or support.
                </li>
                <li className="list-group-item">
                  <strong className="badge bg-success me-2">5</strong> To monitor platform usage and prevent fraud or unauthorized activities.
                </li>
              </ul>
            </div>
          </div>

          <div className="card shadow-lg mb-4">
            <div className="card-body">
              <h4 className="text-secondary mb-3">
                <i className="bi bi-hand-thumbs-up"></i> 4. Data Protection
              </h4>
              <ul className="list-group mb-4">
                <li className="list-group-item">
                  <strong className="badge bg-danger me-2">1</strong> We implement security measures such as encryption to safeguard your personal
                  information.
                </li>
                <li className="list-group-item">
                  <strong className="badge bg-danger me-2">2</strong> While we strive to use commercially acceptable means to protect your data, no
                  method of transmission over the internet is 100% secure.
                </li>
              </ul>
            </div>
          </div>

          <div className="card shadow-lg mb-4">
            <div className="card-body">
              <h4 className="text-secondary mb-3">
                <i className="bi bi-arrow-repeat"></i> 5. Data Sharing and Disclosure
              </h4>
              <ul className="list-group mb-4">
                <li className="list-group-item">
                  <strong className="badge bg-info me-2">1</strong> We do not sell or rent your personal data to third parties.
                </li>
                <li className="list-group-item">
                  <strong className="badge bg-info me-2">2</strong> We may share your data with trusted partners for the purpose of improving our
                  services, processing payments, or fulfilling legal obligations.
                </li>
                <li className="list-group-item">
                  <strong className="badge bg-info me-2">3</strong> If required by law or to protect the rights of the platform, we may disclose your
                  personal information to relevant authorities.
                </li>
              </ul>
            </div>
          </div>

          <div className="card shadow-lg mb-4">
            <div className="card-body">
              <h4 className="text-secondary mb-3">
                <i className="bi bi-clock-history"></i> 6. Data Retention
              </h4>
              <ul className="list-group mb-4">
                <li className="list-group-item">
                  <strong className="badge bg-primary me-2">1</strong> We retain your personal data for as long as necessary to fulfill the purposes
                  outlined in this policy, unless a longer retention period is required by law.
                </li>
              </ul>
            </div>
          </div>

          <div className="card shadow-lg mb-4">
            <div className="card-body">
              <h4 className="text-secondary mb-3">
                <i className="bi bi-sliders"></i> 7. Your Data Rights
              </h4>
              <ul className="list-group mb-4">
                <li className="list-group-item">
                  <strong className="badge bg-warning me-2">1</strong> You have the right to access, correct, or delete your personal information at
                  any time by contacting our support team.
                </li>
                <li className="list-group-item">
                  <strong className="badge bg-warning me-2">2</strong> You may also request a copy of your personal data that we hold and ask us to
                  stop processing your data in certain circumstances.
                </li>
              </ul>
            </div>
          </div>

          <div className="card shadow-lg mb-4">
            <div className="card-body">
              <h4 className="text-secondary mb-3">
                <i className="bi bi-arrow-clockwise"></i> 8. Policy Updates
              </h4>
              <p className="graceful-paragraph text-dark">
                We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on this page. You are
                advised to review this policy periodically for any updates.
              </p>
            </div>
          </div>

          <div className="text-center">
            <p className="graceful-paragraph">
              <i className="bi bi-info-circle"></i> If you have any questions about this Privacy Policy, please contact our support team at{" "}
              <a href="mailto:support@vrddhim.com">support@vrddhim.com</a>.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default PrivacyPolicy;
