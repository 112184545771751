import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../styles/pages/Payments.scss";

const PurchaseCourse = () => {
  // Hardcoded values for courseId and userId
  const courseId = 4;
  const userId = 12;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Load the Razorpay checkout script
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const initiatePayment = async () => {
    if (!courseId || !userId) {
      alert("Course ID or User ID is missing.");
      return;
    }

    setLoading(true);

    try {
      // Create order
      const response = await axios.post(`https://api.vrddhim.org/courses/${courseId}/users/${userId}/create_order`, {
        amount: 8000, // Amount in paise (e.g., 50000 paise = 500 INR)
      });

      const { order_id, amount } = response.data;

      const options = {
        key: "rzp_test_bXLEzTTyKSEPdk",
        amount: amount,
        currency: "INR",
        name: "Vrddhim",
        description: "Course Purchase",
        order_id: order_id,
        handler: async function (response) {
          try {
            // Verify payment
            await axios.post(`https://api.vrddhim.org/courses/${courseId}/users/${userId}/verify_payment`, {
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
            });

            await axios.post("https://api.vrddhim.org/make_payment", {
              enrollment_id: order_id,
              amount: amount,
              payment_method: "Razorpay",
              currency: "INR",
            });
          } catch (error) {
            console.error("Error verifying payment:", error);
            alert("Payment verification failed. Please contact support.");
          }
        },
        prefill: {
          name: "Your Name",
          email: "your-email@example.com",
          contact: "9999999999",
        },
        notes: {
          address: "Vrddhim Address",
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    } catch (error) {
      console.error("Error initiating payment:", error);
      alert("Payment initiation failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="payment-container">
      <h2>Purchase Course</h2>
      <button onClick={initiatePayment} disabled={loading}>
        {loading ? <div className="loader"></div> : "Enroll Now"}
      </button>
    </div>
  );
};

export default PurchaseCourse;
