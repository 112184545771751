// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.site-analytics_analyticsContainer__MEAFD {
  padding: 20px;
}

.site-analytics_headerContainer__P-wZY {
  margin-bottom: 20px;
}

.site-analytics_pageTitle__ujUp3 {
  text-align: center;
  font-size: 2.5em;
  color: #000000;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  margin-bottom: 30px;
}

.site-analytics_chartWrapper__zvLe5 {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.site-analytics_chartSection__BaNga {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.site-analytics_chartCard__FxPGQ {
  flex: 1 1;
  min-width: 300px;
  height: 500px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  padding: 20px;
  padding-bottom: 30px;
}

.site-analytics_chartCard__FxPGQ:hover {
  transform: scale(1.03);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
}

.site-analytics_sectionGap__BEDnD {
  height: 40px;
}`, "",{"version":3,"sources":["webpack://./src/styles/pages/site-analytics.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,kBAAA;EACA,gBAAA;EACA,cAAA;EACA,2CAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,SAAA;AACF;;AAEA;EACE,SAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,0CAAA;EACA,gBAAA;EACA,2CAAA;EACA,aAAA;EACA,oBAAA;AACF;;AAEA;EACE,sBAAA;EACA,2CAAA;AACF;;AAEA;EACE,YAAA;AACF","sourcesContent":[".analyticsContainer {\n  padding: 20px;\n}\n\n.headerContainer {\n  margin-bottom: 20px;\n}\n\n.pageTitle {\n  text-align: center;\n  font-size: 2.5em;\n  color: #000000;\n  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);\n  margin-bottom: 30px;\n}\n\n.chartWrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 40px;\n}\n\n.chartSection {\n  display: flex;\n  justify-content: space-between;\n  gap: 20px;\n}\n\n.chartCard {\n  flex: 1;\n  min-width: 300px;\n  height: 500px;\n  background-color: #fff;\n  border-radius: 8px;\n  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);\n  overflow: hidden;\n  transition: transform 0.3s, box-shadow 0.3s;\n  padding: 20px;\n  padding-bottom: 30px;\n}\n\n.chartCard:hover {\n  transform: scale(1.03);\n  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);\n}\n\n.sectionGap {\n  height: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"analyticsContainer": `site-analytics_analyticsContainer__MEAFD`,
	"headerContainer": `site-analytics_headerContainer__P-wZY`,
	"pageTitle": `site-analytics_pageTitle__ujUp3`,
	"chartWrapper": `site-analytics_chartWrapper__zvLe5`,
	"chartSection": `site-analytics_chartSection__BaNga`,
	"chartCard": `site-analytics_chartCard__FxPGQ`,
	"sectionGap": `site-analytics_sectionGap__BEDnD`
};
export default ___CSS_LOADER_EXPORT___;
