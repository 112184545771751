// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-container {
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.5725490196);
  box-shadow: 0px 0px 8px rgb(0, 0, 0);
}

.echarts {
  width: 100%;
  height: 100%;
}

.chart-container:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}`, "",{"version":3,"sources":["webpack://./src/styles/pages/overview-activitychart.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,mDAAA;EACA,oCAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,yCAAA;AACF","sourcesContent":[".chart-container {\n  width: 100%;\n  height: 100%;\n  padding: 10px;\n  box-sizing: border-box;\n  border-radius: 8px;\n  background-color: #ffffff92;\n  box-shadow: 0px 0px 8px rgb(0, 0, 0);\n}\n\n.echarts {\n  width: 100%;\n  height: 100%;\n}\n\n.chart-container:hover {\n  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
