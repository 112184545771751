// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-holder-here {
  background: linear-gradient(90deg, rgb(48, 48, 48) 0%, rgb(89, 88, 88) 35%, rgb(77, 77, 77) 100%);
  background-size: cover;
  background-position: center;
  height: 100vh;
  position: relative;
  background-repeat: no-repeat;
}
.bg-holder-here .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  text-align: center;
  color: #fff;
  z-index: 1;
}
.bg-holder-here .overlay h4,
.bg-holder-here .overlay h3 {
  margin: 0;
  animation: fadeInUp 0.5s ease;
}
.bg-holder-here .overlay .btn {
  margin-top: 1rem;
  border-radius: 30px;
}

@keyframes fadeInUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@media (max-width: 768px) {
  .bg-holder-here {
    height: 70vh;
  }
  .overlay {
    padding: 10px;
  }
  .text-center h4 {
    font-size: 1.5rem;
  }
  .text-center h3 {
    font-size: 1.8rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/pages/landing.scss"],"names":[],"mappings":"AAAA;EACE,iGAAA;EACA,sBAAA;EACA,2BAAA;EACA,aAAA;EACA,kBAAA;EACA,4BAAA;AACF;AACE;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,oCAAA;EACA,aAAA;EACA,kBAAA;EACA,WAAA;EACA,UAAA;AACJ;AACI;;EAEE,SAAA;EACA,6BAAA;AACN;AAEI;EACE,gBAAA;EACA,mBAAA;AAAN;;AAKA;EACE;IACE,2BAAA;IACA,UAAA;EAFF;EAIA;IACE,wBAAA;IACA,UAAA;EAFF;AACF;AAKA;EACE;IACE,YAAA;EAHF;EAMA;IACE,aAAA;EAJF;EAQE;IACE,iBAAA;EANJ;EASE;IACE,iBAAA;EAPJ;AACF","sourcesContent":[".bg-holder-here {\n  background: linear-gradient(90deg, rgba(48, 48, 48, 1) 0%, rgba(89, 88, 88, 1) 35%, rgba(77, 77, 77, 1) 100%);\n  background-size: cover;\n  background-position: center;\n  height: 100vh;\n  position: relative;\n  background-repeat: no-repeat;\n\n  .overlay {\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: rgba(0, 0, 0, 0.5);\n    padding: 20px;\n    text-align: center;\n    color: #fff;\n    z-index: 1;\n\n    h4,\n    h3 {\n      margin: 0;\n      animation: fadeInUp 0.5s ease;\n    }\n\n    .btn {\n      margin-top: 1rem;\n      border-radius: 30px;\n    }\n  }\n}\n\n@keyframes fadeInUp {\n  from {\n    transform: translateY(20px);\n    opacity: 0;\n  }\n  to {\n    transform: translateY(0);\n    opacity: 1;\n  }\n}\n\n@media (max-width: 768px) {\n  .bg-holder-here {\n    height: 70vh;\n  }\n\n  .overlay {\n    padding: 10px;\n  }\n\n  .text-center {\n    h4 {\n      font-size: 1.5rem;\n    }\n\n    h3 {\n      font-size: 1.8rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
