import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

const RegistrationComingSoon = () => {
  const navigate = useNavigate();

  const handleBackToHome = () => {
    navigate("/home"); // Redirect to the homepage or dashboard
  };

  return (
    <div style={{ minHeight: "100vh", padding: "50px 0" }}>
      <Container className="text-center">
        <Row className="justify-content-center">
          <Col md={8} lg={6}>
            <div style={{ padding: "30px", backgroundColor: "#f1f9f1", borderRadius: "10px", boxShadow: "0 0 15px rgba(0,0,0,0.1)" }}>
              <h2 className="mb-4" style={{ color: "#2d6a4f" }}>
                Registration for Courses Will Open Soon!
              </h2>
              <p style={{ fontSize: "1.1rem", color: "#595959" }}>
                Thank you for your interest! We are working to bring you exciting courses. Please check back soon for updates, or head back to the
                homepage.
              </p>
              <div className="mt-4">
                <button className="bttn-primary mx-2" onClick={handleBackToHome}>
                  Back to Home
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RegistrationComingSoon;
