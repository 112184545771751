import React from "react";
import "../../styles/pages/auth.scss";
import { useNavigate } from "react-router-dom";

const SignupPage = () => {
  const navigate = useNavigate();
  return (
    <div className="auth-container">
      <div className="auth-box">
        <h2 className="auth-title">Sign Up</h2>
        <form>
          <div className="input-group">
            <label>Username</label>
            <input type="text" placeholder="Enter your username" />
          </div>
          <div className="input-group">
            <label>Email</label>
            <input type="email" placeholder="Enter your email" />
          </div>
          <div className="input-group">
            <label>Password</label>
            <input type="password" placeholder="Enter your password" />
          </div>
          <button
            className="auth-button"
            type="submit"
            onClick={() => {
              navigate("/admin/dashboard");
            }}
          >
            Sign Up
          </button>
        </form>
        <p className="switch-auth">
          Already have an account? <a href="/admin/login">Login</a>
        </p>
      </div>
    </div>
  );
};

export default SignupPage;
