import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { HttpServiceContext } from "../../config/httpContext";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

  const httpService = useContext(HttpServiceContext);

  async function continuePasswordReset() {
    if (!email) {
      setError("Please enter your email address.");
      return;
    }
    let payload = { email: email };
    let result = await httpService.forgotPasswordEmailVerify(payload);
    if (result && result.message === "A password reset link has been sent to your email.") {
      setSuccess("Password reset link has been successfully sent to your email.");
      setError("");
    } else {
      setError("Something went wrong, please try again.");
    }
  }

  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <div className="row background-primary border rounded-5 shadow box-area1">
        <div className="col-12 p-5">
          <div className="row align-items-center">
            <div className="header-text mb-4 fc-grey text-center">
              <h2>Password Reset</h2>
              <p className="fc-grey mt-2">Please enter your verified email address, and we'll send you a link to reset your password.</p>
            </div>

            {/* Success message */}
            {success ? (
              <div className="alert alert-success text-center">
                <p>{success}</p>
                <button className="btn bttn-primary btn-lg w-100 mt-3" onClick={() => navigate("/login")}>
                  OK
                </button>
              </div>
            ) : (
              <>
                <div className="input-group mb-3">
                  <input
                    type="email"
                    className="form-control form-control-lg fs-6"
                    placeholder="Enter Your Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                {/* Error message */}
                {error && <div className="alert alert-danger">{error}</div>}

                <div className="input-group mb-3">
                  <button className="btn bttn-primary btn-lg w-100 fs-6" onClick={continuePasswordReset}>
                    Continue
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
