import React, { useState } from "react";
import Navbar from "../navbar";
import Footer from "../home/vrddhim-footer";
import courseData from "../../assets/courseDetail.json";
import "../../styles/pages/CourseDetails.scss";
import { useLocation, useNavigate } from "react-router-dom";

function EnrolledCourseDetail() {
  window.scrollTo(0, 0);

  const location = useLocation();
  const navigate = useNavigate();
  const [courses, setCourses] = useState(courseData);
  const courseDetails = location.state?.courseDetails || {};
  const { course_name } = courseDetails;

  const handleEnrollClick = () => {
    navigate("/enrollment", { state: { courseDetails } });
  };
  return (
    <div>
      <Navbar />
      <section className="container cards-container">
        <div className="coursedetails-row">
          <div className="coursedetails-col-lg-12">
            <div className="coursedetails-single-item">
              {courses.map((course) => {
                return (
                  course.course_name == courseDetails.course_name && (
                    <div key={course.course_id}>
                      <div className="coursedetails-down-content">
                        <h2 className="p-1 mb-3 elegant-heading text-center">{course.course_name}</h2>
                        <p>{course.description}</p>
                        <p>
                          <strong className="lh-2">Objective:</strong>{" "}
                          {course.objective.map((obj, index) => (
                            <div className="p-1">
                              <strong>
                                {index + 1}. {obj.title}
                              </strong>
                              {/* <ul> */}
                              {obj.details.map((detail) => (
                                <p>{detail}</p>
                              ))}
                              {/* </ul> */}
                            </div>
                          ))}
                        </p>
                        <p>
                          <strong className="lh-2">Benifits:</strong>{" "}
                          {course.benifits.map((obj, index) => (
                            <div className="p-1">
                              <strong>
                                {index + 1}. {obj.title}
                              </strong>
                              {/* <ul> */}
                              {obj.details.map((detail, i) => (
                                <p key={i}>{detail}</p>
                              ))}
                              {/* </ul> */}
                            </div>
                          ))}
                        </p>
                        <div>Note* : {course.note}</div>
                        {/* <div className="d-flex align-items-center justify-content-between"> */}
                        {/* <button className="coursedetails-main-button-green" onClick={handleEnrollClick}>
                              Enroll Now!
                            </button> */}
                        {/* </div> */}
                      </div>
                    </div>
                  )
                );
              })}
              <div className="text-end">
                <button className="btn bttn-primary" onClick={() => navigate("/courses")}>
                  Back to course
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default EnrolledCourseDetail;
