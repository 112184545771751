import React from "react";
import { Card, Button, Stack, ProgressBar, Image, Dropdown } from "react-bootstrap";
import styles from "../../styles/pages/todays-courses.module.scss";

const placeholderAvatars = [
  "https://images.pexels.com/photos/935835/pexels-photo-935835.jpeg?auto=compress&cs=tinysrgb&w=600",
  "https://images.pexels.com/photos/582039/pexels-photo-582039.jpeg?auto=compress&cs=tinysrgb&w=600",
  "https://images.pexels.com/photos/2043590/pexels-photo-2043590.jpeg?auto=compress&cs=tinysrgb&w=600",
  "https://images.pexels.com/photos/415263/pexels-photo-415263.jpeg?auto=compress&cs=tinysrgb&w=600",
  "https://images.pexels.com/photos/920377/pexels-photo-920377.jpeg?auto=compress&cs=tinysrgb&w=600",
];
const placeholderThumb =
  "https://media.istockphoto.com/id/585769146/photo/black-hardcover-book-mock-up-wall-background.webp?b=1&s=612x612&w=0&k=20&c=9OLTYhgoLbK3qFurDzIGKaTfn6jm2epAzkcT5BuEvDw=";

const taskDetails = [
  {
    id: 1,
    details: "Elementals of English",
  },
  {
    id: 2,
    details: "Intermediate Grammar",
  },
  {
    id: 3,
    details: "Core Tenses",
  },
];

const SubjectsToday = () => {
  return (
    <Card className={`shadow-sm p-3 mb-5 bg-white rounded ${styles["subjects-today-card"]}`}>
      <Card.Header className="d-flex justify-content-between align-items-center">
        <h6 className="mb-0">Subjects Today</h6>
        <Dropdown>
          <Dropdown.Toggle variant="light" id="dropdown-basic" className="p-0 border-0">
            <i className="bi bi-three-dots"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Card.Header>
      <Card.Img variant="top" src={placeholderThumb} alt="subjects_today_image" className="rounded" />
      <Card.Body>
        <h5 className="font-weight-bold">Welcome to the world of Grammars</h5>
        <p className="text-muted">English Grammar</p>

        <div className="d-flex justify-content-between mt-3">
          <span className="font-weight-bold">Progress</span>
          <span className="text-primary font-weight-bold">90%</span>
        </div>
        <ProgressBar now={90} className="mt-2" />

        <Stack direction="horizontal" className="mt-4" gap={3}>
          <div className="text-center">
            <i className="bi bi-clock text-muted"></i>
            <p className="mb-0 font-weight-bold">1 Hour</p>
          </div>
          <div className="d-flex align-items-center">
            {placeholderAvatars.map((src, index) => (
              <Image key={index} src={src} roundedCircle className="border border-light me-1" style={{ width: "40px", height: "40px" }} />
            ))}
          </div>
        </Stack>

        <hr className="my-4" />

        <h6 className="font-weight-bold">Detail Task</h6>

        <Stack className="mt-3" gap={3}>
          {taskDetails.map((task) => (
            <Stack key={task.id} direction="horizontal" gap={3} className="align-items-center">
              <div
                className="d-flex justify-content-center align-items-center bg-primary text-white rounded-circle"
                style={{ width: "36px", height: "36px" }}
              >
                {task.id}
              </div>
              <p className="mb-0 font-weight-bold">{task.details}</p>
            </Stack>
          ))}
        </Stack>
      </Card.Body>
      <Card.Footer className="bg-white">
        <Button variant="dark" className="w-100 rounded-pill">
          Go To Detail
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default SubjectsToday;
