import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTachometerAlt,
  faUsers,
  faBook,
  faChartLine,
  faUserTie,
  faMoneyBillWave,
  faCommentDots,
  faChevronDown,
  faChevronUp,
  faStar,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import styles from "../../styles/pages/admin-sidebar.module.scss";

const SidebarItem = React.memo(({ to, icon, text, isOpen }) => (
  <li className={styles["sidebar-item"]}>
    <Link to={to} className={styles["sidebar-link"]}>
      <FontAwesomeIcon icon={icon} className={styles.icon} />
      {isOpen && <span className={styles.text}>{text}</span>}
    </Link>
  </li>
));

const SidebarDropdown = React.memo(({ icon, text, isOpen, children }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  return (
    <li className={`${styles["sidebar-item"]} ${styles["dropdown-item"]}`}>
      <div className={styles["dropdown-header"]} onClick={toggleDropdown}>
        <FontAwesomeIcon icon={icon} className={styles.icon} />
        {isOpen && (
          <>
            <span className={styles.text}>{text}</span>
            <FontAwesomeIcon icon={isDropdownOpen ? faChevronUp : faChevronDown} className={styles["dropdown-icon"]} />
          </>
        )}
      </div>
      {isDropdownOpen && <ul className={styles["dropdown-menu"]}>{children}</ul>}
    </li>
  );
});

function Sidebar() {
  const [isOpen, setIsOpen] = useState(true);
  const sidebarRef = useRef(null);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target) && event.target.tagName !== "BUTTON") {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={sidebarRef} className={`${styles.sidebar} ${isOpen ? styles.open : styles.closed}`}>
      <div className={styles["sidebar-header"]}>
        <button className={styles["toggle-btn"]} onClick={toggleSidebar}>
          <FontAwesomeIcon icon={faBars} />
        </button>
        {isOpen && <h1></h1>}
      </div>
      <ul className={styles["sidebar-menu"]}>
        <SidebarItem to="/admin/dashboard" icon={faTachometerAlt} text="Dashboard" isOpen={isOpen} />
        <SidebarItem to="/admin/users" icon={faUsers} text="Users" isOpen={isOpen} />
        <SidebarItem to="/admin/courses" icon={faBook} text="Courses" isOpen={isOpen} />
        <SidebarItem to="/admin/analytics" icon={faChartLine} text="Analytics" isOpen={isOpen} />
        <SidebarItem to="/admin/mentors" icon={faUserTie} text="Mentors" isOpen={isOpen} />
        <SidebarItem to="/admin/payments" icon={faMoneyBillWave} text="Payments" isOpen={isOpen} />
        <SidebarItem to="/admin/chat" icon={faCommentDots} text="Chat" isOpen={isOpen} />
        <SidebarItem to="/admin/reviews" icon={faStar} text="Reviews" isOpen={isOpen} />
      </ul>
    </div>
  );
}

export default Sidebar;
