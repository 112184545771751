import React from "react";
import styles from "../../styles/pages/mentors-overview-card.module.scss";
import { Avatar, Typography, Stack, LinearProgress } from "@mui/material";
import TaskIcon from "@mui/icons-material/Task";
import StarIcon from "@mui/icons-material/Star";
import PeopleIcon from "@mui/icons-material/People";

const MentorCard = ({ mentor }) => {
  return (
    <div className={styles.mentorCard}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Avatar src={mentor.image} alt={mentor.name} className={styles.mentorAvatar} />
        <Stack spacing={1} className={styles.mentorInfo}>
          <Typography variant="h6" className={styles.mentorName}>
            {mentor.name}
          </Typography>
          <Typography variant="body2" className={styles.mentorRole}>
            {mentor.role}
          </Typography>

          <div className={styles.progressWrapper}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <TaskIcon fontSize="small" color="action" />
              <Typography variant="body2" className={styles.mentorTasks}>
                {mentor.tasks} Tasks
              </Typography>
            </Stack>
            <LinearProgress variant="determinate" value={mentor.taskCompletion} className={styles.progressBar} />
          </div>

          <Stack direction="row" alignItems="center" spacing={1}>
            <StarIcon fontSize="small" color="action" />
            <Typography variant="body2" className={styles.mentorReviews}>
              {mentor.rating}
            </Typography>
            <PeopleIcon fontSize="small" color="action" />
            <Typography variant="body2">({mentor.reviews} Reviews)</Typography>
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};

export default MentorCard;
