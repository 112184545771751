import React, { useRef, useState, useEffect, useContext } from "react";
import { Modal, Button, Table } from "react-bootstrap";
import html2pdf from "html2pdf.js";
import styles from "../../styles/pages/invoice.module.scss";
import { HttpServiceContext } from "../../config/httpContext";

function Invoice({ transactionId, userDetail, courseDetails, enrolledBatch, onClose }) {
  const invoiceRef = useRef(null);
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [items, setItems] = useState([]);
  const httpService = useContext(HttpServiceContext);

  const fetchInvoiceDetails = async () => {
    let response = await httpService.getInvoiceData(transactionId);
    if (response) {
      setInvoiceDetails(response);
      setItems(response.items || []);
    } else {
      console.error("Error fetching invoice details:");
    }
  };

  useEffect(() => {
    fetchInvoiceDetails();
  }, []);

  const companyDetails = {
    name: "VRDDHIM INSTITUTE",
    address: "Mangalore, Karnataka",
    email: "info@vrddhim.net",
    phone: "+91-9876543210",
  };

  const calculateSubtotal = () => {
    return items.reduce((total, item) => total + item.rate, 0);
  };

  const handleDownload = () => {
    const element = invoiceRef.current;
    const options = {
      margin: 0.5,
      filename: `invoice_${invoiceDetails.invoice_id}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    html2pdf().from(element).set(options).save();
  };

  return (
    <Modal show={true} onHide={onClose} centered size="lg">
      <Modal.Body className={styles.invoiceBody}>
        <div ref={invoiceRef} className={styles.invoiceContent}>
          <div className={styles.header}>
            <div className={styles.companyDetails}>
              <img src={require("../../assets/images/vrddhim-logo.png")} alt="Company Logo" className={styles.companyLogo} />
              <div>
                <h6>{companyDetails.name}</h6>
                <p>{companyDetails.address}</p>
                <p>Email: {companyDetails.email}</p>
                <p>Phone: {companyDetails.phone}</p>
              </div>
            </div>

            <div className={styles.invoiceInfo}>
              <h2 className="eloquent-title">INVOICE</h2>
              <p className="fw-bold">Billed To:</p>
              <p>{userDetail.username || "User Name"}</p>
              <p>Email: {userDetail.email}</p>
              <p>Phone: {userDetail.phone_number}</p>
              <p>
                <strong>Invoice No:</strong> {invoiceDetails.number}
              </p>
              <p>
                <strong>Invoice Date:</strong> {invoiceDetails.invoice_date}
              </p>
              <p>
                <strong>Payment Id</strong> {invoiceDetails.payment_id}
              </p>
              <p>
                <strong>Transaction Id</strong> {invoiceDetails.transaction_id}
              </p>
            </div>
          </div>

          <Table bordered className={`${styles.invoiceTable} ${styles.customTable}`}>
            <thead>
              <tr className={styles.tableHeader}>
                <th style={{ width: "90px" }}>Serial No</th>
                <th>Course Name</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr className={styles.tableRow}>
                <td>1</td>
                <td>{invoiceDetails.course_name}</td>
                <td>{invoiceDetails.payment_amount}</td>
              </tr>
            </tbody>
          </Table>

          <div className={styles.paymentTerms}>
            <h5>Payment Terms</h5>
            <p>
              <strong>No refunds will be issued for any courses purchased.</strong>
            </p>
          </div>

          <div className={styles.totals}>
            <p>
              <strong>Subtotal:</strong> {invoiceDetails.subtotal}
            </p>
            <p>
              <strong>Tax (0%):</strong> {invoiceDetails.tax}
            </p>
            <p>
              <strong>Discount:</strong> -{invoiceDetails.discount}
            </p>
            <p>
              <strong>Total Amount Due:</strong> {invoiceDetails.total_amount_due}
            </p>
          </div>

          <div className={styles.footerNotes}>
            <p>Thank you for purchasing the course!</p>
            <p>
              If you have any questions, please reach out to us at <a href="mailto:info@vrddhim.net">info@vrddhim.net</a>.
            </p>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className={styles.footer}>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>

        <Button variant="success" onClick={handleDownload}>
          Download Invoice
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Invoice;
