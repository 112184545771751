import React from "react";
import { Container, Row, Col, Card, Button, ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faTwitter, faLinkedin, faInstagram } from "@fortawesome/free-brands-svg-icons";
import "../../styles/pages/admin-profile.scss";

const adminProfile = {
  name: "Vriddhim",
  role: "Administrator",
  email: "vriddhim@example.com",
  phone: "+1234567890",
  address: "123 Admin Street, Suite 45, Admin City, Admin Country",
  country: "Admin Country",
  city: "Admin City",
  socialLinks: {
    facebook: "https://facebook.com/vriddhim",
    twitter: "https://twitter.com/vriddhim",
    linkedin: "https://linkedin.com/in/vriddhim",
    instagram: "https://instagram.com/vriddhim",
  },
};

const AdminProfile = () => {
  return (
    <Container className="profile-container">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card className="profile-card">
            <Card.Body>
              <Row>
                <Col md={4} className="text-center">
                  <div className="profile-avatar">
                    <img
                      src="https://images.unsplash.com/photo-1620379732446-61a664cc81dc?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fHYlMjB3b3JkJTIwaW1hZ2VzfGVufDB8fDB8fHww"
                      alt="Profile Avatar"
                    />
                  </div>
                </Col>
                <Col md={8}>
                  <h2 className="profile-name">{adminProfile.name}</h2>
                  <p className="profile-role">{adminProfile.role}</p>
                  <ListGroup className="profile-info">
                    <ListGroup.Item>
                      <strong>Email:</strong> {adminProfile.email}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <strong>Phone:</strong> {adminProfile.phone}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <strong>Address:</strong> {adminProfile.address}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <strong>Country:</strong> {adminProfile.country}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <strong>City:</strong> {adminProfile.city}
                    </ListGroup.Item>
                  </ListGroup>
                  <div className="profile-actions">
                    <Button variant="outline-dark" className="action-button">
                      Edit Profile
                    </Button>
                    <Button variant="outline-dark" className="action-button">
                      Change Password
                    </Button>
                    <Button variant="outline-dark" className="action-button">
                      Manage Settings
                    </Button>
                  </div>
                  <div className="profile-socials">
                    <a href={adminProfile.socialLinks.facebook} className="social-icon" aria-label="Facebook">
                      <FontAwesomeIcon icon={faFacebook} />
                    </a>
                    <a href={adminProfile.socialLinks.twitter} className="social-icon" aria-label="Twitter">
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                    <a href={adminProfile.socialLinks.linkedin} className="social-icon" aria-label="LinkedIn">
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                    <a href={adminProfile.socialLinks.instagram} className="social-icon" aria-label="Instagram">
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminProfile;
