import React, { useState, useRef, useEffect } from "react";
import { FaEnvelope, FaTimes } from "react-icons/fa";
import "./notification.scss";

const Notification = () => {
  const [notifications, setNotifications] = useState([
    {
      id: 1,
      title: "Book Purchase",
      description: "You successfully purchased 'React for Beginners'.",
      type: "purchase",
      read: false,
    },
    {
      id: 2,
      title: "Class Scheduled",
      description: "Your class 'Web Development 101' is scheduled for tomorrow at 10 AM.",
      type: "class",
      read: false,
    },
    {
      id: 3,
      title: "New Update",
      description: "A new version of the 'Python Advanced' course has been released.",
      type: "update",
      read: false,
    },
  ]);

  const [isOpen, setIsOpen] = useState(false);
  const modalRef = useRef(null);

  const markAsRead = (id) => {
    setNotifications((prev) => prev.map((notification) => (notification.id === id ? { ...notification, read: true } : notification)));
  };

  const dismissNotification = (id) => {
    setNotifications((prev) => prev.filter((notification) => notification.id !== id));
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <button className="home-notify-button" onClick={togglePopup}>
        <FaEnvelope /> Notifications
      </button>
      {isOpen && (
        <div className="home-notify-overlay">
          <div className="home-notify-panel" ref={modalRef}>
            <div className="home-notify-header-container">
              <h3 className="home-notify-header">Notifications</h3>
              <FaTimes className="home-notify-close" onClick={togglePopup} />
            </div>
            {notifications.length > 0 ? (
              <div className="home-notify-list">
                {notifications.map((notification) => (
                  <div key={notification.id} className={`home-notify-item ${notification.read ? "read" : ""}`}>
                    <div className="home-notify-icon">
                      <FaEnvelope />
                    </div>
                    <div className="home-notify-content">
                      <span className="home-notify-title">{notification.title}</span>
                      <span className="home-notify-description">{notification.description}</span>
                    </div>
                    <div className="home-notify-actions">
                      {!notification.read && (
                        <button className="home-notify-mark-read" onClick={() => markAsRead(notification.id)}>
                          Mark as Read
                        </button>
                      )}
                      <FaTimes className="home-notify-dismiss" onClick={() => dismissNotification(notification.id)} />
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className="home-notify-empty">No new notifications</p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Notification;
