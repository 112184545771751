import React from "react";
import { Link } from "react-router-dom";
import "../../styles/pages/joinus.scss";

const Joinus = () => {
  return (
    <section className="joinus-section">
      <div className="container story-container ">
        <h2 className="text-center elegant-heading">Join Us</h2>
        <h3 className="text-subtitle text-center">Enroll Today for Eloquent English Sessions</h3>
        <div className="row align-items-center">
          <div className="image-section col-12 col-lg-4 col-md-4 col-sm-4">
            <img
              src="https://img.freepik.com/premium-photo/young-meeting-support-hands-group-friendship-business-work-togetherness-teamwork-professional-together_163305-210130.jpg"
              alt="Instructor"
              className="img-fluid rounded-circle animated-border"
            />
          </div>
          <div className="text-section col-12 col-lg-8 col-md-8 col-sm-8">
            <div className="description-box shadow-sm">
              <h4 className="text-highlight font-weight-bold">Master English Communication with Ease</h4>
              <p className="text-description">
                Begin your journey towards mastering English communication with our experts. Click &#39;Enroll Now&#39; to explore our courses that
                enhances four quadrants of the language, suitable for all levels. Our instructors ensure a seamless learning experience that will
                boost your confidence and skills.
              </p>
              <div className="stats d-flex flex-wrap justify-content-center mt-3 font-weight-bold">
                <div className="stat-item mx-2">
                  <p className="text-highlight">
                    <strong>5000+</strong> Learners
                  </p>
                </div>
                <div className="stat-item mx-2">
                  <p className="text-highlight">
                    <strong>20+</strong> Years
                  </p>
                </div>
                <div className="stat-item mx-2">
                  <p className="text-highlight">
                    <strong>4.5+</strong> Rating
                  </p>
                </div>
              </div>
              <div className="text-center mt-4">
                <Link to="/courses">
                  <button className="btn btn-enroll">Enroll Now &rarr;</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Joinus;
