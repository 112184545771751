// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  background-color: #f1f9f1;
  color: #595959;
  padding: 10px 20px;
  text-align: center;
  font-size: 0.9rem;
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  z-index: 100;
}

.footer-content {
  display: inline-block;
}

.footer-link {
  color: #595959;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/styles/pages/adminFooter.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,cAAA;EACA,kBAAA;EACA,kBAAA;EACA,iBAAA;EACA,kBAAA;EACA,SAAA;EACA,OAAA;EACA,WAAA;EACA,yCAAA;EACA,YAAA;AACF;;AAEA;EACE,qBAAA;AACF;;AAEA;EACE,cAAA;EACA,qBAAA;AACF;;AAEA;EACE,0BAAA;AACF","sourcesContent":[".footer {\n  background-color: #f1f9f1;\n  color: #595959;\n  padding: 10px 20px;\n  text-align: center;\n  font-size: 0.9rem;\n  position: relative;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);\n  z-index: 100;\n}\n\n.footer-content {\n  display: inline-block;\n}\n\n.footer-link {\n  color: #595959;\n  text-decoration: none;\n}\n\n.footer-link:hover {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
