import React from "react";
import { Line } from "react-chartjs-2";
import { Chart, CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend } from "chart.js";

// Register required components
Chart.register(CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend);

function PaymentsChart() {
  const data = {
    labels: ["January", "February", "March", "April", "May"],
    datasets: [
      {
        label: "Payments (INR)",
        fill: false,
        lineTension: 0.3,
        backgroundColor: "#e0e0e0",
        borderColor: "#ffcc00",
        borderWidth: 2,
        pointBackgroundColor: "#ffcc00",
        pointBorderColor: "#1a1a1a",
        pointBorderWidth: 3,
        pointHoverBackgroundColor: "#1a1a1a",
        pointHoverBorderColor: "#ffcc00",
        pointHoverBorderWidth: 3,
        pointRadius: 5,
        hoverRadius: 7,
        data: [2800, 2500, 3000, 3200, 2900],
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        labels: {
          color: "black",
        },
      },
      title: {
        display: true,
        text: "Monthly Payments",
        color: "black",
      },
    },
    scales: {
      x: {
        ticks: {
          color: "black",
        },
      },
      y: {
        ticks: {
          color: "black",
        },
      },
    },
    animation: {
      duration: 1000,
    },
  };

  return <Line data={data} options={options} />;
}

export default PaymentsChart;
