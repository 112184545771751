// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course-overview-table_task-overview-table__TsRj3 {
  width: 100%;
  border-collapse: collapse;
}

.course-overview-table_MuiDataGrid-root__n1c58 {
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.course-overview-table_MuiDataGrid-row__gboqe:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.course-overview-table_progress-container__2rg69 {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.course-overview-table_progress-bar__toP3I {
  width: 100%;
  height: 5px;
  background-color: white;
  border-radius: 4px;
}

.course-overview-table_progress-bar-inner__aEcTp {
  height: 100%;
  background-color: #4caf50;
  border-radius: 4px;
}

.course-overview-table_status-chip__N6xOI {
  padding: 0.25rem 0.5rem;
  border-radius: 16px;
  text-align: center;
  color: white;
  text-transform: capitalize;
}

.course-overview-table_status-chip__N6xOI.course-overview-table_in-progress__PTMC\\+ {
  background-color: #2196f3;
}

.course-overview-table_status-chip__N6xOI.course-overview-table_completed__q5ZnX {
  background-color: #4caf50;
}

.course-overview-table_status-chip__N6xOI.course-overview-table_pending__JwvD0 {
  background-color: #ff9800;
}

.course-overview-table_status-chip__N6xOI.course-overview-table_other__VY1Af {
  background-color: #00bcd4;
}`, "",{"version":3,"sources":["webpack://./src/styles/pages/course-overview-table.module.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,yBAAA;AAAF;;AAIA;EACE,kBAAA;EACA,gBAAA;EACA,yBAAA;AADF;;AAKA;EACE,qCAAA;AAFF;;AAMA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;AAHF;;AAMA;EACE,WAAA;EACA,WAAA;EACA,uBAAA;EACA,kBAAA;AAHF;;AAMA;EACE,YAAA;EACA,yBAAA;EACA,kBAAA;AAHF;;AAOA;EACE,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,0BAAA;AAJF;;AAQA;EACE,yBAAA;AALF;;AAQA;EACE,yBAAA;AALF;;AAQA;EACE,yBAAA;AALF;;AAQA;EACE,yBAAA;AALF","sourcesContent":["\n.task-overview-table {\n  width: 100%;\n  border-collapse: collapse;\n}\n\n\n.MuiDataGrid-root {\n  border-radius: 8px;\n  overflow: hidden;\n  transition: all 0.3s ease;\n}\n\n\n.MuiDataGrid-row:hover {\n  background-color: rgba(0, 0, 0, 0.04);\n}\n\n\n.progress-container {\n  display: flex;\n  align-items: center;\n  gap: 0.5rem;\n}\n\n.progress-bar {\n  width: 100%;\n  height: 5px;\n  background-color: white;\n  border-radius: 4px;\n}\n\n.progress-bar-inner {\n  height: 100%;\n  background-color: #4caf50;\n  border-radius: 4px;\n}\n\n\n.status-chip {\n  padding: 0.25rem 0.5rem;\n  border-radius: 16px;\n  text-align: center;\n  color: white;\n  text-transform: capitalize;\n}\n\n\n.status-chip.in-progress {\n  background-color: #2196f3;\n}\n\n.status-chip.completed {\n  background-color: #4caf50;\n}\n\n.status-chip.pending {\n  background-color: #ff9800;\n}\n\n.status-chip.other {\n  background-color: #00bcd4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"task-overview-table": `course-overview-table_task-overview-table__TsRj3`,
	"MuiDataGrid-root": `course-overview-table_MuiDataGrid-root__n1c58`,
	"MuiDataGrid-row": `course-overview-table_MuiDataGrid-row__gboqe`,
	"progress-container": `course-overview-table_progress-container__2rg69`,
	"progress-bar": `course-overview-table_progress-bar__toP3I`,
	"progress-bar-inner": `course-overview-table_progress-bar-inner__aEcTp`,
	"status-chip": `course-overview-table_status-chip__N6xOI`,
	"in-progress": `course-overview-table_in-progress__PTMC+`,
	"completed": `course-overview-table_completed__q5ZnX`,
	"pending": `course-overview-table_pending__JwvD0`,
	"other": `course-overview-table_other__VY1Af`
};
export default ___CSS_LOADER_EXPORT___;
