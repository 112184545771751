// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invoice_invoiceContent__-rtbD {
  padding: 20px;
}

.invoice_header__BowF4 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 2px solid #e5e5e5;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.invoice_companyDetails__zAaNo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.invoice_companyLogo__0Rlxb {
  height: 100px;
  width: 90px;
  justify-content: center;
}

.invoice_invoiceInfo__D6G\\+G {
  text-align: end;
}

.invoice_invoiceTable__buiH9 {
  margin-top: 20px;
}

.invoice_paymentTerms__3MftP {
  margin-top: 20px;
  font-size: 0.9em;
}

.invoice_totals__DMVrc {
  margin-top: 20px;
  font-size: 1.1em;
  text-align: right;
}

.invoice_footerNotes__9jtuw {
  margin-top: 20px;
  font-style: italic;
}

.invoice_footer__d55xs {
  text-align: center;
  padding: 10px;
}

@media (max-width: 576px) {
  .invoice_invoiceContent__-rtbD {
    padding: 10px;
  }
  .invoice_header__BowF4 {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .invoice_companyDetails__zAaNo {
    align-items: center;
  }
  .invoice_companyLogo__0Rlxb {
    height: 80px;
    width: 70px;
  }
  .invoice_invoiceInfo__D6G\\+G {
    text-align: center;
    margin-top: 10px;
  }
  .invoice_totals__DMVrc {
    font-size: 1em;
    text-align: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/pages/invoice.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,uBAAA;EACA,gCAAA;EACA,oBAAA;EACA,mBAAA;AACF;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;AADF;;AAIA;EACE,aAAA;EACA,WAAA;EACA,uBAAA;AADF;;AAIA;EACE,eAAA;AADF;;AAIA;EACE,gBAAA;AADF;;AAIA;EACE,gBAAA;EACA,gBAAA;AADF;;AAIA;EACE,gBAAA;EACA,gBAAA;EACA,iBAAA;AADF;;AAIA;EACE,gBAAA;EACA,kBAAA;AADF;;AAIA;EACE,kBAAA;EACA,aAAA;AADF;;AAIA;EACE;IACE,aAAA;EADF;EAIA;IACE,sBAAA;IACA,mBAAA;IACA,kBAAA;EAFF;EAKA;IACE,mBAAA;EAHF;EAMA;IACE,YAAA;IACA,WAAA;EAJF;EAMA;IACE,kBAAA;IACA,gBAAA;EAJF;EAOA;IACE,cAAA;IACA,kBAAA;EALF;AACF","sourcesContent":[".invoiceContent {\n  padding: 20px;\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n  border-bottom: 2px solid #e5e5e5;\n  padding-bottom: 20px;\n  margin-bottom: 20px;\n}\n// ::after {\n//   margin-left: 109px;\n// }\n.companyDetails {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n}\n\n.companyLogo {\n  height: 100px;\n  width: 90px;\n  justify-content: center;\n}\n\n.invoiceInfo {\n  text-align: end;\n}\n\n.invoiceTable {\n  margin-top: 20px;\n}\n\n.paymentTerms {\n  margin-top: 20px;\n  font-size: 0.9em;\n}\n\n.totals {\n  margin-top: 20px;\n  font-size: 1.1em;\n  text-align: right;\n}\n\n.footerNotes {\n  margin-top: 20px;\n  font-style: italic;\n}\n\n.footer {\n  text-align: center;\n  padding: 10px;\n}\n\n@media (max-width: 576px) {\n  .invoiceContent {\n    padding: 10px;\n  }\n\n  .header {\n    flex-direction: column;\n    align-items: center;\n    text-align: center;\n  }\n\n  .companyDetails {\n    align-items: center;\n  }\n\n  .companyLogo {\n    height: 80px;\n    width: 70px;\n  }\n  .invoiceInfo {\n    text-align: center;\n    margin-top: 10px;\n  }\n\n  .totals {\n    font-size: 1em;\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"invoiceContent": `invoice_invoiceContent__-rtbD`,
	"header": `invoice_header__BowF4`,
	"companyDetails": `invoice_companyDetails__zAaNo`,
	"companyLogo": `invoice_companyLogo__0Rlxb`,
	"invoiceInfo": `invoice_invoiceInfo__D6G+G`,
	"invoiceTable": `invoice_invoiceTable__buiH9`,
	"paymentTerms": `invoice_paymentTerms__3MftP`,
	"totals": `invoice_totals__DMVrc`,
	"footerNotes": `invoice_footerNotes__9jtuw`,
	"footer": `invoice_footer__d55xs`
};
export default ___CSS_LOADER_EXPORT___;
