import React from "react";
import "../../styles/pages/Description.scss";
import about from "../../assets/images/pillar1.jpg"; // Import SCSS file for additional styles

const VrddhimDescription = () => {
  return (
    <section className="description-section">
      <div className="container">
        <div className="serene-container">
          <h2 className="elegant-heading text-center">The Vrddhim Experience</h2>
          <div className="row">
            <div className="col-lg-12">
              <p className="graceful-paragraph">
                At Vrddhim, we believe in creating an immersive learning experience that goes beyond traditional methods. Our approach integrates
                real-world scenarios and interactive sessions, making your learning journey both engaging and effective. <br></br>Our team of erudite
                scholars and passionate trainers, led by our internationally certified Communication Coach, is committed to helping you achieve
                mastery in English communication with great comfort.
              </p>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="container story-container">
        <h2 className="elegant-heading text-center">“Vrddhim” Training Academy for English Excellence</h2>

        <div className="row align-items-center">
          <div className="col-md-8 col-lg-6 mb-4 mb-lg-0">
            <div className=" mt-2">
              <p className="eloquent-paragraph">
                Vrddhim works on three main pillars - Clarity, Conciseness, and Consistency. The interplay of these three elements is the essence of
                conveying information accurately and efficiently in our business language.
              </p>
              <p className="eloquent-paragraph">
                The balance of these three key pillars is vital for a learner to accomplish their fluency in English. Which in return provides a
                tremendous amount of self-confidence &amp; motivation to achieve their highest.
              </p>
              <p className="eloquent-emphasis">
                <b>This is what Vrddhim aims to strive for in YOU.</b>
              </p>
            </div>
          </div>
          <div className="col-md-8 col-lg-6">
            <img src={about} alt="About Vrddhim" className="eloquent-image" />
          </div>
        </div>
      </div>
      <div className="container mt-4 story-container">
        <h2 className="elegant-heading text-center">Who Are We?</h2>
        <div className="row mt-2">
          <div className="col-lg-12">
            <p className="lead text-justify" style={{ color: "#595959" }}>
              We are Scholars in this domain. Years of traveling across the global and the experience acquired in this field has helped us understand
              the needs &amp; necessity of this powerful business language – English.<br></br>
              We take pride in conceptualizing &amp; developing premium courses suitable for learners across different levels. This is made possible
              by our team of handpicked experienced passionate trainers who provide their expertise to curate the programs at Vrddhim.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VrddhimDescription;
