// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-batches .ant-list-item {
  font-size: 14px;
}
.admin-batches .batch-edit-form {
  font-size: 14px;
}
.admin-batches .ant-form-item-label > label {
  font-size: 13px;
}
.admin-batches .ant-input {
  font-size: 13px;
}
.admin-batches .ant-btn {
  font-size: 13px;
}
.admin-batches h3 {
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/styles/pages/adminBatches.scss"],"names":[],"mappings":"AACE;EACE,eAAA;AAAJ;AAGE;EACE,eAAA;AADJ;AAIE;EACE,eAAA;AAFJ;AAKE;EACE,eAAA;AAHJ;AAME;EACE,eAAA;AAJJ;AAOE;EACE,eAAA;AALJ","sourcesContent":[".admin-batches {\n  .ant-list-item {\n    font-size: 14px; // Adjust this value as needed\n  }\n\n  .batch-edit-form {\n    font-size: 14px; // Adjust this value as needed\n  }\n\n  .ant-form-item-label > label {\n    font-size: 13px; // Adjust this value as needed\n  }\n\n  .ant-input {\n    font-size: 13px; // Adjust this value as needed\n  }\n\n  .ant-btn {\n    font-size: 13px; // Adjust this value as needed\n  }\n\n  h3 {\n    font-size: 16px; // Adjust this value as needed\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
