import React, { useState, useEffect } from "react";
import { Button, Form, FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPaperPlane, faComments } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import "../../src/styles/pages/ChatBox.scss";

const user_id = "user_1"; // This user's ID for example purposes
const room_id = "room_101";

const ChatBox = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");

  const toggleChatBox = () => setIsOpen(!isOpen);

  const fetchMessages = async () => {
    try {
      const response = await axios.get(`/api/chats/${room_id}`);
      setMessages(response.data);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  useEffect(() => {
    if (isOpen) fetchMessages();
  }, [isOpen]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (currentMessage.trim()) {
      const newMessage = {
        chat_id: `chat_${new Date().getTime()}`,
        room_id,
        user_id,
        message: currentMessage,
        timestamp: new Date().toISOString(),
      };

      try {
        await axios.post("/api/chats", newMessage);
        setMessages([...messages, newMessage]);
        setCurrentMessage("");
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  return (
    <div className={`chatbox-container ${isOpen ? "open" : ""}`}>
      {!isOpen && (
        <Button className="chatbox-toggle-button" onClick={toggleChatBox}>
          <FontAwesomeIcon icon={faComments} />
        </Button>
      )}
      {isOpen && (
        <div className="chatbox">
          <div className="chatbox-header">
            <span>Chat</span>
            <Button className="chatbox-close-button" onClick={toggleChatBox}>
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </div>
          <div className="chatbox-messages">
            {messages.length === 0 && <div className="chatbox-welcome-message">How can I help you today?</div>}
            {messages.map((msg, index) => (
              <div key={index} className={`chatbox-message ${msg.user_id === user_id ? "sent" : "received"}`}>
                <span className="chatbox-message-content">{msg.message}</span>
                <span className="chatbox-timestamp">{new Date(msg.timestamp).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}</span>
              </div>
            ))}
          </div>
          <Form onSubmit={handleSendMessage} className="chatbox-form">
            <FormControl
              type="text"
              placeholder="Type your message..."
              value={currentMessage}
              onChange={(e) => setCurrentMessage(e.target.value)}
              className="chatbox-input"
            />
            <Button type="submit" className="chatbox-send-button">
              <FontAwesomeIcon icon={faPaperPlane} />
            </Button>
          </Form>
        </div>
      )}
    </div>
  );
};

export default ChatBox;
