// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-container {
  margin-top: 50px;
}

.notifications-title {
  color: #000;
  margin-bottom: 20px;
}

.notification-item {
  background-color: #fff;
  border: 1px solid #ddd;
  color: #000; /* Black text */
  margin-bottom: 10px;
}

.notification-item:hover {
  background-color: #f5f5f5;
}`, "",{"version":3,"sources":["webpack://./src/styles/pages/admin-notification.scss"],"names":[],"mappings":"AACA;EACE,gBAAA;AAAF;;AAGA;EACE,WAAA;EACA,mBAAA;AAAF;;AAGA;EACE,sBAAA;EACA,sBAAA;EACA,WAAA,EAAA,eAAA;EACA,mBAAA;AAAF;;AAGA;EACE,yBAAA;AAAF","sourcesContent":["\n.notification-container {\n  margin-top: 50px;\n}\n\n.notifications-title {\n  color: #000; \n  margin-bottom: 20px;\n}\n\n.notification-item {\n  background-color: #fff; \n  border: 1px solid #ddd; \n  color: #000; /* Black text */\n  margin-bottom: 10px;\n}\n\n.notification-item:hover {\n  background-color: #f5f5f5; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
