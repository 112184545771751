import React, { useState, useEffect, useMemo, Suspense, lazy, useContext } from "react";
import { useTheme } from "@mui/material/styles";
import * as echarts from "echarts/core";
import { LineChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { TitleComponent, TooltipComponent, GridComponent, LegendComponent } from "echarts/components";
import axios from "axios";
import "../../styles/pages/overview-activitychart.scss";
import { HttpServiceContext } from "../../config/httpContext";

const ReactEchart = lazy(() => import("./overview-ReactEchart"));

echarts.use([LineChart, TitleComponent, TooltipComponent, GridComponent, LegendComponent, CanvasRenderer]);

const ActivityChart = React.memo(({ ...rest }) => {
  const theme = useTheme();
  const [registrationData, setRegistrationData] = useState([]);
  const httpService = useContext(HttpServiceContext);

  useEffect(() => {
    fetchRegistrationData();
  }, []);

  const fetchRegistrationData = async () => {
    let response = await httpService.getRegisteredUsers();
    if (response) {
      setRegistrationData(response);
    }
  };

  const chartData = useMemo(() => {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const registrationsByDay = days.map((day) => ({
      day,
      count: 0,
    }));

    registrationData.forEach((user) => {
      const registrationDate = new Date(user.registration_date);
      const dayOfWeek = days[registrationDate.getDay()];
      const dayData = registrationsByDay.find((data) => data.day === dayOfWeek);
      if (dayData) {
        dayData.count += 1;
      }
    });

    return registrationsByDay;
  }, [registrationData]);

  const option = useMemo(
    () => ({
      backgroundColor: theme.palette.background.paper,
      title: {
        text: "Weekly User Registration Overview",
        left: "center",
        top: "top",
        textStyle: {
          color: theme.palette.text.primary,
          fontSize: 16,
          fontWeight: "bold",
        },
      },
      grid: {
        top: 60,
        bottom: 50,
        left: 50,
        right: 30,
        containLabel: true,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "line",
          lineStyle: {
            color: theme.palette.primary.main,
          },
        },
        backgroundColor: theme.palette.background.default,
        borderColor: theme.palette.divider,
        borderWidth: 1,
        padding: [10, 15],
        textStyle: {
          color: theme.palette.text.primary,
        },
      },
      xAxis: {
        type: "category",
        data: chartData.map((data) => data.day),
        axisTick: { show: false },
        axisLine: {
          lineStyle: {
            color: theme.palette.text.secondary,
            width: 2,
          },
        },
        axisLabel: {
          color: theme.palette.text.primary,
          fontSize: 14,
          fontWeight: "bold",
        },
        splitLine: {
          show: false,
        },
      },
      yAxis: {
        type: "value",
        axisLine: {
          lineStyle: {
            color: theme.palette.text.secondary,
            width: 2,
          },
        },
        axisLabel: {
          color: theme.palette.text.primary,
          fontSize: 14,
          fontWeight: "bold",
        },
        splitLine: {
          show: true,
          lineStyle: {
            type: "dashed",
            color: theme.palette.divider,
            width: 1,
          },
        },
      },
      series: [
        {
          name: "Users Registered",
          data: chartData.map((data) => data.count),
          type: "line",
          smooth: true,
          symbol: "circle",
          symbolSize: 8,
          lineStyle: {
            width: 3,
            color: "#000000",
            shadowColor: "rgba(0, 0, 0, 0.3)",
            shadowBlur: 6,
            shadowOffsetX: 2,
            shadowOffsetY: 2,
          },
          itemStyle: {
            color: "#000000",
            borderWidth: 2,
            borderColor: theme.palette.background.paper,
          },
          emphasis: {
            focus: "series",
          },
        },
      ],
    }),
    [theme, chartData]
  );

  return (
    <Suspense fallback={<div>Loading chart...</div>}>
      <ReactEchart echarts={echarts} option={option} {...rest} />
    </Suspense>
  );
});

export default ActivityChart;
