// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open Sans:wght@400;700&family=Montserrat:wght@500;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app {
  display: flex;
  height: 100vh;
  overflow: hidden;
  transition: margin-left 0.3s ease;
}
:root {
  --primary-color: #6a11cb;
  --secondary-color: #2575fc;
  --background-color: #f0f2f5;
  --card-background: #ffffff;
  --text-color: #333333;
  --subtext-color: #555555;
  --border-radius: 15px;
  --transition-speed: 0.3s;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
}

p {
  font-weight: 400;
  line-height: 1.6;
}

.main-content {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  overflow-y: auto; 
  overflow-x: hidden;
}

.webUI {
  margin-top: 100px;
  height: calc(100vh - 60px);
}

*::-webkit-scrollbar {
  width: 0;
  height: 0;
}

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AACA;EACE,aAAa;EACb,aAAa;EACb,gBAAgB;EAChB,iCAAiC;AACnC;AACA;EACE,wBAAwB;EACxB,0BAA0B;EAC1B,2BAA2B;EAC3B,0BAA0B;EAC1B,qBAAqB;EACrB,wBAAwB;EACxB,qBAAqB;EACrB,wBAAwB;AAC1B;;AAEA;EACE,oCAAoC;EACpC,eAAe;EACf,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,SAAO;EACP,sBAAsB;EACtB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,0BAA0B;AAC5B;;AAEA;EACE,QAAQ;EACR,SAAS;AACX;;AAEA;EACE,wBAAwB;EACxB,qBAAqB;AACvB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Open Sans:wght@400;700&family=Montserrat:wght@500;700&display=swap\");\n.app {\n  display: flex;\n  height: 100vh;\n  overflow: hidden;\n  transition: margin-left 0.3s ease;\n}\n:root {\n  --primary-color: #6a11cb;\n  --secondary-color: #2575fc;\n  --background-color: #f0f2f5;\n  --card-background: #ffffff;\n  --text-color: #333333;\n  --subtext-color: #555555;\n  --border-radius: 15px;\n  --transition-speed: 0.3s;\n}\n\nbody {\n  font-family: \"Open Sans\", sans-serif;\n  font-size: 16px;\n  line-height: 1.5;\n  color: #333;\n}\n\np {\n  font-weight: 400;\n  line-height: 1.6;\n}\n\n.main-content {\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  overflow-y: auto; \n  overflow-x: hidden;\n}\n\n.webUI {\n  margin-top: 100px;\n  height: calc(100vh - 60px);\n}\n\n*::-webkit-scrollbar {\n  width: 0;\n  height: 0;\n}\n\n* {\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
