import React, { useContext, useEffect, useState } from "react";
import { FaCalendarAlt, FaUsers, FaClock, FaSpinner } from "react-icons/fa";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import "../../styles/pages/EnrollmentPage.scss";
import Navbar from "../navbar";
import Invoice from "../payments-analytics/Invoice";

import Footer from "../home/vrddhim-footer";
import PaymentSuccess from "./PaymentSuccess";
import { HttpServiceContext } from "../../config/httpContext";

const EnrollmentPage = () => {
  window.scrollTo(0, 0);

  const location = useLocation();
  const navigate = useNavigate();
  const courseDetails = location.state?.courseDetails || {};
  const [batches, setBatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [razorpayLoaded, setRazorpayLoaded] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  // const [paymentId, setPaymentId] = useState(null);
  const [razerpaymentId, setRazerPaymentId] = useState(null);
  // const [showInvoice, setShowInvoice] = useState(false);

  const httpService = useContext(HttpServiceContext);

  useEffect(() => {
    const loadRazorpayScript = () => {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.onload = () => resolve(true);
        script.onerror = () => reject(new Error("Failed to load Razorpay script"));
        document.body.appendChild(script);
      });
    };

    loadRazorpayScript()
      .then(() => setRazorpayLoaded(true))
      .catch((error) => {
        console.error(error);
        setError("Failed to load Razorpay. Please try again later.");
      });
  }, []);

  useEffect(() => {
    if (courseDetails.course_id) {
      const fetchBatches = async () => {
        try {
          const response = await axios.get(`https://api.vrddhim.org/batches-details?course_id=${courseDetails.course_id}`);
          setBatches(response.data.batches);
          setLoading(false);
        } catch (err) {
          console.error("Error fetching data:", err);
          setError("There was a problem fetching the batch data. Please try again later.");
          setLoading(false);
        }
      };

      fetchBatches();
    }
  }, [courseDetails.course_id]);

  useEffect(() => {
    let timer;
    if (paymentSuccess) {
      timer = setTimeout(async () => {
        let payload = {
          userid: httpService.loginedUserId,
          course_id: selectedBatch.course_id,
          batch_id: selectedBatch.batch_id,
          razorpayment_id: razerpaymentId,
          status: "complete",
        };
        let response = await httpService.updatePaymentStatus(JSON.stringify(payload));
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [paymentSuccess]);

  const handleProceedClick = (batch) => {
    setSelectedBatch(batch);
    setShowConfirmation(true);
  };

  const handleConfirmPayment = async () => {
    if (selectedBatch) {
      setShowConfirmation(false);
      setLoading(true);
      try {
        const response = await axios.post(
          `https://api.vrddhim.org/courses/${selectedBatch.course_id}/users/${httpService.loginedUserId}/batch/${selectedBatch.batch_id}/create_order`,
          {
            amount: selectedBatch.price,
          }
        );

        const { order_id, amount } = response.data;

        if (window.Razorpay && razorpayLoaded) {
          const options = {
            key: "rzp_test_bXLEzTTyKSEPdk",
            amount: amount,
            currency: "INR",
            name: "Vrddhim",
            description: "Course Purchase",
            order_id: order_id,
            handler: function (response) {
              setPaymentSuccess(true);
              setRazerPaymentId(response.razorpay_payment_id);
              // setShowInvoice(true); // Show the Invoice modal after payment success
            },
            prefill: {
              name: "Your Name",
              email: "your-email@example.com",
              contact: "9999999999",
            },
            notes: {
              address: "Vrddhim Address",
            },
            theme: {
              color: "#2d572c",
            },
          };

          const rzp1 = new window.Razorpay(options);
          rzp1.open();
        } else {
          console.error("Razorpay library not loaded");
          alert("Razorpay library not loaded. Please try again later.");
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCancelPayment = () => {
    setShowConfirmation(false);
  };

  const handleNotifyClick = () => {
    alert("Notification sent!");
  };

  const handleJoinClassClick = () => {
    navigate("/liveclass");
  };

  if (loading) {
    return (
      <div className="loading-container">
        <FaSpinner className="loading-spinner" />
        <p>Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-container">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <>
      <Navbar />
      <div className="m-3">
        <section className="container cards-container">
          <div>
            <button className="btn bttn-primary" onClick={() => navigate(`/course-detail/${courseDetails.course_id}`, { state: { courseDetails } })}>
              {/* <i className="fas fa-angle-left arrow-icon mx-2"></i> */}
              Back
            </button>
          </div>
          <h2 className="text-center fs-4 p-3 elegant-heading">Plese select your preferred batch timing from the available options</h2>
          <div className="enrollment-page">
            <div className="batch-list row justify-content-center">
              {batches.length > 0 ? (
                batches.map((batch) => (
                  <div className="batch-card bdr-primary col-5" key={batch.batch_id}>
                    <div className="batch-card-header">
                      <h5 className="batch-title">{batch.batch_name}</h5>
                      <hr className="divider" />
                    </div>
                    <div className="batch-card-body">
                      <div className="p-2">
                        <p className="batch-course-name">{batch.course_name}</p>
                        <p className="batch-date">
                          <FaCalendarAlt className="icon" />
                          {batch.batch_date}
                        </p>
                        <p className="batch-time">
                          <FaClock className="icon" />
                          {batch.start_time} - {batch.end_time}
                        </p>
                        <p className="batch-enrollment mb-2">
                          <FaUsers className="icon" />
                          {batch.enrolled}/{batch.max_students} students enrolled
                        </p>
                      </div>
                      <button className="payment-button" onClick={() => handleProceedClick(batch)}>
                        Proceed
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <p>No batches available for this course.</p>
              )}
            </div>
          </div>
          {showConfirmation && selectedBatch && (
            <div className="confirmation-popup">
              <div className="confirmation-content">
                <h2 className="p-3 elegant-heading text-center">Confirm Payment</h2>
                <div className="p-2">
                  <p>
                    <strong>Batch Name:</strong> {selectedBatch.batch_name}
                  </p>
                  <p>
                    <strong>Course Name:</strong> {selectedBatch.course_name}
                  </p>
                  <p>
                    <strong>Start Time:</strong> {selectedBatch.start_time}
                  </p>
                  <p>
                    <strong>End Time:</strong> {selectedBatch.end_time}
                  </p>
                  <p>
                    <strong>Price:</strong> <i class="fa fa-inr fa-xs"></i>&nbsp;
                    {selectedBatch.price}
                  </p>
                </div>
                <div className="confirmation-buttons">
                  <button onClick={handleConfirmPayment}>Confirm</button>
                  <button onClick={handleCancelPayment}>Cancel</button>
                </div>
                <p className="fw-bold p-2 fs-7">Important: No refunds or rescheduling permitted after payment is completed</p>
              </div>
            </div>
          )}
          {paymentSuccess && (
            <PaymentSuccess razorpayment_id={razerpaymentId} enrolledBatch={selectedBatch} courseDetails={courseDetails}></PaymentSuccess>
          )}
        </section>
      </div>
      <Footer />
    </>
  );
};

export default EnrollmentPage;
