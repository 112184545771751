import React, { useContext, useEffect, useState } from "react";
import { HttpServiceContext } from "../config/httpContext";
import Navbar from "./navbar";
import { Modal, Button } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FaEnvelope, FaPhone } from "react-icons/fa";
import "./Profile.scss";

const Profile = () => {
  const [profile, setProfile] = useState(null);
  const [error, setError] = useState(null);
  const [initials, setInitials] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isPasswordChange, setIsPasswordChange] = useState(false);
  const [editProfile, setEditProfile] = useState({
    username: "",
    email: "",
    phone_number: "",
  });
  const [passwordForm, setPasswordForm] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPasswordSuccess, setShowPasswordSuccess] = useState(false);
  const httpService = useContext(HttpServiceContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (httpService.loginedUserId !== "") fetchProfile();
    else navigate("/login");
  }, []);

  async function fetchProfile() {
    let response = await httpService.getProfile();
    if (!response.data) {
      setProfile(response);
      setInitials(getInitials(response.username || response.mentor_name));
    } else {
      navigate("/login");
      setError("Failed to fetch profile");
    }
  }

  function getInitials(name) {
    if (!name) return "";
    const nameParts = name.split(" ");
    return nameParts[1] ? `${nameParts[0][0]}${nameParts[1][0]}` : `${nameParts[0][0]}`;
  }

  const handleLogout = async () => {
    await httpService.logoutUser();
    httpService.setLoginedType("");
    httpService.setLoginedUserId("");
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    navigate("/login");
    window.location.reload();
  };

  const handleEditProfile = () => {
    setEditProfile({
      username: profile.username,
      email: profile.email,
      phone_number: profile.phone_number,
    });
    setIsPasswordChange(false);
    setShowModal(true);
  };

  const handleSaveProfile = async () => {
    try {
      const response = await httpService.updateProfile(editProfile);
      if (response) {
        setProfile({ ...profile, ...editProfile });
        setShowModal(false);
      }
    } catch (error) {
      console.error("Failed to save profile:", error);
    }
  };

  const handleChangePassword = async () => {
    const { old_password, new_password, confirm_password } = passwordForm;

    if (new_password !== confirm_password) {
      console.error("Passwords do not match.");
      return;
    }

    try {
      const response = await httpService.changePassword(passwordForm);
      if (response.message) {
        setShowPasswordSuccess(true);
        setShowModal(false);
        console.log("Password changed successfully");
      }
    } catch (error) {
      console.error("Failed to change password:", error);
    }
  };

  const handleModalClose = () => setShowModal(false);
  const handleSuccessModalClose = () => setShowPasswordSuccess(false);

  if (error) return <div className="error-message">{error}</div>;

  return (
    <div className="profile-page">
      <Navbar />
      <div className="container mt-5">
        <h1 className="text-center mb-5">Profile</h1>
        {profile ? (
          <div className="profile-card mx-auto">
            <div className="profile-user-avatar">
              <span className="initials">{initials}</span>
            </div>
            <div className="profile-details">
              <h2 className="profile-name">{profile.username || profile.mentor_name}</h2>
              <p className="profile-info">
                <FaEnvelope className="info-icon" /> <strong>Email:</strong> {profile.email || profile.mentor_email}
              </p>
              <p className="profile-info">
                <FaPhone className="info-icon" /> <strong>Phone:</strong> {profile.phone_number}
              </p>
              <div className="profile-actions">
                <button className="btn bttn-primary " onClick={handleEditProfile}>
                  Edit Profile
                </button>
                <button className="btn bttn-primary " onClick={handleLogout}>
                  Logout
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="loading-state text-center">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            <p>Loading profile...</p>
          </div>
        )}

        {/* Display Login Option if User is not Logged In */}
        {!profile && (
          <div className="text-center mt-5">
            <h2>You are not logged in</h2>
            <Link to="/login" className="btn btn-primary">
              Login
            </Link>
            <Link to="/register" className="btn btn-secondary ms-2">
              Register
            </Link>
          </div>
        )}
      </div>

      {/* Edit Profile / Change Password Modal */}
      <Modal show={showModal} onHide={handleModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{isPasswordChange ? "Change Password" : "Edit Profile"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!isPasswordChange ? (
            <form>
              <div className="mb-3">
                <label htmlFor="username" className="form-label">
                  Username
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  value={editProfile.username}
                  onChange={(e) => setEditProfile({ ...editProfile, username: e.target.value })}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  value={editProfile.email}
                  onChange={(e) => setEditProfile({ ...editProfile, email: e.target.value })}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="phone" className="form-label">
                  Phone Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  value={editProfile.phone_number}
                  onChange={(e) => setEditProfile({ ...editProfile, phone_number: e.target.value })}
                />
              </div>
              <div className="text-end">
                <Button variant="link" onClick={() => setIsPasswordChange(true)}>
                  Change Password
                </Button>
              </div>
            </form>
          ) : (
            <form>
              <div className="mb-3 position-relative d-block">
                <label htmlFor="old_password" className="form-label">
                  Old Password
                </label>
                <input
                  type={showOldPassword ? "text" : "password"}
                  className="form-control"
                  id="old_password"
                  value={passwordForm.old_password}
                  onChange={(e) => setPasswordForm({ ...passwordForm, old_password: e.target.value })}
                />
                <span
                  onClick={() => setShowOldPassword(!showOldPassword)}
                  style={{ cursor: "pointer", position: "absolute", right: "30px", top: "70%", transform: "translateY(-50%)" }}
                >
                  {showOldPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
              <div className="mb-3 position-relative d-block">
                <label htmlFor="new_password" className="form-label">
                  New Password
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  id="new_password"
                  value={passwordForm.new_password}
                  onChange={(e) => setPasswordForm({ ...passwordForm, new_password: e.target.value })}
                />
                <span
                  onClick={() => setShowPassword(!showPassword)}
                  style={{ cursor: "pointer", position: "absolute", right: "30px", top: "70%", transform: "translateY(-50%)" }}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
              <div className="mb-3 position-relative d-block">
                <label htmlFor="confirm_password" className="form-label">
                  Confirm New Password
                </label>
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  className="form-control"
                  id="confirm_password"
                  value={passwordForm.confirm_password}
                  onChange={(e) => setPasswordForm({ ...passwordForm, confirm_password: e.target.value })}
                />
                <span
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  style={{ cursor: "pointer", position: "absolute", right: "30px", top: "70%", transform: "translateY(-50%)" }}
                >
                  {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
            </form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          <Button variant="primary" onClick={isPasswordChange ? handleChangePassword : handleSaveProfile}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showPasswordSuccess} onHide={handleSuccessModalClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="background-primary">
          <div className="terms-modal text-center">
            <div>Password Updated Successfully</div>
            <button className="btn bttn-primary">Ok</button>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};

export default Profile;
