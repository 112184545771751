import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const Tandc = ({ handleCheckboxChange, isChecked }) => {
  return (
    <div className="container mt-4">
      <h4 className="text-center text-primary fc-grey">Terms and Conditions</h4>
      <ul className="list-group mt-3">
        <li className="list-group-item">
          <strong>1. Account Responsibility:</strong> By registering, you agree to provide accurate information and are responsible for maintaining
          the confidentiality of your account.
        </li>
        <li className="list-group-item">
          <strong>2. Usage of Services:</strong> You must not misuse the platform, attempt unauthorized access, or violate any legal guidelines. Any
          violation may lead to account suspension.
        </li>
        <li className="list-group-item">
          <strong>3. Payment Terms:</strong> All purchases and subscriptions are non-refundable. Ensure your payment details are accurate for future
          transactions.
        </li>
        <li className="list-group-item">
          <strong>4. Data Privacy:</strong> We collect and use your data in accordance with our Privacy Policy. You agree to the collection and use of
          this information.
        </li>
        <li className="list-group-item">
          <strong>5. Modification of Terms:</strong> We reserve the right to update these terms at any time. Continued use of the platform implies
          acceptance of the updated terms.
        </li>
      </ul>
      <div className="input-group mt-3">
        <div className="form-check display-flex align-items-center">
          <input type="checkbox" className="form-check-input" checked={isChecked} onChange={handleCheckboxChange} id="formCheck" />
          <label className="form-check-label text-secondary pt-1">
            <small className="mx-1">
              Agree to
              <span> Terms and Conditions</span>
            </small>
          </label>
        </div>
      </div>
    </div>
  );
};

export default Tandc;
