import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const DataGridFooter = () => {
  return (
    <Box sx={{ p: 2, display: "flex", justifyContent: "center" }}>
      <Typography variant="body2" color="textSecondary">
        Footer Content Here
      </Typography>
    </Box>
  );
};

export default DataGridFooter;
