// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mentors-overview-card_mentorCard__cLsjO {
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin: 10px;
  margin-left: 2px;
  transition: transform 0.2s ease-in-out;
  width: 100%;
  max-width: 300px;
}

.mentors-overview-card_mentorCard__cLsjO:hover {
  transform: scale(1.02);
}

.mentors-overview-card_mentorAvatar__hw44J {
  width: 64px;
  height: 64px;
  border: 2px solid #3f51b5;
  border-radius: 50%;
}

.mentors-overview-card_mentorInfo__Z7dLh {
  flex-grow: 1;
}

.mentors-overview-card_mentorName__ApWZR {
  color: #333;
  font-weight: 600;
}

.mentors-overview-card_mentorRole__5pIad {
  color: #888;
  font-size: 14px;
}

.mentors-overview-card_progressWrapper__8iemS {
  margin-top: 8px;
}

.mentors-overview-card_progressBar__A1nZO {
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background-color: #e0e0e0;
}

.mentors-overview-card_mentorTasks__4WVsK {
  color: #555;
}

.mentors-overview-card_mentorReviews__lrv8f {
  color: #ff9800;
}

.mentors-overview-card_mentors-carousel__fOmgS .mentors-overview-card_slick-slide__MXSYP {
  display: flex;
  justify-content: center;
}

.mentors-overview-card_mentor-card-wrapper__JZdJx {
  display: flex;
  justify-content: center;
  margin: 0 10px;
}

.mentors-overview-card_mentorCard__cLsjO {
  width: 100%;
  max-width: 300px;
}`, "",{"version":3,"sources":["webpack://./src/styles/pages/mentors-overview-card.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,mBAAA;EACA,wCAAA;EACA,aAAA;EACA,YAAA;EACA,gBAAA;EACA,sCAAA;EACA,WAAA;EACA,gBAAA;AACF;;AAEA;EACE,sBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,WAAA;EACA,gBAAA;AACF;;AAEA;EACE,WAAA;EACA,eAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,WAAA;EACA,WAAA;EACA,kBAAA;EACA,yBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,cAAA;AACF;;AACA;EACE,aAAA;EACA,uBAAA;AAEF;;AACA;EACE,aAAA;EACA,uBAAA;EACA,cAAA;AAEF;;AACA;EACE,WAAA;EACA,gBAAA;AAEF","sourcesContent":[".mentorCard {\n  background-color: #f9f9f9;\n  border-radius: 12px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  padding: 16px;\n  margin: 10px;\n  margin-left: 2px;\n  transition: transform 0.2s ease-in-out;\n  width: 100%;\n  max-width: 300px;\n}\n\n.mentorCard:hover {\n  transform: scale(1.02);\n}\n\n.mentorAvatar {\n  width: 64px;\n  height: 64px;\n  border: 2px solid #3f51b5;\n  border-radius: 50%;\n}\n\n.mentorInfo {\n  flex-grow: 1;\n}\n\n.mentorName {\n  color: #333;\n  font-weight: 600;\n}\n\n.mentorRole {\n  color: #888;\n  font-size: 14px;\n}\n\n.progressWrapper {\n  margin-top: 8px;\n}\n\n.progressBar {\n  width: 100%;\n  height: 8px;\n  border-radius: 4px;\n  background-color: #e0e0e0;\n}\n\n.mentorTasks {\n  color: #555;\n}\n\n.mentorReviews {\n  color: #ff9800;\n}\n.mentors-carousel .slick-slide {\n  display: flex;\n  justify-content: center;\n}\n\n.mentor-card-wrapper {\n  display: flex;\n  justify-content: center;\n  margin: 0 10px;\n}\n\n.mentorCard {\n  width: 100%;\n  max-width: 300px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mentorCard": `mentors-overview-card_mentorCard__cLsjO`,
	"mentorAvatar": `mentors-overview-card_mentorAvatar__hw44J`,
	"mentorInfo": `mentors-overview-card_mentorInfo__Z7dLh`,
	"mentorName": `mentors-overview-card_mentorName__ApWZR`,
	"mentorRole": `mentors-overview-card_mentorRole__5pIad`,
	"progressWrapper": `mentors-overview-card_progressWrapper__8iemS`,
	"progressBar": `mentors-overview-card_progressBar__A1nZO`,
	"mentorTasks": `mentors-overview-card_mentorTasks__4WVsK`,
	"mentorReviews": `mentors-overview-card_mentorReviews__lrv8f`,
	"mentors-carousel": `mentors-overview-card_mentors-carousel__fOmgS`,
	"slick-slide": `mentors-overview-card_slick-slide__MXSYP`,
	"mentor-card-wrapper": `mentors-overview-card_mentor-card-wrapper__JZdJx`
};
export default ___CSS_LOADER_EXPORT___;
