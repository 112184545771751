import React from "react";

import ActivityChart from "../charts/overview-activitychart";
import Mentors from "./mentors-overview";
import WeekCalendar from "./calendar";
import Carousel from "./course-overview-carousel";
import { subjects } from "../data/subjects";
import TaskOverview from "./course-overview";
import SubjectsToday from "./todays-courses";
import "../../styles/pages/admin-overview.scss";

function Dashboard() {
  return (
    <div className="dashboard-container">
      <div className="dashboard-left">
        <div className="overview-header">
          <h2 className="eloquent-title">Overview</h2>
        </div>
        <h3>Activity</h3>
        <div className="chart-container">
          <ActivityChart />
        </div>
        <div className="mentors-container">
          <h3 className="mentors-heading">Mentors</h3>
          <Mentors />
        </div>
        <div className="cards-container">
          <h3 className="upcoming-tasks-heading">Upcoming Tasks</h3>
          <Carousel subjects={subjects} />
        </div>
        <div className="task-overview-container">
          <h3 className="task-overview-heading">Task Overview</h3>
          <TaskOverview />
        </div>
      </div>
      <div className="dashboard-right">
        <div className="schedule-container">
          <WeekCalendar />
        </div>
        <div className="subjects-today-container">
          <SubjectsToday />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
