import React from "react";
import "../../styles/pages/coverImage.scss";
import coverimage from "../../assets/images/cover-image.png";

const AboutCover = () => {
  return (
    <section>
      <div className="bg-holder" style={{ backgroundImage: `url(${coverimage})` }}></div>
    </section>
  );
};

export default AboutCover;
