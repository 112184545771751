import React from "react";
import { IconButton, Badge, TextField } from "@mui/material";
import { Search, Notifications, AccountCircle, Language } from "@mui/icons-material";
import styles from "../../styles/pages/admin-navigationbar.module.scss";

function Header() {
  return (
    <nav className={`navbar navbar-expand-lg navbar-light bg-light ${styles.header}`}>
      <div className="container-fluid">
        <a className={`navbar-brand ${styles.navbarBrand}`} href="#">
          <img src={require("../../assets/images/vrddhim-logo.png")} alt="Header Logo" className={styles.logo} />
        </a>
        <div className={`d-flex align-items-center ${styles.searchContainer}`}>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search..."
            className={styles.searchField}
            slotProps={{
              input: {
                startAdornment: (
                  <IconButton>
                    <Search />
                  </IconButton>
                ),
              },
            }}
          />
        </div>
        <div className={`navbar-nav ms-auto ${styles.navbarNav}`}>
          <IconButton className={styles.navIcon} href="/notifications">
            <Badge badgeContent={4} color="error">
              <Notifications />
            </Badge>
          </IconButton>
          <IconButton className={styles.navIcon} href="/admin-profile">
            <AccountCircle />
          </IconButton>
          <IconButton className={styles.navIcon} href="#">
            <Language />
          </IconButton>
        </div>
      </div>
    </nav>
  );
}

export default Header;
