import React from "react";
import Slider from "react-slick";
import "../../styles/pages/subjects_carousel.module.scss";

import SubjectCard from "../admin-overview/course-overview-card";
const Carousel = ({ subjects }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="carouselContainer">
      <Slider {...settings}>
        {subjects.map((subject, index) => (
          <div key={index} className="cardContainer">
            <SubjectCard
              subject={subject.subject}
              image={subject.image}
              department={subject.department}
              progress={subject.progress}
              daysLeft={subject.daysLeft}
              profiles={subject.profiles}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
