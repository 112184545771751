import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import "./ChatComponent.scss";

const ChatBox = () => {
  const whatsappNumber = "+916363008186";

  const openWhatsApp = () => {
    const message = "Hello, I need assistance!";
    const url = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, "_blank");
  };

  return (
    <div className="chatbox-home-container">
      <Button className="chatbox-toggle-button" onClick={openWhatsApp}>
        <FontAwesomeIcon icon={faWhatsapp} />
      </Button>
    </div>
  );
};

export default ChatBox;
