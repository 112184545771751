import React, { useEffect, useState } from "react";
import "../../styles/pages/corestrength.scss";
import TreeImage from "../../assets/images/tree.png";
import CenterImage from "../../assets/images/center-image.png"; // Add your center image here

const CoreStrength = () => {
  const [isVisible, setIsVisible] = useState(true);
  const strengths = [
    { title: "Innovative and Creative Curriculum" },
    { title: "Realtime Learning Experience" },
    { title: "Individual Focus Driven Sessions" },
    { title: "Mentoring and Coaching" },
    { title: "Result Oriented Methodology" },
    { title: "Customized Programs" },
  ];

  useEffect(() => {
    const onScroll = () => {
      const element = document.querySelector(".core-strength-section");
      if (element) {
        const position = element.getBoundingClientRect();
        setIsVisible(position.top < window.innerHeight && position.bottom >= 0);
      }
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <section
      className={`core-strength-section text-center ${isVisible ? "visible" : ""}`}
      style={{ backgroundColor: "#ffffff", padding: "60px 0px 0px 0px" }}
    >
      <div className="container tree-container story-container background-primary">
        <h2 className="elegant-heading" style={{ color: "#595959" }}>
          Our Core Strength
        </h2>
        <img src={TreeImage} alt="Tree" className="tree-image" />
        {/* Add attractive text section here */}
        <div className="core-text">
          <h2 className="core-text" style={{ fontFamily: "'Lucida Calligraphy', cursive" }}>
            "Yes" as an answer always
          </h2>
        </div>

        {/* Image with text spaces around it */}
        <div className="center-image-section mt-2">
          <img src={CenterImage} alt="Center Image" className="center-image" />
          <div className="text-blocks">
            {strengths.slice(0, 6).map((strength, index) => (
              <div key={index} className={`text-space text-space-${index + 1}`}>
                <p>{strength.title}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CoreStrength;
