import React from "react";
import Navbar from "./navbar";
import Footer from "./home/vrddhim-footer";

const Locations = () => {
  window.scrollTo(0, 0);

  return (
    <>
      <Navbar />

      <section className="vrddhim-locations" id="locations">
        <div className="container my-3">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="elegant-heading text-center">Locations We Serve</h1>
              <p className="graceful-paragraph text-center">
                Vrddhim Spoken English Academy proudly offers its training sessions across various locations in the Dakshina Kannada District. Our
                expert instructors are available in Mangaluru, Moodabidri, Mulki, Ullala, Bantwala, Belthangady, Puttur, Sullia, and Kadaba regions.
                Whether you're a beginner or looking to improve your fluency, our courses are designed to meet all your English language learning
                needs.
              </p>

              <div className="location-list my-4">
                <h2 className="elegant-heading text-center">Our Service Locations</h2>
                <ul className="list-unstyled text-center">
                  <li>
                    <strong>Mangaluru:</strong> Comprehensive spoken English courses for all levels, available throughout the city.
                  </li>
                  <li>
                    <strong>Moodabidri:</strong> Enhance your English communication skills with our expert trainers in Moodabidri.
                  </li>
                  <li>
                    <strong>Mulki:</strong> Build your confidence in speaking English with our tailored courses in Mulki.
                  </li>
                  <li>
                    <strong>Ullala:</strong> Develop fluency in English with our immersive language training in Ullala.
                  </li>
                  <li>
                    <strong>Bantwala:</strong> Join our classes in Bantwala and master the art of effective English communication.
                  </li>
                  <li>
                    <strong>Belthangady:</strong> Learn English through interactive and engaging sessions in Belthangady.
                  </li>
                  <li>
                    <strong>Puttur:</strong> Start your English learning journey with personalized instruction in Puttur.
                  </li>
                  <li>
                    <strong>Sullia:</strong> Improve your English language skills with our structured courses in Sullia.
                  </li>
                  <li>
                    <strong>Kadaba:</strong> Take the first step towards English fluency with our expert guidance in Kadaba.
                  </li>
                </ul>
              </div>

              <div className="seo-content my-5">
                <h3 className="elegant-heading text-center">Why Choose Vrddhim for Your English Learning Journey?</h3>
                <p className="graceful-paragraph">
                  At Vrddhim Spoken English Academy, we understand the importance of mastering English in today’s global world. Our courses are
                  designed to boost your confidence and communication skills, making you fluent in English speaking, reading, writing, and listening.
                  Whether you're in Mangaluru or a small town like Kadaba, our instructors provide personalized attention to help you excel.
                </p>
                <p className="graceful-paragraph">
                  We offer a range of courses tailored for beginners, intermediate learners, and advanced speakers. Our unique teaching methods focus
                  on practical usage of the language, making learning fun and effective. Enroll today and take the first step towards fluency!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Locations;
