import React, { useState, useRef, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo, faMicrophone, faExpand, faDesktop, faShareAlt, faChalkboardTeacher, faArrowsAlt, faPen } from "@fortawesome/free-solid-svg-icons";
import { io } from "socket.io-client";
import axios from "axios";
import styles from "../../styles/pages/classliveMentors.module.scss";
import { HttpServiceContext } from "../../config/httpContext";

const ClassLiveMentor = () => {
  const { checkIsLogined, loginedType, setLoginedUserId, loginedUserId } = useContext(HttpServiceContext);

  const [isVideoMuted, setIsVideoMuted] = useState(false);
  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [isDrawing, setIsDrawing] = useState(false);
  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const [isWhiteboardActive, setIsWhiteboardActive] = useState(false);
  const [roomCode, setRoomCode] = useState("");
  const [isSessionStarted, setIsSessionStarted] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [courseName, setCourseName] = useState("");
  const [batchName, setBatchName] = useState("");
  const [userId, setUserId] = useState(null);
  const [isRoomCreated, setIsRoomCreated] = useState(false);

  // Refs
  const localVideoRef = useRef();
  const remoteVideoRef = useRef();
  const screenShareRef = useRef();
  const canvasRef = useRef();
  const contextRef = useRef();
  const socketRef = useRef();
  const peerConnectionRef = useRef(null);

  useEffect(() => {
    if (loginedType === "mentor") {
      const initialize = async () => {
        const response = await checkIsLogined();
        if (response?.user_id) {
          setLoginedUserId(response.user_id);
          document.cookie = `userid=${response.user_id}; path=/; SameSite=Strict`;
        }

        const fetchMentorData = async () => {
          const recieve = await axios.get(`https://api.vrddhim.org/mentors/${response.data.user_id}`);
          if (recieve) {
            setUserId(response.user_id);
          }
        };

        const fetchProfile = async () => {
          const response = await axios.get(`https://api.vrddhim.org/profile/${response.data.user_id}`);
          if (response) {
            setCourseName(response.data.course_name);
            setBatchName(response.data.batch_name);
          }
        };

        socketRef.current = io("https://api.vrddhim.org");

        socketRef.current.on("session_started", (data) => {
          setIsSessionStarted(true);
          console.log(data.message);
        });

        socketRef.current.on("ice-candidate", (data) => {
          try {
            peerConnectionRef.current.addIceCandidate(new RTCIceCandidate(data.candidate));
            console.log("ICE Candidate added:", data.candidate);
          } catch (err) {
            console.error("Error adding ICE candidate:", err);
          }
        });

        socketRef.current.on("chat_message", (data) => {
          setChatMessages((prevMessages) => [...prevMessages, data]);
        });

        await fetchMentorData();
        await fetchProfile();
        getLocalStream();

        return () => {
          if (socketRef.current) {
            socketRef.current.emit("mentor_left", { room: roomCode });
            socketRef.current.disconnect();
          }
          if (peerConnectionRef.current) {
            peerConnectionRef.current.close();
          }
        };
      };

      initialize();
    }
  }, [checkIsLogined, loginedType, setLoginedUserId]);

  const createPeerConnection = () => {
    const peerConnection = new RTCPeerConnection({
      iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
    });

    peerConnection.onicecandidate = (event) => {
      if (event.candidate) {
        socketRef.current.emit("ice-candidate", {
          room: roomCode,
          candidate: event.candidate,
        });
      }
    };

    peerConnection.ontrack = (event) => {
      console.log("Received remote stream", event.streams[0]);
      if (remoteVideoRef.current) {
        remoteVideoRef.current.srcObject = event.streams[0];
      }
    };

    return peerConnection;
  };
  //for video and audio
  const getLocalStream = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      if (localVideoRef.current) {
        localVideoRef.current.srcObject = stream;
      }
      peerConnectionRef.current = createPeerConnection();
      stream.getTracks().forEach((track) => peerConnectionRef.current.addTrack(track, stream));
    } catch (err) {
      console.error("Error accessing media devices:", err);
      alert("Failed to access media devices. Please check permissions.");
    }
  };

  let mediaStream;

  const startMediaStream = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    } catch (err) {
      console.error("Failed to access media devices:", err);
    }
  };

  //adding participants

  //          const addParticipant = async (roomId, userId, role) => {
  //              try {
  //                const response = await axios.post("https://api.vrddhim.org/participants", {
  //                           room_id: roomId,
  //                           userid: userId,
  //                           role: role,
  //                           join_time: new Date().toISOString(),
  //                           leave_time: null,
  //     });

  //     if (response.status === 201) {
  //       console.log("Participant added:", response.data.message);
  //     } else {
  //       console.error("Error adding participant:", response.data.error);
  //     }
  //   } catch (error) {
  //     if (error.response) {

  //       console.error("Error adding participant:", error.response.data.error);
  //     } else {

  //       console.error("Request failed:", error.message);
  //     }
  //   }
  // };addParticipant();

  useEffect(() => {
    const createRoom = async (mentorId) => {
      if (!userId) return;

      try {
        const response = await axios.post("https://api.vrddhim.org/create/rooms", {
          user_id: userId,
        });

        if (response.status === 201) {
          console.log("Room created successfully:", response.data.room_link);
          setRoomCode(response.data.room_code);
          setIsRoomCreated(true);
        } else {
          console.error("Room creation failed:", response.data.error);
        }
      } catch (error) {
        console.error("Error creating room:", error.response?.data?.error || error.message);
      }
    };

    if (userId) {
      createRoom(userId);
    }
  }, [userId]);

  // Start Session here
  const startSession = async (roomCode) => {
    try {
      await axios.post("https://api.vrddhim.org/mentor/start/session", { room_name: roomCode });
      socketRef.current.emit("start_session", { room: roomCode });
      await getLocalStream();
    } catch (error) {
      console.error("Error starting session:", error);
    }
  };

  useEffect(() => {
    if (isRoomCreated) {
      startSession();
    }
  }, [isRoomCreated]);

  const endSession = async () => {
    try {
      await axios.put(`https://api.vrddhim.org/rooms/${roomCode}/end`);
      socketRef.current.emit("end_session", { room: roomCode });
      console.log("Session ended successfully");
    } catch (error) {
      console.error("Error ending session:", error);
    }
  };

  const handleIceCandidate = (candidate) => {
    socketRef.current.emit("ice-candidate", { room: roomCode, candidate });
  };

  const fetchChats = async (roomId) => {
    try {
      const response = await axios.get(`https://api.vrddhim.org/rooms/${roomId}/chats`);
      setChatMessages(response.data);
    } catch (error) {
      console.error("Error fetching chat messages:", error);
    }
  };

  const fetchParticipants = async (roomId) => {
    try {
      const response = await axios.get(`https://api.vrddhim.org/rooms/${roomId}/participants`);
      console.log("Participants:", response.data);
    } catch (error) {
      console.error("Error fetching participants:", error);
    }
  };

  const toggleVideo = (stream) => {
    if (stream && stream.getVideoTracks().length > 0) {
      const videoTrack = stream.getVideoTracks()[0];
      videoTrack.enabled = !videoTrack.enabled;
      setIsVideoMuted(!videoTrack.enabled);
    } else {
      console.error("No video stream available");
    }
  };

  const toggleAudio = () => {
    if (localVideoRef.current) {
      const audioTrack = localVideoRef.current.srcObject.getAudioTracks()[0];
      if (audioTrack) {
        audioTrack.enabled = !isAudioMuted;
        setIsAudioMuted(!isAudioMuted);
      }
    }
  };

  const joinClass = () => {
    if (roomCode) {
      socketRef.current.emit("join_class", { room: roomCode });
    }
  };
  joinClass();

  const toggleFullscreen = () => {
    const videoContainer = localVideoRef.current?.parentElement;
    if (videoContainer) {
      if (!document.fullscreenElement) {
        videoContainer.requestFullscreen().catch((err) => {
          console.error("Fullscreen failed: ", err);
        });
      } else {
        document.exitFullscreen();
      }
    }
  };

  const toggleScreenShare = async () => {
    if (isScreenSharing) {
      const tracks = screenShareRef.current?.srcObject?.getTracks();
      if (tracks) {
        tracks.forEach((track) => track.stop());
        screenShareRef.current.srcObject = null;
      }
      setIsScreenSharing(false);
    } else {
      try {
        const screenStream = await navigator.mediaDevices.getDisplayMedia({ video: true });
        screenShareRef.current.srcObject = screenStream;
        const screenTrack = screenStream.getVideoTracks()[0];
        const sender = peerConnectionRef.current.getSenders().find((s) => s.track.kind === screenTrack.kind);
        if (sender) {
          sender.replaceTrack(screenTrack);
        }
        setIsScreenSharing(true);
      } catch (err) {
        console.error("Error sharing screen.", err);
      }
    }
  };

  const toggleWhiteboard = () => {
    setIsWhiteboardActive(!isWhiteboardActive);
  };

  const startDrawing = ({ nativeEvent }) => {
    const { offsetX, offsetY } = nativeEvent;
    contextRef.current.beginPath();
    contextRef.current.moveTo(offsetX, offsetY);
    setIsDrawing(true);
  };

  const draw = ({ nativeEvent }) => {
    if (!isDrawing) return;

    const { offsetX, offsetY } = nativeEvent;
    contextRef.current.lineTo(offsetX, offsetY);
    contextRef.current.stroke();
    socketRef.current.emit("drawing", { offsetX, offsetY });
  };

  const stopDrawing = () => {
    contextRef.current.closePath();
    setIsDrawing(false);
  };

  useEffect(() => {
    if (canvasRef.current && isWhiteboardActive) {
      const canvas = canvasRef.current;
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
      const context = canvas.getContext("2d");
      context.lineCap = "round";
      context.strokeStyle = "black";
      context.lineWidth = 3;
      contextRef.current = context;
    }
  }, [isWhiteboardActive]);

  const handleSendMessage = () => {
    if (newMessage.trim()) {
      axios
        .post("https://api.vrddhim.org/chats", {
          room_id: roomCode,
          user_id: userId,
          message: newMessage,
        })
        .then(() => socketRef.current.emit("chat_message", { room: roomCode, message: newMessage }));
      setNewMessage("");
    }
  };

  return (
    <>
      <div className={styles.mainContent}>
        <div className="row no-gutters">
          <div className="col-lg-9">
            <div className={styles.videoContainer}>
              {isWhiteboardActive ? (
                <div className={styles.whiteboardContainer}>
                  <canvas
                    ref={canvasRef}
                    className={styles.whiteboard}
                    onMouseDown={startDrawing}
                    onMouseMove={draw}
                    onMouseUp={stopDrawing}
                    onMouseOut={stopDrawing}
                  ></canvas>
                  <FontAwesomeIcon icon={faArrowsAlt} className={styles.fullscreenIcon} onClick={toggleFullscreen} />
                </div>
              ) : (
                <>
                  <video ref={localVideoRef} className={styles.video} autoPlay muted></video>
                  <video ref={remoteVideoRef} className={styles.video} autoPlay></video>
                  <video ref={screenShareRef} className={styles.video} autoPlay></video>
                </>
              )}
              <div className={styles.controlsDrawer}>
                <FontAwesomeIcon
                  icon={faVideo}
                  className={`${styles.controlIcon} ${isVideoMuted ? styles.disabled : ""}`}
                  onClick={toggleVideo}
                  title={isVideoMuted ? "Unmute Video" : "Mute Video"}
                />
                <FontAwesomeIcon
                  icon={faMicrophone}
                  className={`${styles.controlIcon} ${isAudioMuted ? styles.disabled : ""}`}
                  onClick={toggleAudio}
                  title={isAudioMuted ? "Unmute Audio" : "Mute Audio"}
                />
                <FontAwesomeIcon icon={faExpand} className={styles.controlIcon} onClick={toggleFullscreen} title="Fullscreen" />
                <FontAwesomeIcon
                  icon={faDesktop}
                  className={`${styles.controlIcon} ${isScreenSharing ? styles.active : ""}`}
                  onClick={toggleScreenShare}
                  title={isScreenSharing ? "Stop Screen Sharing" : "Share Screen"}
                />
                <FontAwesomeIcon
                  icon={faChalkboardTeacher}
                  className={`${styles.controlIcon} ${isWhiteboardActive ? styles.active : ""}`}
                  onClick={toggleWhiteboard}
                  title={isWhiteboardActive ? "Stop Whiteboard" : "Start Whiteboard"}
                />
                <FontAwesomeIcon
                  icon={faPen}
                  className={`${styles.controlIcon} ${isDrawing ? styles.active : ""}`}
                  onClick={() => setIsDrawing((prev) => !prev)}
                  title={isDrawing ? "Stop Drawing" : "Start Drawing"}
                />
                <FontAwesomeIcon
                  icon={faShareAlt}
                  className={styles.controlIcon}
                  onClick={() => console.log("Resource shared")}
                  title="Share Resource"
                />
              </div>
            </div>

            {/* Chat Interface */}
            <div className={styles.chatContainer}>
              <h3>Class Chat</h3>
              <div className={styles.chatBox}>
                {chatMessages.map((msg, index) => (
                  <div key={index} className={styles.chatMessage}>
                    {msg.message}
                  </div>
                ))}
              </div>
              <input
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Type a message..."
                className={styles.chatInput}
              />
              <button onClick={handleSendMessage} className={styles.chatSendBtn}>
                Send
              </button>
            </div>
          </div>

          <div className="col-lg-3">
            <div className={styles.topicsPanel}>
              <h3>Topics to Cover Today</h3>
              <ul>
                <li>Introduction to English</li>
                <li>Component Lifecycle</li>
                <li>Vowels</li>
                <li>Handling Speeches</li>
                <li>Building Forms</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClassLiveMentor;

// import React, { useState, useRef, useEffect, useContext } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faVideo,
//   faMicrophone,
//   faExpand,
//   faDesktop,
//   faShareAlt,
//   faChalkboardTeacher,
//   faArrowsAlt,
//   faPen,
//   faSpinner,
// } from "@fortawesome/free-solid-svg-icons";
// import { io } from "socket.io-client";
// import axios from "axios";
// import styles from "../../styles/pages/classliveMentors.module.scss";
// import { HttpServiceContext } from "../../config/httpContext";
// import { Alert, Button, Form } from "react-bootstrap";

// const ClassLiveMentor = () => {
//   const { checkIsLogined, loginedType, setLoginedUserId } = useContext(HttpServiceContext);

//   const [isVideoMuted, setIsVideoMuted] = useState(false);
//   const [isLoadingVideo, setIsLoadingVideo] = useState(false);
//   const [isAudioMuted, setIsAudioMuted] = useState(false);
//   const [isDrawing, setIsDrawing] = useState(false);
//   const [isScreenSharing, setIsScreenSharing] = useState(false);
//   const [isWhiteboardActive, setIsWhiteboardActive] = useState(false);
//   const [roomCode, setRoomCode] = useState("");
//   const [isSessionStarted, setIsSessionStarted] = useState(false);
//   const [chatMessages, setChatMessages] = useState([]);
//   const [newMessage, setNewMessage] = useState("");
//   const [courseName, setCourseName] = useState("");
//   const [batchName, setBatchName] = useState("");
//   const [userId, setUserId] = useState(null);
//   const [isRoomCreated, setIsRoomCreated] = useState(false);
//   const [errorMessage, setErrorMessage] = useState("");

//   // Refs
//   const localVideoRef = useRef(null);
//   const remoteVideoRef = useRef(null);
//   const screenShareRef = useRef(null);
//   const canvasRef = useRef(null);
//   const contextRef = useRef(null);
//   const socketRef = useRef(null);
//   const peerConnectionRef = useRef(null);

//   const httpService = useContext(HttpServiceContext);

//   const ICE_SERVERS = {
//     iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
//   };

//   useEffect(() => {
//     if (loginedType === "mentor") {
//       const initialize = async () => {
//         try {
//           // Check if mentor is logged in
//           const response = await checkIsLogined();
//           if (response?.user_id) {
//             setLoginedUserId(response.user_id);
//             document.cookie = `userid=${response.user_id}; path=/; SameSite=Strict`;
//             setUserId(response.user_id);
//           } else {
//             setErrorMessage("Mentor not logged in.");
//             return;
//           }

//           // Fetch mentor profile
//           const fetchProfile = async () => {
//             try {
//               const profileResponse = await axios.get(`https://api.vrddhim.org/profile/${response.user_id}`);
//               if (profileResponse) {
//                 setCourseName(profileResponse.data.course_name);
//                 setBatchName(profileResponse.data.batch_name);
//               }
//             } catch (err) {
//               console.error("Error fetching profile:", err);
//               setErrorMessage("Failed to fetch profile.");
//             }
//           };

//           await fetchProfile();

//           // Initialize Socket.IO
//           socketRef.current = io("https://api.vrddhim.org");

//           // Set up Socket.IO event listeners
//           socketRef.current.on("answer", handleAnswer);
//           socketRef.current.on("ice-candidate", handleIceCandidate);
//           socketRef.current.on("session_started", (data) => {
//             setIsSessionStarted(true);
//             console.log(data.message);
//           });
//           socketRef.current.on("chat_message", (data) => {
//             setChatMessages((prevMessages) => [...prevMessages, data]);
//           });

//           // Create a room for the mentor
//           await createRoom(response.user_id);
//         } catch (err) {
//           console.error("Initialization error:", err);
//           setErrorMessage("Failed to initialize the session.");
//         }
//       };

//       initialize();

//       // Clean up on unmount
//       return () => {
//         if (socketRef.current) {
//           socketRef.current.emit("mentor_left", { room: roomCode });
//           socketRef.current.disconnect();
//         }
//         if (peerConnectionRef.current) {
//           peerConnectionRef.current.close();
//           peerConnectionRef.current = null;
//         }
//       };
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [loginedType]);

//   // Create room when userId is set
//   useEffect(() => {
//     if (userId) {
//       createRoom(userId);
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [userId]);

//   // Start session when room is created
//   useEffect(() => {
//     if (isRoomCreated) {
//       startSession();
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [isRoomCreated]);

//   // Function to create a room
//   const createRoom = async (mentorId) => {
//     try {
//       const response = await axios.post("https://api.vrddhim.org/create/rooms", {
//         user_id: mentorId,
//       });

//       if (response.status === 201) {
//         console.log("Room created successfully:", response.data.room_link);
//         setRoomCode(response.data.room_code);
//         setIsRoomCreated(true);
//       } else {
//         console.error("Room creation failed:", response.data.error);
//         setErrorMessage("Failed to create room.");
//       }
//     } catch (error) {
//       console.error("Error creating room:", error.response?.data?.error || error.message);
//       setErrorMessage("Error creating room.");
//     }
//   };

//   // Function to get local media stream
//   const getLocalStream = async () => {
//     try {
//       const stream = await navigator.mediaDevices.getUserMedia({
//         video: true,
//         audio: true,
//       });

//       // Display the local video
//       if (localVideoRef.current) {
//         localVideoRef.current.srcObject = stream;
//       }

//       setIsLoadingVideo(false);
//       manageAudioTracks(stream);

//       return stream; // Return the stream for further use
//     } catch (err) {
//       console.error("Error accessing media devices:", err);
//       setErrorMessage("Failed to access media devices.");
//       alert("Failed to access media devices. Please check permissions.");
//       return null;
//     }
//   };

//   // Function to manage audio tracks
//   const manageAudioTracks = (stream) => {
//     const audioTracks = stream.getAudioTracks();

//     if (audioTracks.length > 0) {
//       const audioTrack = audioTracks[0];
//       console.log("Using audio track:", audioTrack);

//       audioTrack.enabled = !isAudioMuted;
//     } else {
//       console.warn("No audio tracks found in the stream.");
//     }
//   };

//   // Function to handle 'answer' event
//   const handleAnswer = async (data) => {
//     const { answer } = data;

//     try {
//       if (!peerConnectionRef.current) {
//         console.error("PeerConnection not established before receiving answer.");
//         return;
//       }

//       await peerConnectionRef.current.setRemoteDescription(new RTCSessionDescription(answer));
//       console.log("Remote description set with answer.");
//     } catch (err) {
//       console.error("Error handling answer:", err);
//     }
//   };

//   // Function to handle 'ice-candidate' event
//   const handleIceCandidate = async (data) => {
//     const { candidate } = data;

//     try {
//       if (candidate) {
//         await peerConnectionRef.current.addIceCandidate(new RTCIceCandidate(candidate));
//         console.log("ICE Candidate added from user:", candidate);
//       }
//     } catch (err) {
//       console.error("Error adding received ICE candidate from user", err);
//     }
//   };

//   // Function to join class
//   const joinClass = () => {
//     if (roomCode) {
//       socketRef.current.emit("join_class", { room: roomCode });
//       console.log(`Joined class with roomCode: ${roomCode}`);
//     }
//   };

//   // Function to start the session (create offer and send to users)
//   const startSession = async () => {
//     try {
//       const stream = await getLocalStream();
//       if (!stream) {
//         console.error("No local stream available to start session.");
//         return;
//       }

//       createPeerConnection();

//       // Add tracks to peer connection
//       stream.getTracks().forEach((track) => peerConnectionRef.current.addTrack(track, stream));

//       // Create an offer
//       const offer = await peerConnectionRef.current.createOffer();
//       await peerConnectionRef.current.setLocalDescription(offer);

//       // Send the offer via socket
//       socketRef.current.emit("offer", { room: roomCode, offer });
//       console.log("Offer sent to users:", offer);
//     } catch (error) {
//       console.error("Error starting session:", error);
//       setErrorMessage("Failed to start session.");
//     }
//   };

//   // Function to create peer connection
//   const createPeerConnection = () => {
//     const peerConnection = new RTCPeerConnection(ICE_SERVERS);
//     peerConnectionRef.current = peerConnection;

//     // Handle ICE candidates generated by this peer (mentor)
//     peerConnection.onicecandidate = (event) => {
//       if (event.candidate) {
//         socketRef.current.emit("ice-candidate", {
//           room: roomCode,
//           candidate: event.candidate,
//         });
//         console.log("ICE candidate sent:", event.candidate);
//       }
//     };

//     // Handle incoming tracks from users (if any; assuming mentor only sends video)
//     peerConnection.ontrack = (event) => {
//       console.log("Received track:", event.track.kind);
//       if (event.track.kind === "video" && remoteVideoRef.current) {
//         remoteVideoRef.current.srcObject = event.streams[0];
//         setIsLoadingVideo(false);
//       }
//     };

//     // Handle connection state changes for debugging
//     peerConnection.oniceconnectionstatechange = () => {
//       const state = peerConnection.iceConnectionState;
//       console.log("ICE Connection State:", state);

//       if (state === "connected") {
//         console.log("WebRTC connection established successfully.");
//       } else if (state === "disconnected") {
//         console.log("WebRTC connection disconnected.");
//       } else if (state === "failed") {
//         console.error("WebRTC connection failed.");
//       }
//     };
//   };

//   // Function to end session
//   const endSession = async () => {
//     try {
//       await axios.put(`https://api.vrddhim.org/rooms/${roomCode}/end`);
//       socketRef.current.emit("end_session", { room: roomCode });
//       console.log("Session ended successfully");

//       // Clean up peer connection
//       if (peerConnectionRef.current) {
//         peerConnectionRef.current.close();
//         peerConnectionRef.current = null;
//       }
//     } catch (error) {
//       console.error("Error ending session:", error);
//       setErrorMessage("Failed to end session.");
//     }
//   };

//   // Function to toggle video
//   const toggleVideo = () => {
//     if (localVideoRef.current && localVideoRef.current.srcObject) {
//       const videoTracks = localVideoRef.current.srcObject.getVideoTracks();
//       if (videoTracks.length > 0) {
//         const videoTrack = videoTracks[0];
//         videoTrack.enabled = !isVideoMuted;
//         setIsVideoMuted(!videoTrack.enabled);
//       }
//     }
//   };

//   // Function to toggle audio
//   const toggleAudio = () => {
//     if (localVideoRef.current && localVideoRef.current.srcObject) {
//       const audioTracks = localVideoRef.current.srcObject.getAudioTracks();
//       if (audioTracks.length > 0) {
//         const audioTrack = audioTracks[0];
//         audioTrack.enabled = !isAudioMuted;
//         setIsAudioMuted(!audioTrack.enabled);
//       }
//     }
//   };

//   // Function to toggle fullscreen
//   const toggleFullscreen = () => {
//     const videoContainer = document.querySelector(`.${styles.videoContainer}`);
//     if (videoContainer) {
//       if (!document.fullscreenElement) {
//         videoContainer.requestFullscreen().catch((err) => {
//           console.error("Error attempting to enable fullscreen mode:", err);
//         });
//       } else {
//         document.exitFullscreen();
//       }
//     }
//   };

//   // Function to toggle screen sharing
//   const toggleScreenShare = async () => {
//     if (isScreenSharing) {
//       const tracks = screenShareRef.current?.srcObject?.getTracks();
//       if (tracks) {
//         tracks.forEach((track) => track.stop());
//         screenShareRef.current.srcObject = null;
//       }
//       setIsScreenSharing(false);
//     } else {
//       try {
//         const screenStream = await navigator.mediaDevices.getDisplayMedia({ video: true });
//         screenShareRef.current.srcObject = screenStream;
//         const screenTrack = screenStream.getVideoTracks()[0];
//         const sender = peerConnectionRef.current.getSenders().find((s) => s.track.kind === screenTrack.kind);
//         if (sender) {
//           sender.replaceTrack(screenTrack);
//         }
//         setIsScreenSharing(true);
//       } catch (err) {
//         console.error("Error sharing screen.", err);
//       }
//     }
//   };

//   // Function to toggle whiteboard
//   const toggleWhiteboard = () => {
//     setIsWhiteboardActive(!isWhiteboardActive);
//   };

//   // Drawing functions
//   const startDrawing = ({ nativeEvent }) => {
//     const { offsetX, offsetY } = nativeEvent;
//     contextRef.current.beginPath();
//     contextRef.current.moveTo(offsetX, offsetY);
//     setIsDrawing(true);
//   };

//   const draw = ({ nativeEvent }) => {
//     if (!isDrawing) return;

//     const { offsetX, offsetY } = nativeEvent;
//     contextRef.current.lineTo(offsetX, offsetY);
//     contextRef.current.stroke();
//     socketRef.current.emit("drawing", { offsetX, offsetY });
//   };

//   const stopDrawing = () => {
//     contextRef.current.closePath();
//     setIsDrawing(false);
//   };

//   // Initialize canvas for whiteboard
//   useEffect(() => {
//     if (canvasRef.current && isWhiteboardActive) {
//       const canvas = canvasRef.current;
//       canvas.width = canvas.offsetWidth;
//       canvas.height = canvas.offsetHeight;
//       const context = canvas.getContext("2d");
//       context.lineCap = "round";
//       context.strokeStyle = "black";
//       context.lineWidth = 3;
//       contextRef.current = context;
//     }
//   }, [isWhiteboardActive]);

//   // Function to handle sending chat messages
//   const handleSendMessage = () => {
//     if (newMessage.trim()) {
//       axios
//         .post("https://api.vrddhim.org/chats", {
//           room_id: roomCode,
//           user_id: userId,
//           message: newMessage,
//         })
//         .then(() => socketRef.current.emit("chat_message", { room: roomCode, message: newMessage }))
//         .catch((err) => {
//           console.error("Error sending message:", err);
//           setErrorMessage("Failed to send message.");
//         });
//       setNewMessage("");
//     }
//   };

//   return (
//     <div className={styles.mainContent}>
//       <div className="row no-gutters">
//         <div className="col-lg-9">
//           <div className={styles.videoContainer}>
//             {isLoadingVideo && (
//               <div className={styles.loadingSpinner}>
//                 <FontAwesomeIcon icon={faSpinner} spin size="3x" />
//                 <p>Loading video...</p>
//               </div>
//             )}

//             {isWhiteboardActive ? (
//               <div className={styles.whiteboardContainer}>
//                 <canvas
//                   ref={canvasRef}
//                   className={styles.whiteboard}
//                   onMouseDown={startDrawing}
//                   onMouseMove={draw}
//                   onMouseUp={stopDrawing}
//                   onMouseOut={stopDrawing}
//                 ></canvas>
//                 <FontAwesomeIcon icon={faArrowsAlt} className={styles.fullscreenIcon} onClick={toggleFullscreen} />
//               </div>
//             ) : (
//               <>
//                 {/* Local Video */}
//                 <video ref={localVideoRef} className={styles.video} autoPlay muted playsInline></video>
//                 {/* Remote Video - Not typically used on mentor side unless bidirectional */}
//                 {/* <video ref={remoteVideoRef} className={styles.video} autoPlay playsInline></video> */}
//                 {/* Screen Share */}
//                 <video ref={screenShareRef} className={styles.video} autoPlay playsInline></video>
//               </>
//             )}
//             <div className={styles.controlsDrawer}>
//               <FontAwesomeIcon
//                 icon={faVideo}
//                 className={`${styles.controlIcon} ${isVideoMuted ? styles.disabled : ""}`}
//                 onClick={toggleVideo}
//                 title={isVideoMuted ? "Unmute Video" : "Mute Video"}
//               />
//               <FontAwesomeIcon
//                 icon={faMicrophone}
//                 className={`${styles.controlIcon} ${isAudioMuted ? styles.disabled : ""}`}
//                 onClick={toggleAudio}
//                 title={isAudioMuted ? "Unmute Audio" : "Mute Audio"}
//               />
//               <FontAwesomeIcon icon={faExpand} className={styles.controlIcon} onClick={toggleFullscreen} title="Fullscreen" />
//               <FontAwesomeIcon
//                 icon={faDesktop}
//                 className={`${styles.controlIcon} ${isScreenSharing ? styles.active : ""}`}
//                 onClick={toggleScreenShare}
//                 title={isScreenSharing ? "Stop Screen Sharing" : "Share Screen"}
//               />
//               <FontAwesomeIcon
//                 icon={faChalkboardTeacher}
//                 className={`${styles.controlIcon} ${isWhiteboardActive ? styles.active : ""}`}
//                 onClick={toggleWhiteboard}
//                 title={isWhiteboardActive ? "Stop Whiteboard" : "Start Whiteboard"}
//               />
//               <FontAwesomeIcon
//                 icon={faPen}
//                 className={`${styles.controlIcon} ${isDrawing ? styles.active : ""}`}
//                 onClick={() => setIsDrawing((prev) => !prev)}
//                 title={isDrawing ? "Stop Drawing" : "Start Drawing"}
//               />
//               <FontAwesomeIcon
//                 icon={faShareAlt}
//                 className={styles.controlIcon}
//                 onClick={() => console.log("Resource shared")}
//                 title="Share Resource"
//               />
//             </div>
//           </div>

//           {/* Chat Interface */}
//           <div className={styles.chatContainer}>
//             <h3>Class Chat</h3>
//             <div className={styles.chatBox}>
//               {chatMessages.map((msg, index) => (
//                 <div key={index} className={styles.chatMessage}>
//                   {msg.message}
//                 </div>
//               ))}
//             </div>
//             <input
//               type="text"
//               value={newMessage}
//               onChange={(e) => setNewMessage(e.target.value)}
//               placeholder="Type a message..."
//               className={styles.chatInput}
//             />
//             <button onClick={handleSendMessage} className={styles.chatSendBtn}>
//               Send
//             </button>
//           </div>
//         </div>

//         <div className="col-lg-3">
//           <div className={styles.topicsPanel}>
//             <h3>Topics to Cover Today</h3>
//             <ul>
//               <li>Introduction to English</li>
//               <li>Component Lifecycle</li>
//               <li>Vowels</li>
//               <li>Handling Speeches</li>
//               <li>Building Forms</li>
//             </ul>
//           </div>
//         </div>
//       </div>

//       {/* Display Error Message if any */}
//       {errorMessage && (
//         <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
//           {errorMessage}
//         </Alert>
//       )}
//     </div>
//   );
// };

// export default ClassLiveMentor;
