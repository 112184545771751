import React from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "../../styles/pages/Cards.scss";

const VrddhimAboutcards = () => {
  return (
    <section className="cards-section">
      <div className="container cards-container " style={{ backgroundColor: "#eaf3ea" }}>
        <div className="row py-4">
          {/* Mission Card */}
          <div className="col-md-4 mb-4">
            <div className="card shadow-sm h-100 text-center">
              <div className="card-body">
                <div className="icon mb-3">
                  <i className="fas fa-binoculars fs-3 text-success"></i>
                </div>
                <h5 className="card-title">Mission</h5>
                <p className="card-text">
                  Vrddhim’s mission is to craft an immersive real-world learning experience that empowers our learners to speak the business language
                  with absolute confidence. Through our innovative and strategic methods, we simplify the journey of mastering English-speaking
                  skills. Our expert trainers provide sessions that instil confidence in a supportive and highly engaging learning environment.
                </p>
              </div>
            </div>
          </div>

          {/* Vision Card */}
          <div className="col-md-4 mb-4">
            <div className="card shadow-sm h-100 text-center">
              <div className="card-body">
                <div className="icon mb-3">
                  <i className="fas fa-rocket fs-3 text-success"></i>
                </div>
                <h5 className="card-title">Vision</h5>
                <p className="card-text">
                  Vrddhim envisions itself as the leading solution for individuals seeking to excel in eloquent English skills. We promote a holistic
                  approach of learning that fosters growth, ensuring both the mind and spirit are in harmony. Our aim is to nurture productive
                  learning, allowing our learners to reach their fullest potential.
                </p>
              </div>
            </div>
          </div>

          {/* Our Team Card */}
          <div className="col-md-4 mb-4">
            <div className="card shadow-sm h-100 text-center">
              <div className="card-body">
                <div className="icon mb-3">
                  <i className="fas fa-users fs-3 text-success"></i>
                </div>
                <h5 className="card-title">Our Team</h5>
                <p className="card-text">
                  ” Team Vrddhim” is a group of experienced trainers with 20 + years of expertise in this field of learning and development, with a
                  profile ranging from managerial hands- on strategic learning experience &amp; result oriented approach. Our team is spearheaded by
                  our American certified Communication Coach and guided by pioneers in this field. Team Vrddhim ensures an exclusive learning journey
                  and equips you to embrace the opportunity that awaits ahead of you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VrddhimAboutcards;
