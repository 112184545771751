import React, { useContext, useEffect } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { HttpServiceContext } from "../../config/httpContext";
const EmailVerified = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const httpService = useContext(HttpServiceContext);

  useEffect(() => {
    const path = location.pathname;
    console.log(path);
    const match = path.match(/\/register\/success\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/i);
    if (match) {
      verifyMail(match[1]);
    }
  }, [location]);

  async function verifyMail(token) {
    let response = await httpService.verifyRegistration(token);
    if (response) {
      console.log(response);
    }
  }

  const handleLoginRedirect = () => {
    navigate("/login");
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className="card shadow-lg p-4" style={{ maxWidth: "500px", width: "100%" }}>
        <div className="card-body text-center">
          <div className="mb-3">
            <FaCheckCircle className="text-success" style={{ fontSize: "3rem" }} />
          </div>
          <h4 className="card-title mb-3">Email Verified Successfully</h4>
          <p className="card-text">Your email has been successfully verified. Please log in to continue.</p>
          <button className="btn bttn-primary mt-3" onClick={handleLoginRedirect}>
            Go to Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmailVerified;
