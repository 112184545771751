import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "../../styles/pages/users-management.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { HttpServiceContext } from "../../config/httpContext";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDeleted, setShowDeleted] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: "username", direction: "ascending" });
  const [showSortDropdown, setShowSortDropdown] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [currentUserId, setCurrentUserId] = useState(null);
  const [enrollments, setEnrollments] = useState([]);
  const [enrollmentLoading, setEnrollmentLoading] = useState(false);
  const [enrollmentError, setEnrollmentError] = useState(null);

  const httpService = useContext(HttpServiceContext);

  useEffect(() => {
    fetchUsers();
  }, [showDeleted]);

  const fetchUsers = async () => {
    let response = showDeleted ? await httpService.getTrashedUsers() : await httpService.getRegisteredUsers();
    if (response) {
      setUsers(response);
      setLoading(false);
    } else if (response?.data?.error) {
      console.log(response.data.error);
    }
  };

  const handleDeleteUser = async () => {
    if (!currentUserId) {
      setError("No user ID selected for deletion.");
      return;
    }
    let response = await httpService.deleteUsers(currentUserId);
    if (response) {
      setUsers(users.map((user) => (user.userid === currentUserId ? { ...user, isDeleted: true } : user)));
      setShowModal(false);
    }
  };

  const handleBlockUser = async () => {
    if (!currentUserId) {
      setError("No user ID selected for blocking/unblocking.");
      return;
    }
    let response = await httpService.blockUsers(currentUserId);
    if (response) {
      setUsers(users.map((user) => (user.userid === currentUserId ? { ...user, blocked: !user.blocked } : user)));
      setShowModal(false);
    }
  };

  const handleViewEnrollments = async (userId) => {
    setEnrollmentLoading(true);
    setEnrollmentError(null);
    let response = await httpService.getUserEnrollments(currentUserId);
    if (response) {
      setEnrollments(response);
      setModalType("enrollments");
      setEnrollmentLoading(false);
    }
    setShowModal(true);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });

    const sortedUsers = [...users].sort((a, b) => {
      if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
      if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
      return 0;
    });

    setUsers(sortedUsers);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  const selectedUser = users.find((user) => user.userid === currentUserId);

  return (
    <div className="users-container">
      <h2 className="eloquent-title">Users</h2>

      <div className="controls-container">
        <input type="text" placeholder="Search by username" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
        <button onClick={() => setShowDeleted(!showDeleted)} className="toggle-users-btn">
          {showDeleted ? "Show Active Users" : "Show Deleted Users"}
        </button>
        <div className="sort-dropdown">
          <button onClick={() => setShowSortDropdown(!showSortDropdown)} className="bttn-primary">
            Sort By
          </button>
          {showSortDropdown && (
            <div className="dropdown-content">
              <button onClick={() => handleSort("username")}>Username</button>
              <button onClick={() => handleSort("registration_date")}>Registration Date</button>
              <button onClick={() => handleSort("email")}>Email</button>
            </div>
          )}
        </div>
      </div>

      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>User ID</th>

              <th>Username</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Registration Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users
              .filter(
                (user) =>
                  user.username.toLowerCase().includes(searchTerm.toLowerCase()) || user.email.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map((user) => (
                <tr key={user.userid}>
                  <td>{user.userid}</td>

                  <td>{user.username}</td>
                  <td>{user.email}</td>
                  <td>{user.phone_number}</td>
                  <td>{new Date(user.registration_date).toLocaleString()}</td>
                  <td className="d-flex justify-content-around">
                    {!user.isDeleted && (
                      <>
                        {user.is_enrolled ? (
                          <button
                            onClick={() => {
                              setCurrentUserId(user.userid);
                              handleViewEnrollments(user.userid);
                            }}
                            className="info-btn"
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                          </button>
                        ) : (
                          <div className="p-4"></div>
                        )}

                        <button
                          onClick={() => {
                            setCurrentUserId(user.userid);
                            setModalType(user.blocked ? "unblock" : "block");
                            setShowModal(true);
                          }}
                          className={`block-btn ${user.blocked ? "blocked" : ""}`}
                        >
                          {user.blocked ? "Unblock" : "Block"}
                        </button>
                        <button
                          onClick={() => {
                            setCurrentUserId(user.userid);
                            setModalType("delete");
                            setShowModal(true);
                          }}
                          className="delete-btn"
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <Modal className="mt-20vh" show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {modalType === "delete"
              ? "Confirm Deletion"
              : modalType === "info"
              ? "User Info"
              : modalType === "enrollments"
              ? "Enrollment Details"
              : "Confirm Action"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalType === "delete" ? (
            "Are you sure you want to delete this user?"
          ) : modalType === "info" ? (
            selectedUser ? (
              <>
                <p>Username: {selectedUser.username}</p>
                <p>Email: {selectedUser.email}</p>
                <p>Phone Number: {selectedUser.phone_number}</p>
                <p>Registration Date: {new Date(selectedUser.registration_date).toLocaleString()}</p>
              </>
            ) : (
              "No user selected"
            )
          ) : modalType === "enrollments" ? (
            enrollmentLoading ? (
              <p>Loading enrollments...</p>
            ) : enrollmentError ? (
              <p>{enrollmentError}</p>
            ) : enrollments.length > 0 ? (
              enrollments.map((enrollment) => (
                <div key={enrollment.enrollment_id}>
                  <p>Student Name:{enrollment.username}</p>
                  <p>Course Name: {enrollment.course_name}</p>
                  <p>Batch name: {enrollment.batch_name}</p>
                  <p>Enrollment User ID:{enrollment.enrollment_id}</p>
                  <p>Enrollment Date: {new Date(enrollment.enrollment_date).toLocaleString()}</p>
                </div>
              ))
            ) : (
              "No enrollments found."
            )
          ) : modalType === "block" ? (
            "Are you sure you want to block this user?"
          ) : modalType === "unblock" ? (
            "Are you sure you want to unblock this user?"
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          {modalType === "delete" ? (
            <Button variant="danger" onClick={handleDeleteUser}>
              Delete
            </Button>
          ) : modalType === "block" || modalType === "unblock" ? (
            <Button variant="primary" onClick={handleBlockUser}>
              Confirm
            </Button>
          ) : null}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Users;
