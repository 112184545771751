// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-holder {
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  background-repeat: no-repeat;
  will-change: transform, opacity, filter;
  backface-visibility: hidden;
  z-index: 0;
}`, "",{"version":3,"sources":["webpack://./src/styles/pages/coverImage.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,gBAAA;EACA,4BAAA;EACA,uCAAA;EAEA,2BAAA;EACA,UAAA;AACF","sourcesContent":[".bg-holder {\r\n  width: 100%;\r\n  height: 300px;\r\n  background-size: cover;\r\n  background-position: center;\r\n  overflow: hidden;\r\n  background-repeat: no-repeat;\r\n  will-change: transform, opacity, filter;\r\n  -webkit-backface-visibility: hidden;\r\n  backface-visibility: hidden;\r\n  z-index: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
