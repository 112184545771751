import React from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaFacebook, FaLinkedin, FaInstagram } from "react-icons/fa";

const Footer = ({ marginTop }) => {
  const footerStyle = {
    marginTop: marginTop || "0",
    background: "linear-gradient(90deg, #3ab54a 0%, #b0b0b0 100%)",
    color: "#fff",
    padding: "30px 0",
  };

  const linkStyle = {
    color: "#fff",
    textDecoration: "none",
    transition: "color 0.3s ease",
  };

  const highlightedStyle = {
    color: "#fff",
    padding: "10px",
    borderRadius: "5px",
    textAlign: "center",
  };

  const underlineStyle = {
    height: "2px",
    width: "35px",
    background: "#ffd700",
    marginBottom: "10px",
  };

  return (
    <footer className="footer" style={footerStyle}>
      <div className="container ">
        <div className="row text-center text-md-start">
          {/* First column */}
          <div className="col-md-3 mb-2">
            <h5 className="mb-2">Vrddhim</h5>
            <div className="d-none d-md-block" style={underlineStyle} />
            <ul className="list-unstyled">
              <li>
                <Link to="/" style={linkStyle}>
                  Vrddhim Home
                </Link>
              </li>
              <li>
                <Link to="/courses" style={linkStyle}>
                  Vrddhim Courses
                </Link>
              </li>
              <li>
                <Link to="/about" style={linkStyle}>
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/contactus" style={linkStyle}>
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>

          {/* Second column */}
          <div className="col-md-3 ">
            <h5 className="mb-2">Explore</h5>
            <div className="d-none d-md-block" style={underlineStyle} />
            <ul className="list-unstyled">
              <li>
                <Link to="/blog" style={linkStyle}>
                  Blog
                </Link>
              </li>
              <li>
                <Link to="" style={linkStyle}>
                  Custom Classes
                </Link>
              </li>
              <li>
                <Link to="/helpandsupport" style={linkStyle}>
                  Help & Support
                </Link>
              </li>
              <li>
                <Link to="/locations" style={linkStyle}>
                  Location
                </Link>
              </li>
            </ul>
          </div>

          {/* Third column */}
          <div className="col-md-3 mb-2">
            <h5 className="mb-2">Legal</h5>
            <div className="d-none d-md-block" style={underlineStyle} />
            <ul className="list-unstyled">
              <li>
                <Link to="/termsandconditions" style={linkStyle}>
                  Terms
                </Link>
              </li>
              <li>
                <Link to="/privacypolicy" style={linkStyle}>
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/cookiesettings" style={linkStyle}>
                  Cookie Settings
                </Link>
              </li>
            </ul>
          </div>

          {/* Fourth column (Subscribe to Newsletter & Follow Us) */}
          <div className="col-md-3 mb-2">
            <h5 className=" mb-2">Follow Us</h5>
            <div className="d-none d-md-block" style={underlineStyle} />
            <div>
              <a href="https://www.facebook.com/profile.php?id=61567842538638/" className="me-3" target="_blank" rel="noreferrer" style={linkStyle}>
                <FaFacebook size={24} />
              </a>

              <a href="https://www.linkedin.com/company/vrddhim-english-academy/" className="me-3" target="_blank" rel="noreferrer" style={linkStyle}>
                <FaLinkedin size={24} />
              </a>
              <a href="https://www.instagram.com/vrddhim_training_academy/" target="_blank" rel="noreferrer" style={linkStyle}>
                <FaInstagram size={24} />
              </a>
            </div>
            <h5 className="mt-3 mb-2">Subscribe to Newsletter</h5>
            <div className="d-none d-md-block" style={underlineStyle} />
            <div className="d-flex align-items-center">
              <input type="email" placeholder="Enter your email" className="form-control me-2" style={{ borderRadius: "5px", height: "40px" }} />
              <button className="btn bttn-primary" style={{ height: "40px" }}>
                Subscribe
              </button>
            </div>
          </div>
        </div>

        {/* Divider line */}
        <hr style={{ backgroundColor: "#ffd700", height: "1px", border: "none", margin: "20px 0" }} />

        {/* Copyright Section */}
        <div className="d-flex flex-column justify-content-center align-items-center ">
          <div style={highlightedStyle}>
            © Copyrigt 2024 Vrddhim Academy - All Rights Reserved <br></br>Powered By{" "}
            <a
              href="https://hollasinnovations.com"
              target="_blank"
              className="text-white"
              rel="noreferrer"
              style={{ textDecoration: "underline", color: "#ffd700" }}
            >
              Holla's Innovations
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
