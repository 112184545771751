import React, { useState, useRef, useEffect, useContext } from "react";
import { io } from "socket.io-client";
import ChatBox from "../supportChat";
import "../../styles/pages/classlive.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicrophone,
  faExpand,
  faHandPaper,
  faHand,
  faArrowRight,
  faSpinner,
  faVideoSlash,
  faUsers,
  faMicrophoneSlash,
  faVideo,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Button, Form, Alert } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { HttpServiceContext } from "../../config/httpContext";

const LiveClass = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const roomIdFromUrl = searchParams.get("roomid");
  const userIdFromUrl = searchParams.get("userid");
  const roleFromUrl = searchParams.get("role");

  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef([]);
  const peerConnectionRef = useRef(null);
  const socketRef = useRef(null);
  const [remoteStreams, setRemoteStreams] = useState([]);
  const [audioStreams, setAudioStreams] = useState([]);

  const [roomId] = useState(roomIdFromUrl);
  const [userId] = useState(userIdFromUrl || "");
  const [role] = useState(roleFromUrl || "user");

  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [isVideoMuted, setIsVideoMuted] = useState(false);
  const [isHandRaised, setIsHandRaised] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [participantsList, setParticipantsList] = useState([]);
  const [currentMessage, setCurrentMessages] = useState("");
  const [qaList, setQaList] = useState([]);
  const [notes, setNotes] = useState("");
  const [activeTab, setActiveTab] = useState("overview");
  const [isLoadingVideo, setIsLoadingVideo] = useState(true);
  const [videoQuality, setVideoQuality] = useState("720p");
  const [isOfferCreated, setIsOfferCreated] = useState(false);
  const remoteAudioRef = useRef([]);
  const [messages, setMessages] = useState([]);
  const [profile, setProfile] = useState(null);
  const [userName, setUserName] = useState("");

  const httpService = useContext(HttpServiceContext);
  const navigate = useNavigate();

  const ICE_SERVERS = {
    iceServers: [
      {
        urls: "stun:stun.l.google.com:19302",
      },
      {
        url: "turn:192.158.29.39:3478?transport=udp",
        credential: "JZEOEt2V3Qb0y27GRntt2u2PAYA=",
        username: "28224511:1379330808",
      },
      {
        url: "turn:192.158.29.39:3478?transport=tcp",
        credential: "JZEOEt2V3Qb0y27GRntt2u2PAYA=",
        username: "28224511:1379330808",
      },
    ],
  };

  const videoResolutions = {
    "1080p": { width: { exact: 1920 }, height: { exact: 1080 } },
    "720p": { width: { exact: 1280 }, height: { exact: 720 } },
    "480p": { width: { exact: 640 }, height: { exact: 480 } },
  };

  useEffect(() => {
    initialize();

    return () => {
      if (peerConnectionRef.current) {
        peerConnectionRef.current.close();
        peerConnectionRef.current = null;
      }
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current.off("hand-raise", handleHandRaise);
        socketRef.current.off("join_class", handleJoin);
        socketRef.current.off("offer", handleOffer);
        socketRef.current.off("answer", handleAnswer);
        socketRef.current.off("ice-candidate", handleIceCandidate);
        socketRef.current.off("offer_status", offerStatus);
      }
    };
  }, []);

  const initialize = async () => {
    if (userId) {
      fetchProfile();
    }
    if (roomId) {
      // start Socket Connection
      socketRef.current = io("https://api.vrddhim.org", {
        transports: ["websocket", "polling"],
        upgrade: false,
        withCredentials: true,
      });

      // Setup Socket Event Listeners
      socketRef.current.on("message", (data) => {
        console.log("Socket connected:", data, socketRef.current.id);
      });
      socketRef.current.on("room_joined", handleJoin);
      socketRef.current.on("offer", handleOffer);
      socketRef.current.on("answer", handleAnswer);
      socketRef.current.on("ice_candidate", handleIceCandidate);
      socketRef.current.on("hand-raise", (data) => {
        console.log("Hand raised:", data);
        handleHandRaise(data);
      });
      socketRef.current.on("offer-status", offerStatus);
      socketRef.current.on("chat", (data) => handleOnChat(data));
      socketRef.current.on("left", (data) => leaveRoom(data));

      await joinClass();
      await getLocalStream();
      await createPeerConnection();
      await fetchParticipants(roomId);
    }
  };

  const handleJoin = async (data) => {
    console.log("Joined Class:", data);
  };

  const handleOnChat = async (data) => {
    console.log("Joined Class:", data);
    setMessages((prev) => [...prev, data]);
  };

  const leaveRoom = (data) => {
    navigate("/");
  };

  const offerStatus = async (data) => {
    console.log("Offer Status:", data);
    if (data.status === "Offer already created") {
      setIsOfferCreated(true);
    }
  };

  const createPeerConnection = async () => {
    if (peerConnectionRef.current) {
      console.log("Peer connection already exists.");
      return peerConnectionRef.current;
    }

    const peerConnection = new RTCPeerConnection(ICE_SERVERS);
    const localStream = localVideoRef.current?.srcObject;

    if (localStream) {
      localStream.getTracks().forEach((track) => {
        peerConnection.addTrack(track, localStream);
      });
    } else {
      console.error("Local stream is not available.");
      return;
    }

    peerConnection.ontrack = (event) => {
      const remoteStream = event.streams[0];

      if (event.track.kind === "video") {
        const videoElement = document.createElement("video");
        videoElement.srcObject = remoteStream;
        videoElement.autoplay = true;
        videoElement.playsInline = true;
        videoElement.className = "remote-video";

        const container = document.getElementById("remote-videos-container");
        container.appendChild(videoElement);
        remoteVideoRef.current.push(videoElement);
      }
      if (event.track.kind === "audio") {
        const audioElement = document.createElement("audio");
        audioElement.srcObject = remoteStream;
        audioElement.autoplay = true;

        const container = document.getElementById("remote-audios-container");
        container.appendChild(audioElement);
        remoteAudioRef.current.push(audioElement);
      }
    };

    peerConnection.onicecandidate = (event) => {
      if (event.candidate) {
        socketRef.current.emit("ice-candidate", {
          roomId: roomId,
          candidate: event.candidate,
          userid: userId,
          role: role,
          userName: userName,
        });
      }
    };

    peerConnection.oniceconnectionstatechange = () => {
      const state = peerConnection.iceConnectionState;
      console.log("ICE Connection State:", state);

      if (state === "connected") {
        console.log("WebRTC connection established successfully.");
      } else if (state === "disconnected") {
        console.log("Attempting to reconnect WebRTC connection...");
        peerConnection.restartIce();
      } else if (state === "failed") {
        console.error("WebRTC connection failed. Refresh or try again.");
        // Optional: You can add retry logic here or prompt the user to retry.
      }
    };

    // Create offer only if it’s the first time setting up the peer connection
    peerConnectionRef.current = peerConnection;
    if (!isOfferCreated) {
      await createOffer();
      setIsOfferCreated(true);
    }

    return peerConnection;
  };

  // useEffect(() => {
  //   if (!isOfferCreated && peerConnectionRef[userId]) {
  //     createOffer();
  //     setIsOfferCreated(true);
  //   }
  // }, [peerConnectionRef]);

  const handleOffer = async (data) => {
    const { offer } = data;

    try {
      if (!peerConnectionRef.current) {
        await createPeerConnection();
      }
      await peerConnectionRef.current.setRemoteDescription(new RTCSessionDescription(offer));
      const answer = await peerConnectionRef.current.createAnswer();
      await peerConnectionRef.current.setLocalDescription(answer);

      socketRef.current.emit("answer", {
        roomId: roomId,
        answer: answer,
        userid: userId,
        role: role,
        userName: userName,
      });
    } catch (err) {
      console.error("Error handling offer:", err);
    }
  };

  const createOffer = async () => {
    try {
      const offer = await peerConnectionRef.current.createOffer();
      await peerConnectionRef.current.setLocalDescription(offer);

      socketRef.current.emit("offer", {
        roomId: roomId,
        offer: offer,
        userid: userId,
        role: role,
        userName: userName,
      });
    } catch (error) {
      console.error("Error creating offer:", error);
    }
  };

  const handleAnswer = async (data) => {
    const { answer, userid } = data;

    try {
      if (!peerConnectionRef.current) {
        console.error("PeerConnection not established before receiving answer.");
        return;
      }
      if (peerConnectionRef.current.signalingState === "have-local-offer") {
        await peerConnectionRef.current.setRemoteDescription(new RTCSessionDescription(answer));
        console.log("Remote answer set successfully.");
      }
      // await peerConnectionRef.current.setRemoteDescription(new RTCSessionDescription(answer));
    } catch (err) {
      console.error("Error handling answer:", err);
    }
  };

  const handleIceCandidate = async (data) => {
    const { candidate, userid } = data;

    try {
      if (candidate) {
        await peerConnectionRef.current.addIceCandidate(new RTCIceCandidate(candidate));
      }
    } catch (err) {
      console.error("Error adding received ICE candidate", err);
    }
  };

  const handleHandRaise = (data) => {
    console.log("Received hand raise data:", data);
    const updatedParticipants = data.participant.map((participant) => {
      // if (
      //   (participant.role === "user" && participant.userid === Number(data.data.userid)) ||
      //   (participant.role === "mentor" && participant.mentor_id === Number(data.data.userid))
      // ) {
      //   return { ...participant, isHandRaised: data.data.raised };
      // }
      console.log(participant);
      return participant;
    });

    setParticipantsList(updatedParticipants);
  };

  const getLocalStream = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: {
          height: 350,
          width: 350,
        },
        userId: userId,
      });

      // Check if the stream is valid
      if (stream) {
        if (localVideoRef.current) {
          localVideoRef.current.srcObject = stream;
          manageAudioTracks(stream);

          if (peerConnectionRef.current) {
            stream.getTracks().forEach((track) => {
              peerConnectionRef.current.addTrack(track, stream);
            });
          }
          // Object.values(peerConnectionRef).forEach((peerConnection) => {
          //   stream.getTracks().forEach((track) => {
          //     peerConnection.addTrack(track, stream);
          //   });
          // });
        }
        setIsLoadingVideo(false);
      }
    } catch (err) {
      console.error("Error accessing media devices:", err);
      if (err.name === "NotAllowedError") {
        setErrorMessage("Permission denied: Please allow access to your camera and microphone.");
      } else if (err.name === "NotFoundError") {
        setErrorMessage("No media devices found: Please connect a camera or microphone.");
      } else {
        setErrorMessage("Failed to access media devices: " + err.message);
      }
    }
  };

  const joinClass = async () => {
    const payload = {
      room_id: roomId,
      userid: userId,
      join_time: new Date().toISOString(),
      role: role,
    };

    try {
      const response = await httpService.joinWebrtClass(JSON.stringify(payload));
      if (response) {
        socketRef.current.emit("join_class", { roomId: roomId, userid: userId, role: role, userName: userName });
        socketRef.current.emit("offer_status", { roomId: roomId, userid: userId, role: role, userName: userName });
      } else if (response?.error) {
        console.error("Error joining class:", response?.error);
        setErrorMessage("Failed to join class, please try again.");
      }
    } catch (error) {
      console.error("Error in joinClass:", error);
      setErrorMessage("An unexpected error occurred while joining the class.");
    }
  };

  const fetchParticipants = async (roomId) => {
    try {
      const response = await httpService.getParticipants(roomId);
      if (response) {
        setParticipantsList(response.participants);
      } else if (response?.error) {
        console.error("Error fetching participants:", response?.error);
        setErrorMessage("Failed to load participants, please try again.");
      }
    } catch (error) {
      console.error("Error in fetchParticipants:", error);
      setErrorMessage("An unexpected error occurred while fetching participants.");
    }
  };

  async function fetchProfile() {
    let response = await httpService.getUserDetails(userId, role);
    if (response) {
      setProfile(response);
      setUserName(role === "mentor" ? response.mentor_name : response.username);
    } else {
      console.log("Failed to fetch profile");
    }
  }

  const toggleAudio = () => {
    const localStream = localVideoRef.current?.srcObject;
    if (localStream) {
      const audioTracks = localStream.getAudioTracks();
      if (audioTracks.length > 0) {
        audioTracks.forEach((track) => {
          track.enabled = !track.enabled;
        });
        setIsAudioMuted((prev) => !prev);
        console.log("Audio Toggled:", !isAudioMuted);
      } else {
        console.error("No audio tracks found in the local stream.");
      }
    } else {
      console.error("No local stream found.");
    }
  };

  const manageAudioTracks = (stream) => {
    const audioTracks = stream.getAudioTracks();

    if (audioTracks.length > 0) {
      const audioTrack = audioTracks[0];
      console.log("Using audio track:", audioTrack);

      audioTrack.enabled = !isAudioMuted;
    } else {
      console.warn("No audio tracks found in the stream.");
    }
  };

  const toggleHandRaise = () => {
    const newHandState = !isHandRaised;
    setIsHandRaised(!isHandRaised);
    socketRef.current.emit("hand-raise", { raised: newHandState, roomId: roomId, userid: userId, userName: userName });
  };

  const toggleFullscreen = () => {
    const videoContainer = document.querySelector(`.${"videoContainer"}`);
    if (videoContainer) {
      if (!document.fullscreenElement) {
        videoContainer.requestFullscreen().catch((err) => {
          console.error("Error attempting to enable fullscreen mode:", err);
        });
      } else {
        document.exitFullscreen();
      }
    }
  };

  const handleSendMessages = (event, msg) => {
    socketRef.current.emit("chat", { message: msg, roomId: roomId, userid: userId, userName: userName });
    setCurrentMessages("");
  };

  const saveNotes = () => {
    if (notes.trim()) {
      alert("Notes saved!");
    } else {
      alert("Please write some notes before saving.");
    }
  };

  function getUserNameForStream(stream) {
    // const userId = mapStreamToUserId(stream);
    // return userNames[userId] || "";
    return "";
  }

  function leaveClass() {
    socketRef.current.emit("leave_class", { roomId: roomId, userid: userId, role: role, userName: userName });
  }

  function toggleVideo() {
    const localStream = localVideoRef.current?.srcObject;

    if (localStream) {
      const videoTracks = localStream.getVideoTracks();

      if (videoTracks.length > 0) {
        videoTracks.forEach((track) => {
          if (track.enabled) {
            track.enabled = false;
            track.stop();
          } else {
            getLocalStream();
          }
        });

        setIsVideoMuted((prev) => !prev);
        console.log("Video Toggled:", !isVideoMuted);
      } else {
        console.error("No video tracks found in the local stream.");
      }
    } else {
      console.error("No local stream found.");
    }
  }

  function showParticipantsList() {}

  return (
    <div className="mainContent">
      <div className="row">
        <div className="col-lg-9">
          <div className="videoContainer">
            {isLoadingVideo && (
              <div className="loadingSpinner">
                <FontAwesomeIcon icon={faSpinner} spin size="3x" />
                <p>Loading video...</p>
              </div>
            )}
            {/* <div className="container">
              <div className="row"> */}
            {/* <div className="col-6 mb-3">
                  <video ref={localVideoRef} className="w-100 localVideo" autoPlay playsInline muted></video>
                </div> */}
            {/* <div className="col-12 d-flex justify-content-cente r flex-wrap remoteVideos"> */}
            {/* {remoteVideoRef.map((ref, index) => (
                    <div key={index} className="remote-video-container">
                      <video ref={ref} className="remote-video w-100" autoPlay playsInline></video>
                    </div>
                  ))} */}
            <video ref={localVideoRef} className="remoteVideosContainer" autoPlay playsInline muted></video>

            <div className="remoteVideosContainer" id="remote-videos-container"></div>
            <div className="remoteVideosContainer" id="remote-audios-container"></div>
            {/* </div> */}
            {/* </div> */}
            {/* </div> */}
            <div className="videoControls">
              <FontAwesomeIcon
                icon={isAudioMuted ? faMicrophoneSlash : faMicrophone}
                className={`${"controlIcon"} ${isAudioMuted ? "disabled" : ""}`}
                onClick={toggleAudio}
                title={isAudioMuted ? "Unmute" : "Mute"}
                style={{ cursor: "pointer" }}
              />

              <FontAwesomeIcon
                icon={isVideoMuted ? faVideoSlash : faVideo}
                className={`${"controlIcon"} ${isVideoMuted ? "disabled" : ""}`}
                onClick={toggleVideo}
                title={isVideoMuted ? "Turn On Video" : "Turn Off Video"}
                style={{ cursor: "pointer" }}
              />

              <FontAwesomeIcon icon={faExpand} className="controlIcon" onClick={toggleFullscreen} title="Fullscreen" style={{ cursor: "pointer" }} />

              <FontAwesomeIcon
                icon={faUsers}
                className="controlIcon"
                onClick={showParticipantsList}
                title="Participants"
                style={{ cursor: "pointer" }}
              />

              <FontAwesomeIcon
                icon={isHandRaised ? faHandPaper : faHand}
                className={`${"controlIcon"} ${!isHandRaised ? "disabled" : ""}`}
                onClick={toggleHandRaise}
                title={isHandRaised ? "Lower Hand" : "Raise Hand"}
                style={{ cursor: "pointer" }}
              />

              <FontAwesomeIcon
                icon={faSignOutAlt}
                className="controlIcon"
                onClick={leaveClass}
                title="Leave Class"
                style={{ cursor: "pointer", color: "red" }}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="tabBar m-2">
            {["overview", "Chat Room"].map((key) => (
              <button key={key} className={`${"tabButton bttn-primary"} ${activeTab === key ? "active" : ""}`} onClick={() => setActiveTab(key)}>
                {key.charAt(0).toUpperCase() + key.slice(1)}
              </button>
            ))}
          </div>

          <div className="container tabContent">
            {activeTab === "overview" && (
              <div>
                <div className="topicsCard">
                  <h5>Live Class Topics</h5>
                  <ul>
                    {["Introduction to English", "Advanced Patterns"].map((topic, index) => (
                      <li key={index}>
                        <FontAwesomeIcon icon={faArrowRight} className="arrowIcon" /> {topic}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
            {activeTab === "Chat Room" && (
              <div>
                {messages.map((item, index) => (
                  <div className={`d-flex ${userId === item.userid ? "justify-content-start" : "justify-content-end"} `}>
                    <div className="p-2">
                      <div key={index} className="fw-bold fc-green ">
                        {item.userName}
                      </div>
                      <div key={index} className={`${userId === item.userid ? "float-start px-2" : "float-end px-2"}`}>
                        {item.message}
                      </div>
                    </div>
                  </div>
                ))}
                <Form.Control
                  type="text"
                  placeholder="Type your message..."
                  value={currentMessage}
                  onChange={(e) => setCurrentMessages(e.target.value)}
                />
                <Button className="mt-2 btn bttn-primary" onClick={(event) => handleSendMessages(event, currentMessage)} disabled={!currentMessage}>
                  Send
                </Button>
              </div>
            )}
          </div>

          <div className="chatContainer">
            <ChatBox />
          </div>
        </div>
      </div>

      <div className="participantsList p-3 pt-5">
        <h5 className="fw-bold fc-green">Participants:</h5>
        <ul className="p-2">
          {participantsList.map((participant, index) => (
            <li key={index}>
              {participant.role === "user" ? "Student" : "Mentor"} : {participant.username}
              {participant.isHandRaised && <span> 👋</span>}
            </li>
          ))}
        </ul>
      </div>
      {errorMessage && (
        <Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>
          {errorMessage}
        </Alert>
      )}
    </div>
  );
};

export default LiveClass;
