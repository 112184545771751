import React, { useEffect, useState } from "react";
import { FaEnvelope } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";

const MailVerification = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const email = new URLSearchParams(location.search).get("email");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!email) {
      console.error("No email provided for verification");
      return;
    }

    const timer = setTimeout(() => {
      navigate("/login");
    }, 3000);

    fetch(`https://api.vrddhim.org/register/success/${email}`, {
      method: "GET",
      cache: "no-cache",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          console.error("Server returned status:", response.status);
        }
      })
      .then((data) => {
        if (data) {
          console.log("Data fetched:", data);
        }
      })
      .catch((error) => console.error("Error:", error))
      .finally(() => setLoading(false));

    return () => clearTimeout(timer);
  }, [email, navigate]);

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className="card shadow-lg p-4" style={{ maxWidth: "500px", width: "100%" }}>
        <div className="card-body text-center">
          <div className="mb-3">
            <FaEnvelope className="text-primary" style={{ fontSize: "3rem" }} />
          </div>
          <h4 className="card-title mb-3">Verification Email Sent</h4>
          {email && (
            <p className="card-text">
              We've sent a verification email to <strong>{email}</strong>. Please check your inbox and click the verification link.
            </p>
          )}
          {loading ? (
            <div>
              <p>Loading...</p>
              <p>You will be redirected to the login page in 3 seconds.</p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default MailVerification;
