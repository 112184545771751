import React, { useState, useEffect, useContext } from "react";
import { Modal, Button, Input, Form, message } from "antd";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { HttpServiceContext } from "../../config/httpContext";
import styles from "../../styles/pages/mentors-management.module.scss";
import Swal from "sweetalert2";

import axios from "axios";

const AdminMentors = () => {
  const httpService = useContext(HttpServiceContext);
  const [mentorsData, setMentorsData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [mentorEmail, setMentorEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [resendAvailable, setResendAvailable] = useState(false);
  const [timer, setTimer] = useState(60);

  useEffect(() => {
    getMentorsData();
  }, []);

  async function getMentorsData() {
    let response = await httpService.getMentorsData();
    if (response) {
      setMentorsData(response);
    }
  }

  const handleSendEmail = async () => {
    if (!mentorEmail) {
      message.error("Please enter an email address.");
      return;
    }

    try {
      await httpService.sendMentorEmail({ mentor_email: mentorEmail });
      setEmailSent(true);
      setResendAvailable(true);
      setTimer(60);

      Swal.fire({
        icon: "success",
        title: "Invitation Sent!",
        text: "An invite email has been sent to the mentor successfully.",
        confirmButtonText: "Ok",
      });

      const countdown = setInterval(() => {
        setTimer((prev) => {
          if (prev === 1) {
            clearInterval(countdown);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } catch (error) {
      console.error("Error sending email:", error);
      Swal.fire({
        icon: "error",
        title: "Failed to Send Email",
        text: "There was an error sending the email. Please try again.",
        confirmButtonText: "Ok",
      });
    }
  };

  const handleResendEmail = () => {
    if (resendAvailable) {
      handleSendEmail();
    }
  };

  const filteredMentors = mentorsData?.length
    ? mentorsData.filter((mentor) => mentor?.mentor_name?.toLowerCase().includes(searchTerm.toLowerCase()))
    : [];

  const openModal = () => {
    setModalVisible(true);
    setMentorEmail("");
  };

  const onFinish = () => {
    handleSendEmail();
    setModalVisible(false);
  };

  const barOptions = {
    chart: {
      type: "bar",
      height: 300,
    },
    title: {
      text: "Mentor Completed Sessions",
    },
    xAxis: {
      categories: filteredMentors.map((mentor) => mentor.mentor_name),
    },
    yAxis: {
      title: {
        text: "Completed Sessions",
      },
    },
    series: [
      {
        name: "Completed Sessions",
        data: filteredMentors.map((mentor) => mentor.completed_session),
        color: "#007bff",
      },
    ],
  };

  const lineOptions = {
    chart: {
      type: "line",
      height: 300,
    },
    title: {
      text: "Mentor Ratings",
    },
    xAxis: {
      categories: filteredMentors.map((mentor) => mentor.mentor_name),
    },
    yAxis: {
      title: {
        text: "Rating",
      },
    },
    series: [
      {
        name: "Average Rating",
        data: filteredMentors.map((mentor) => mentor.rating),
        color: "#ff7f0e",
        lineWidth: 2,
      },
    ],
  };

  return (
    <div className={styles.adminMentors}>
      <h2 className="eloquent-title">Mentors Management Dashboard</h2>

      <div className={styles.mentorsHeader}>
        <Input placeholder="Search by Name" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} className={styles.searchBar} />
        <Button className="bttn-primary" onClick={openModal}>
          Add Mentor
        </Button>
      </div>

      <div className={styles.tableContainer}>
        <table className={styles.mentorsTable}>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Registration Date</th>
              <th>Completed Sessions</th>
            </tr>
          </thead>
          <tbody>
            {filteredMentors.map((mentor) => (
              <tr key={mentor.mentor_id}>
                <td>{mentor.mentor_id}</td>
                <td>{mentor.mentor_name}</td>
                <td>{mentor.mentor_email}</td>
                <td>{mentor.phone_number}</td>
                <td>{mentor.registration_date}</td>
                <td>{mentor.completed_session}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className={styles.emailContainer}>
        {emailSent && (
          <div className={styles.successMessage}>
            Email has been sent successfully.
            {resendAvailable && (
              <span>
                <Button type="link" onClick={handleResendEmail} disabled={timer > 0}>
                  Resend {timer > 0 ? `(${timer})` : ""}
                </Button>
              </span>
            )}
          </div>
        )}
      </div>

      <div className={styles.chartsContainer}>
        <div className={styles.chart}>
          <HighchartsReact highcharts={Highcharts} options={barOptions} />
        </div>
        <div className={styles.chart}>
          <HighchartsReact highcharts={Highcharts} options={lineOptions} />
        </div>
      </div>

      {/* Modal for Add Mentor */}
      <Modal title="Add Mentor" open={modalVisible} onCancel={() => setModalVisible(false)} footer={null}>
        <Form layout="vertical" onFinish={onFinish}>
          <Form.Item label="Email" name="mentor_email" rules={[{ required: true, message: "Please input the mentor's email!" }]}>
            <Input value={mentorEmail} onChange={(e) => setMentorEmail(e.target.value)} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AdminMentors;
