import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import isoWeek from "dayjs/plugin/isoWeek";
import IconifyIcon from "../iconifyIcon";
import "../../styles/pages/calendar.scss";

dayjs.extend(localizedFormat);
dayjs.extend(isoWeek);

const daysOfWeekLetters = ["S", "M", "T", "W", "T", "F", "S"];

const WeekCalendar = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [openModal, setOpenModal] = useState(false);
  const [events, setEvents] = useState([
    {
      date: dayjs().startOf("week").add(1, "day").toDate(),
      title: "Math Class",
      mentor: "John Doe",
      students: ["Alice", "Bob"],
      subjects: ["Algebra", "Geometry"],
      image: "https://via.placeholder.com/150", // Dummy image
    },
    {
      date: dayjs().startOf("week").add(2, "day").toDate(),
      title: "Science Workshop",
      mentor: "Jane Smith",
      students: ["Carol", "David"],
      subjects: ["Biology", "Chemistry"],
      image: "https://via.placeholder.com/150", // Dummy image
    },
    {
      date: dayjs().startOf("week").add(3, "day").toDate(),
      title: "History Lecture",
      mentor: "Mike Johnson",
      students: ["Eve", "Frank"],
      subjects: ["Ancient History", "Medieval History"],
      image: "https://via.placeholder.com/150", // Dummy image
    },
  ]);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [viewDetail, setViewDetail] = useState(false);

  const startOfCurrentWeek = dayjs(selectedDate).startOf("week");
  const daysOfWeek = Array.from(new Array(7)).map((_, index) => startOfCurrentWeek.add(index, "day"));

  const handlePrevMonth = () => {
    const newDate = dayjs(selectedDate).subtract(1, "month");
    setSelectedDate(newDate.toDate());
  };

  const handleNextMonth = () => {
    const newDate = dayjs(selectedDate).add(1, "month");
    setSelectedDate(newDate.toDate());
  };

  const handleDateClick = (date) => {
    setSelectedDate(date.toDate());
    const eventsForDate = events.filter((event) => dayjs(event.date).isSame(date, "day"));
    setSelectedEvents(eventsForDate);
    setViewDetail(false);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleViewDetail = () => {
    setViewDetail(true);
  };

  return (
    <Paper className="calendar-container">
      <Stack direction="row" alignItems="center" justifyContent="space-between" px={2} py={1}>
        <IconButton onClick={handlePrevMonth}>
          <IconifyIcon icon="oui:arrow-left" width={24} height={24} />
        </IconButton>
        <Typography variant="h6" fontWeight="bold">
          {dayjs(selectedDate).format("MMMM YYYY")}
        </Typography>
        <IconButton onClick={handleNextMonth}>
          <IconifyIcon icon="oui:arrow-right" width={24} height={24} />
        </IconButton>
      </Stack>

      <Stack direction="row" justifyContent="space-between" px={2} py={1}>
        {daysOfWeekLetters.map((letter, index) => (
          <Typography key={index} variant="subtitle1" className="day-letter">
            {letter}
          </Typography>
        ))}
      </Stack>

      <Stack direction="row" justifyContent="space-between" px={2} py={1}>
        {daysOfWeek.map((day, index) => {
          const isToday = day.isSame(new Date(), "day");
          return (
            <Box key={index} className={`date-box ${isToday ? "today" : ""}`} onClick={() => handleDateClick(day)}>
              <Typography variant="body1">{day.format("D")}</Typography>
            </Box>
          );
        })}
      </Stack>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box className="modal-style">
          <Typography variant="h6" mb={2}>
            Classes for {dayjs(selectedDate).format("MMMM D, YYYY")}
          </Typography>
          {!viewDetail ? (
            <>
              {selectedEvents.length > 0 ? (
                <>
                  <Typography variant="body1" mb={2}>
                    {selectedEvents.length} class{selectedEvents.length > 1 ? "s" : ""} scheduled.
                  </Typography>
                  <Button variant="contained" color="primary" onClick={handleViewDetail}>
                    Know More
                  </Button>
                </>
              ) : (
                <Typography variant="body1">No classes scheduled for this day.</Typography>
              )}
            </>
          ) : (
            <Box>
              {selectedEvents.map((event, index) => (
                <Box key={index} mb={2} className="event-detail">
                  <img src={event.image} alt={event.title} className="event-image" />
                  <Typography variant="h6">{event.title}</Typography>
                  <Typography variant="body2">Mentor: {event.mentor}</Typography>
                  <Typography variant="body2">Subjects: {event.subjects.join(", ")}</Typography>
                  <Typography variant="body2">Students: {event.students.join(", ")}</Typography>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Modal>
    </Paper>
  );
};

export default WeekCalendar;
