import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "../../src/styles/pages/calendarpage.scss"; // Custom CSS for theming
import Navbar from "./navbar";
import Footer from "./home/vrddhim-footer";

const CalendarPage = () => {
  const [value, setValue] = useState(new Date());

  // Example of scheduled classes
  const scheduledClasses = [
    new Date(2024, 9, 16), // Oct 16, 2024
    new Date(2024, 9, 17), // Oct 22, 2024
    new Date(2024, 9, 28), // Oct 28, 2024
  ];

  // Function to check if a date has a scheduled class
  const isClassScheduled = (date) => {
    return scheduledClasses.some(
      (scheduledDate) =>
        scheduledDate.getFullYear() === date.getFullYear() &&
        scheduledDate.getMonth() === date.getMonth() &&
        scheduledDate.getDate() === date.getDate()
    );
  };

  return (
    <>
      <Navbar />
      <h2 className="elegant-heading text-center">Vrddhim Calender</h2>
      <p className="gracefull-paragraph text-center">In this calendar, we are showing the scheduled classes for the courses you have purchased.</p>
      <div className="calendar-container">
        <Calendar
          onChange={setValue}
          value={value}
          tileClassName={({ date, view }) => (view === "month" && isClassScheduled(date) ? "highlight" : null)}
        />
      </div>
      <Footer />
    </>
  );
};

export default CalendarPage;
