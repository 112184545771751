import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import IconifyIcon from "../iconifyIcon";
import TaskOverviewTable from "./course-overview-table";
import styles from "../../styles/pages/course-overview.module.scss";

const TaskOverview = () => {
  const [searchText, setSearchText] = useState("");

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <Stack direction="column" spacing={1} width={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" className={styles.searchContainer}>
        <Typography variant="h4" className={styles.upcomingTasksHeading}></Typography>
        <TextField
          variant="outlined"
          size="small"
          placeholder="Search Task"
          value={searchText}
          onChange={handleInputChange}
          className={styles.searchBar}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconifyIcon icon={"mynaui:search"} />
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Paper className={styles.tableContainer}>
        <TaskOverviewTable searchText={searchText} />
      </Paper>
    </Stack>
  );
};

export default TaskOverview;
