import React, { useContext, useEffect, useState } from "react";
import "./MentorRegistration.scss";
import { HttpServiceContext } from "../../config/httpContext";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";

const MentorRegistration = () => {
  const [formData, setFormData] = useState({
    mentor_email: "",
    mentor_name: "",
    mentor_password: "",
    confirm_password: "",
    phone_number: "",
  });
  const [error, setError] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const httpService = useContext(HttpServiceContext);
  const [showSuccessModel, setShowSuccessModel] = useState(false);

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const mentorEmail = params.get("mentor_email");

      if (mentorEmail) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          mentor_email: mentorEmail,
        }));
        console.log("Mentor Email:", mentorEmail);
      } else {
        console.log("mentor_email not found in the query string.");
      }
    }
  }, [location.search]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let response = await httpService.mentorRegistrationSubmit(formData, "mentor");
    if (response?.message) {
      // setShowSuccessModel(true);
      Swal.fire({
        title: "Success!",
        text: "Mentor Registration Successfull...",
        icon: "success",
        timer: 2000,
        showConfirmButton: false,
        willClose: () => {
          navigate(`/mentor/login`);
        },
      });
    } else if (response?.data?.error) {
      setError(response?.data?.error);
    } else {
      setError(response?.message || "An error occurred. Please try again.");
    }
  };

  function handleModalClose() {
    setShowSuccessModel(false);
  }

  return (
    <div>
      <div className="mentor-registration-container vh-100">
        <form onSubmit={handleSubmit} className="mentor-registration-form">
          <h2>Mentor Registration</h2>

          <label>Email</label>
          <input type="email" name="mentor_email" value={formData.mentor_email} onChange={handleChange} placeholder="Enter your email" disabled />

          <label>Name</label>
          <input type="text" name="mentor_name" value={formData.mentor_name} onChange={handleChange} placeholder="Enter your full name" required />

          <label>Phone Number</label>
          <input
            type="tel"
            name="phone_number"
            value={formData.phone_number}
            onChange={handleChange}
            placeholder="Enter your phone number"
            required
          />

          <label>Password</label>
          <input
            type="password"
            name="mentor_password"
            value={formData.mentor_password}
            onChange={handleChange}
            placeholder="Enter your password"
            required
          />

          <label>Confirm Password</label>
          <input
            type="password"
            name="confirm_password"
            value={formData.confirm_password}
            onChange={handleChange}
            placeholder="Confirm your password"
            required
          />
          {error && <div className="text-danger float-end mb-2">{error}</div>}

          <button type="submit" className="bttn-primary ">
            Register
          </button>
        </form>
      </div>
      <Modal show={showSuccessModel} onHide={handleModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="background-primary">
          <div className="text-center">Successfully registered as mentor</div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn bttn-primary" onClick={() => navigate("/mentor/login")}>
            Login
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MentorRegistration;
