// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chatbox-home-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}
.chatbox-home-container .chatbox-toggle-button {
  background-color: rgb(113, 234, 113);
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.3s;
}
.chatbox-home-container .chatbox-toggle-button:hover {
  background-color: #4cae4c;
  transform: scale(1.1);
}`, "",{"version":3,"sources":["webpack://./src/components/home/ChatComponent.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;AACF;AACE;EACE,oCAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,yCAAA;EACA,iDAAA;AACJ;AACI;EACE,yBAAA;EACA,qBAAA;AACN","sourcesContent":[".chatbox-home-container {\n  position: fixed;\n  bottom: 20px;\n  right: 20px;\n  z-index: 1000;\n\n  .chatbox-toggle-button {\n    background-color: rgb(113, 234, 113);\n    color: white;\n    border: none;\n    border-radius: 50%;\n    width: 60px;\n    height: 60px;\n    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);\n    transition: background-color 0.3s, transform 0.3s;\n\n    &:hover {\n      background-color: #4cae4c;\n      transform: scale(1.1);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
