import React from "react";
import OwlCarousel from "react-owl-carousel";
import "../../styles/pages/whychoose.scss"; // Link the SCSS file

const services = [
  {
    imgSrc: "service-icon-01.png",
    title: "Personalized Learning",
    description: "Tailored courses to fit your unique needs, ensuring you achieve your specific language goals.",
  },
  {
    imgSrc: "service-icon-02.png",
    title: "Practical Application",
    description: "Hands-on practice with real-world scenarios to build practical communication skills.",
  },
  {
    imgSrc: "service-icon-03.png",
    title: "Expert Guidance",
    description: "Learn from industry veterans with over 20 years of experience in communication and language training",
  },
  {
    imgSrc: "service-icon-02.png",
    title: "Holistic Development",
    description: "Foster growth in language skills and personal confidence, Preparing you for every interaction.",
  },
  {
    imgSrc: "service-icon-03.png",
    title: "Innovative Methods",
    description: "Utilize the latest teaching strategies to make learning engaging, effective, and enjoyable",
  },
];

const WhyChoosevrddhim = () => {
  return (
    <>
      <section className="services " style={{ backgroundColor: "#ffff" }}>
        <div className="container">
          <h2 className="elegant-heading text-center ">Why Choose us? </h2>
          <div className="row">
            <div className="col-lg-12">
              <OwlCarousel
                className="owl-theme"
                loop
                margin={20}
                nav
                autoplay
                dots={false}
                responsive={{
                  0: {
                    items: 1,
                  },
                  768: {
                    items: 2,
                  },
                  1000: {
                    items: 3,
                  },
                }}
              >
                {services.map((service, index) => (
                  <div className="item" key={index}>
                    <div className="service-card">
                      <div className="icon">
                        <img src={service.imgSrc} alt={service.title} />
                      </div>
                      <div className="down-content">
                        <h4>{service.title}</h4>
                        <p>{service.description}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhyChoosevrddhim;
