import React from "react";
import { Container, ListGroup, ListGroupItem } from "react-bootstrap";
import "../../styles/pages/admin-notification.scss";

const notifications = [
  { id: 1, message: "System update completed successfully." },
  { id: 2, message: "New user registration: John Doe." },
  { id: 3, message: "Scheduled maintenance on 30th August." },
];

const Notification = () => {
  return (
    <Container className="notification-container">
      <h2 className="notifications-title">Notifications</h2>
      <ListGroup>
        {notifications.map((notification) => (
          <ListGroupItem key={notification.id} className="notification-item">
            {notification.message}
          </ListGroupItem>
        ))}
      </ListGroup>
    </Container>
  );
};

export default Notification;
