import React, { useState } from "react";
import Navbar from "./navbar";
import Footer from "./home/vrddhim-footer";

const CookieSetting = () => {
  window.scrollTo(0, 0);

  const [preferences, setPreferences] = useState({
    necessary: true,
    analytics: false,
    marketing: false,
  });

  const handleToggle = (cookieType) => {
    setPreferences((prev) => ({
      ...prev,
      [cookieType]: !prev[cookieType],
    }));
  };

  const savePreferences = () => {
    // Logic to save preferences
    alert("Cookie preferences have been saved.");
  };

  return (
    <section className="cookie-settings" style={{ backgroundColor: "#f9f9f9" }}>
      <Navbar />
      <div className="container">
        <div className="container my-4">
          <h2 className="elegant-heading text-center">Cookie Settings</h2>

          <div className="card shadow-lg mb-4">
            <div className="card-body">
              <h4 className="text-secondary mb-3">
                <i className="bi bi-cookie"></i> Manage Your Cookie Preferences
              </h4>
              <p className="graceful-paragraph text-dark">
                We use cookies to enhance your experience on the platform. You can manage your preferences here, and we will respect your choices.
                Necessary cookies cannot be disabled as they are essential for the proper functioning of the platform.
              </p>
            </div>
          </div>

          <div className="card shadow-lg mb-4">
            <div className="card-body">
              <h4 className="text-secondary mb-3">
                <i className="bi bi-shield-lock-fill"></i> Types of Cookies
              </h4>
              <ul className="list-group mb-4">
                <li className="list-group-item">
                  <strong className="badge bg-primary me-2">1</strong>
                  <strong>Necessary Cookies:</strong> These cookies are essential for the website to function properly. They cannot be disabled.
                </li>
                <li className="list-group-item">
                  <strong className="badge bg-success me-2">2</strong>
                  <strong>Analytics Cookies:</strong> These cookies help us analyze website traffic and improve the user experience.
                  <div className="form-check form-switch mt-2">
                    <input className="form-check-input" type="checkbox" checked={preferences.analytics} onChange={() => handleToggle("analytics")} />
                    <label className="form-check-label">Enable Analytics Cookies</label>
                  </div>
                </li>
                <li className="list-group-item">
                  <strong className="badge bg-warning me-2">3</strong>
                  <strong>Marketing Cookies:</strong> These cookies are used to show relevant ads and marketing messages to users.
                  <div className="form-check form-switch mt-2">
                    <input className="form-check-input" type="checkbox" checked={preferences.marketing} onChange={() => handleToggle("marketing")} />
                    <label className="form-check-label">Enable Marketing Cookies</label>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="text-center">
            <button className="btn bttn-primary" onClick={savePreferences} style={{ marginBottom: "20px" }}>
              Save Preferences
            </button>
          </div>

          <div className="text-center">
            <p>
              <i className="bi bi-info-circle"></i> For more information, please refer to our{" "}
              <a href="/privacypolicy" className="text-primary">
                Privacy Policy
              </a>
              .
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default CookieSetting;
