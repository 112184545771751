import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { HttpServiceProvider } from "./config/httpContext";
import "bootstrap-icons/font/bootstrap-icons.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <HttpServiceProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </HttpServiceProvider>
  // </React.StrictMode> // should be there for productino
);
