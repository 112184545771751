import React, { useEffect, useState, useContext, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { Dropdown, Modal } from "react-bootstrap";
import logo from "../assets/images/vrddhim-logo.png";
import { HttpServiceContext } from "../config/httpContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faBell, faBars, faComment, faCog, faQuestionCircle, faCalendar } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import "../../src/styles/pages/navbar.scss";

function Navbar() {
  const [scrolled, setScrolled] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const location = useLocation();
  const httpService = useContext(HttpServiceContext);
  const label = httpService.loginedType;
  const notificationRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (notificationRef.current && !notificationRef.current.contains(e.target)) {
        console.log("Clicked outside");
        setShowNotifications(false);
      } else {
        console.log("Clicked inside");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    checkLoginStatus();
    generateDummyNotifications();
  }, []);

  const checkLoginStatus = async () => {
    let response = await httpService.checkIsLogined();
    if (response?.data.user_id) {
      httpService.setLoginedUserId(response.data.user_id);
      document.cookie = `userid=${response.data.user_id}; path=/; SameSite=Strict`;
      setIsLoggedIn(true);
    } else {
      console.error("Error checking login status:", response?.data.error);
    }
  };

  const generateDummyNotifications = () => {
    const dummyData = [
      { id: 1, message: "Your class has been scheduled for tomorrow.", time: "10 minutes ago" },
      { id: 2, message: "You have a new message from a student.", time: "1 hour ago" },
      { id: 3, message: "New updates are available for your courses.", time: "3 hours ago" },
      { id: 4, message: "Reminder: Your assignment is due soon.", time: "2 days ago" },
      { id: 5, message: "Your profile has been updated successfully.", time: "1 week ago" },
    ];
    setNotifications(dummyData);
  };

  const getLinkClass = (path) => (location.pathname === path ? "nav-link active" : "nav-link");

  const handleOutsideClick = (e) => {
    if (e.target.className.includes("notification-modal-overlay")) {
      setShowNotifications(false);
    }
  };

  const showComingSoonAlert = () => {
    Swal.fire({
      title: "Coming Soon!",
      text: "This feature will be available soon.",
      icon: "info",
      confirmButtonText: "OK",
    });
  };

  const toggleNotificationModal = () => {
    setShowNotifications(!showNotifications);
  };

  return (
    <>
      <nav className={`navbar navbar-expand-lg navbar-light fixed-top ${scrolled ? "scrolled" : ""}`}>
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={logo} alt="Brand Logo" className="navbar-brand-logo" />
          </Link>

          <button
            className="custom-toggler d-lg-none"
            type="button"
            onClick={() => setMenuOpen(!menuOpen)}
            aria-controls="responsive-navbar-menu"
            aria-label="Toggle navigation"
          >
            <FontAwesomeIcon icon={faBars} className="toggler-icon" />
          </button>

          {isLoggedIn && (
            <div className="custom-icon-container d-lg-none">
              <Link className="custom-notification-icon" aria-label="Notifications" onClick={toggleNotificationModal}>
                <FontAwesomeIcon icon={faBell} className="notification-icon" />
              </Link>
              <Link to="/profile" className="custom-profile-icon" aria-label="Profile">
                <FontAwesomeIcon icon={faUser} className="profile-icon" />
              </Link>
            </div>
          )}

          <div className={`mobile-dropdown d-lg-none ${menuOpen ? "open" : ""}`}>
            <ul className="navbar-nav justify-content-center align-items-center">
              {["/", "/about", "/blog", "/courses", "/contactus"].map((path, index) => (
                <li className="nav-item my-2" key={index}>
                  <Link className={getLinkClass(path)} to={path} onClick={() => setMenuOpen(false)}>
                    {path === "/" ? "HOME" : path.toUpperCase().slice(1)}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div className="collapse navbar-collapse d-none d-lg-block">
            <ul className="navbar-nav ms-auto align-items-center">
              {["/", "/about", "/blog", "/courses", "/contactus"].map((path, index) => (
                <li className="nav-item mx-2" key={index}>
                  <Link className={getLinkClass(path)} to={path} aria-current={path === "/" ? "page" : undefined}>
                    {path === "/" ? "HOME" : path.toUpperCase().slice(1)}
                  </Link>
                </li>
              ))}

              {isLoggedIn && (
                <>
                  <li className="nav-item mx-2">
                    <Link className="nav-link d-none d-lg-block" aria-label="Notifications" onClick={toggleNotificationModal}>
                      NOTIFICATIONS
                    </Link>
                    <Link className="nav-link d-lg-none" aria-label="Notifications" onClick={toggleNotificationModal}>
                      <FontAwesomeIcon icon={faBell} />
                    </Link>
                  </li>
                  <li className="nav-item dropdown mx-2 d-none d-lg-block">
                    <Dropdown>
                      <Dropdown.Toggle as="span" className="home-more" style={{ cursor: "pointer", color: "#05aa25", textDecoration: "none" }}>
                        MORE
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item as={Link} to="/profile">
                          <FontAwesomeIcon icon={faUser} /> Profile
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to="/calendar">
                          <FontAwesomeIcon icon={faCalendar} /> Calendar
                        </Dropdown.Item>
                        <div style={{ borderBottom: "1px solid #ccc", margin: "0.5rem 0" }} />
                        <Dropdown.Item as={Link} to="/helpandsupport">
                          <FontAwesomeIcon icon={faQuestionCircle} /> Help
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                </>
              )}
              {!isLoggedIn && (
                <li className="nav-item mx-2">
                  <Link className={getLinkClass("/login")} to={"/login"} aria-current={undefined}>
                    LOGIN
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>

      {showNotifications && (
        <>
          <div className="notification-modal-overlay" onClick={() => setShowNotifications(false)}></div>
          <div className="notification-dropdown" ref={notificationRef}>
            <div className="notification-body">
              {notifications.length > 0 ? (
                notifications.map((notification) => (
                  <div key={notification.id} className="notification-item">
                    <FontAwesomeIcon icon={faComment} className="notification-icon" />
                    <div className="notification-content">
                      <div className="notification-message">{notification.message}</div>
                      <div className="notification-time text-muted">{notification.time}</div>
                    </div>
                  </div>
                ))
              ) : (
                <div>No new notifications</div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Navbar;

///see here for the integrated notification

// import React, { useEffect, useState, useContext } from "react";
// import { Link, useLocation } from "react-router-dom";
// import { Dropdown, Modal } from "react-bootstrap";
// import logo from "../assets/images/vrddhim-logo.png";
// import { HttpServiceContext } from "../config/httpContext";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faUser, faEnvelope, faCog, faQuestionCircle, faBell, faBars, faComment } from "@fortawesome/free-solid-svg-icons";
// import Swal from "sweetalert2";
// import "../../src/styles/pages/navbar.scss";
// import io from "socket.io-client";

// const SOCKET_URL = "https://api.vrddhim.org";

// function Navbar() {
//   const [scrolled, setScrolled] = useState(false);
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const [menuOpen, setMenuOpen] = useState(false);
//   const [showNotifications, setShowNotifications] = useState(false);
//   const [notifications, setNotifications] = useState([]);
//   const location = useLocation();
//   const httpService = useContext(HttpServiceContext);
//   const label = httpService.loginedType;

//   useEffect(() => {
//     const handleScroll = () => {
//       setScrolled(window.scrollY > 50);
//     };

//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   useEffect(() => {
//     checkLoginStatus();
//     initializeSocket();
//   }, []);

//   const checkLoginStatus = async () => {
//     let response = await httpService.checkIsLogined();
//     if (response?.data.user_id) {
//       httpService.setLoginedUserId(response.data.user_id);
//       document.cookie = `userid=${response.data.user_id}; path=/; SameSite=Strict`;
//       setIsLoggedIn(true);
//     } else {
//       console.error("Error checking login status:", response?.data.error);
//     }
//   };

//   const initializeSocket = () => {
//     const socket = io(SOCKET_URL);

//     socket.on("payment_completed", (data) => {
//       setNotifications((prevNotifications) => [...prevNotifications, data]);
//     });

//     socket.on("class_reminder", (data) => {
//       setNotifications((prevNotifications) => [...prevNotifications, data]);
//     });

//     socket.on("connect", () => {
//       console.log("Socket connected:", socket.id);
//     });

//     socket.on("disconnect", () => {
//       console.log("Socket disconnected");
//     });
//     ///for testing purpopse ->>>

//     socket.on("test_notification", (data) => {
//       console.log("Test notification received:", data);
//     });

//     socket.on("notification", (notification) => {
//       setNotifications((prevNotifications) => [...prevNotifications, notification]);
//     });

//     return () => {
//       socket.disconnect();
//     };
//   };

//   const getLinkClass = (path) => (location.pathname === path ? "nav-link active" : "nav-link");

//   const showComingSoonAlert = () => {
//     Swal.fire({
//       title: "Coming Soon!",
//       text: "This feature will be available soon.",
//       icon: "info",
//       confirmButtonText: "OK",
//     });
//   };

//   return (
//     <>
//       <nav className={`navbar navbar-expand-lg navbar-light fixed-top ${scrolled ? "scrolled" : ""}`}>
//         <div className="container">
//           <Link className="navbar-brand" to="/">
//             <img src={logo} alt="Brand Logo" className="navbar-brand-logo" />
//           </Link>

//           <button
//             className="custom-toggler d-lg-none"
//             type="button"
//             onClick={() => setMenuOpen(!menuOpen)}
//             aria-controls="responsive-navbar-menu"
//             aria-label="Toggle navigation"
//           >
//             <FontAwesomeIcon icon={faBars} className="toggler-icon" />
//           </button>

//           {isLoggedIn && (
//             <div className="custom-icon-container d-lg-none">
//               <Link className="custom-notification-icon" aria-label="Notifications" onClick={() => setShowNotifications(true)}>
//                 <FontAwesomeIcon icon={faBell} className="notification-icon" />
//               </Link>
//               <Link to="/profile" className="custom-profile-icon" aria-label="Profile">
//                 <FontAwesomeIcon icon={faUser} className="profile-icon" />
//               </Link>
//             </div>
//           )}

//           <div className={`mobile-dropdown d-lg-none ${menuOpen ? "open" : ""}`}>
//             <ul className="navbar-nav justify-content-center align-items-center">
//               {["/", "/about", "/blog", "/courses", "/contactus"].map((path, index) => (
//                 <li className="nav-item my-2" key={index}>
//                   <Link className={getLinkClass(path)} to={path} onClick={() => setMenuOpen(false)}>
//                     {path === "/" ? "HOME" : path.toUpperCase().slice(1)}
//                   </Link>
//                 </li>
//               ))}
//             </ul>
//           </div>

//           <div className="collapse navbar-collapse d-none d-lg-block">
//             <ul className="navbar-nav ms-auto align-items-center">
//               {["/", "/about", "/blog", "/courses", "/contactus"].map((path, index) => (
//                 <li className="nav-item mx-2" key={index}>
//                   <Link className={getLinkClass(path)} to={path} aria-current={path === "/" ? "page" : undefined}>
//                     {path === "/" ? "HOME" : path.toUpperCase().slice(1)}
//                   </Link>
//                 </li>
//               ))}

//               {isLoggedIn && (
//                 <>
//                   <li className="nav-item mx-2">
//                     <Link className="nav-link d-none d-lg-block" aria-label="Notifications" onClick={() => setShowNotifications(true)}>
//                       NOTIFICATION
//                     </Link>
//                     <Link className="nav-link d-lg-none" aria-label="Notifications" onClick={() => setShowNotifications(true)}>
//                       <FontAwesomeIcon icon={faBell} />
//                     </Link>
//                   </li>
//                   <li className="nav-item dropdown mx-2 d-none d-lg-block">
//                     <Dropdown>
//                       <Dropdown.Toggle className="home-more">More</Dropdown.Toggle>
//                       <Dropdown.Menu>
//                         <Dropdown.Item as={Link} to="/profile">
//                           <FontAwesomeIcon icon={faUser} /> Profile
//                         </Dropdown.Item>
//                         <Dropdown.Item as={Link} to="/settings" onClick={showComingSoonAlert}>
//                           <FontAwesomeIcon icon={faCog} /> Settings
//                         </Dropdown.Item>
//                         <Dropdown.Item as={Link} to="/help" onClick={showComingSoonAlert}>
//                           <FontAwesomeIcon icon={faQuestionCircle} /> Help
//                         </Dropdown.Item>
//                       </Dropdown.Menu>
//                     </Dropdown>
//                   </li>
//                 </>
//               )}
//             </ul>
//           </div>
//         </div>
//       </nav>

//       <Modal show={showNotifications} onHide={() => setShowNotifications(false)} className="home-notify-modal">
//         <Modal.Header closeButton>
//           <Modal.Title>Notifications</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           {notifications.length > 0 ? (
//             notifications.map((notification, index) => (
//               <div key={index} className="notification-item">
//                 <FontAwesomeIcon icon={faComment} className="notification-icon" />
//                 <div className="notification-content">
//                   <div className="notification-message">{notification.message}</div>
//                   <div className="notification-time text-muted">{notification.time || "Just now"}</div>
//                 </div>
//               </div>
//             ))
//           ) : (
//             <div>No new notifications</div>
//           )}
//         </Modal.Body>
//       </Modal>
//     </>
//   );
// }

// export default Navbar;
