import React, { useContext, useEffect, useState } from "react";
import { Button, List, Input, Form } from "antd";
import Swal from "sweetalert2";
import "../../styles/pages/adminBatches.scss";
import { HttpServiceContext } from "../../config/httpContext";

const AdminBatches = ({ courseId, onClose }) => {
  const [batches, setBatches] = useState([]);
  const [selectedBatchId, setSelectedBatchId] = useState(null);
  const [editedBatch, setEditedBatch] = useState(null);
  const [originalBatch, setOriginalBatch] = useState(null);
  const [newBatch, setNewBatch] = useState({
    batch_name: "",
    batch_date: "",
    start_time: "",
    end_time: "",
    max_students: "",
    course_name: "",
  });
  const [showAddBatchForm, setShowAddBatchForm] = useState(false);

  const httpService = useContext(HttpServiceContext);

  useEffect(() => {
    if (courseId) {
      fetchBatches();
    }
  }, [courseId]);

  async function fetchBatches() {
    try {
      const result = await httpService.getBatches(courseId);
      if (result && Array.isArray(result.Batches)) {
        setBatches(result.Batches);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Unexpected data format",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error fetching batches",
        text: error.message,
      });
    }
  }

  async function saveEditedBatch() {
    try {
      // Check if there are no changes,.
      if (JSON.stringify(originalBatch) === JSON.stringify(editedBatch)) {
        Swal.fire({
          icon: "error",
          title: "No changes made",
          text: "You haven't made any changes to the batch.",
        });
        return;
      }

      const result = await httpService.editBatches(editedBatch);
      if (result) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Batch updated successfully",
        });
        setBatches(batches.map((batch) => (batch.batch_id === editedBatch.batch_id ? editedBatch : batch)));
        setEditedBatch(null);
        setSelectedBatchId(null);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error updating batch",
        text: error.message,
      });
    }
  }

  async function deleteBatch(batchId) {
    try {
      await httpService.deleteBatch(batchId);
      Swal.fire({
        icon: "success",
        title: "Deleted!",
        text: "Batch deleted successfully",
      });
      setBatches(batches.filter((batch) => batch.batch_id !== batchId));
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error deleting batch",
        text: error.message,
      });
    }
  }

  async function addNewBatch() {
    try {
      // Validate new batch input
      if (!newBatch.batch_name || !newBatch.batch_date || !newBatch.start_time || !newBatch.end_time || !newBatch.max_students) {
        Swal.fire({
          icon: "error",
          title: "Incomplete data",
          text: "Please fill in all the fields.",
        });
        return;
      }

      const courseName = batches.length > 0 ? batches[0].course_name : "";
      const batchData = {
        batch_name: newBatch.batch_name,
        course_id: courseId,
        batch_date: newBatch.batch_date,
        start_time: newBatch.start_time,
        end_time: newBatch.end_time,
        max_students: newBatch.max_students,
        course_name: courseName,
      };

      const result = await httpService.addBatch(batchData);
      if (result) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Batch added successfully",
        });
        setBatches([...batches, batchData]);
        setNewBatch({
          batch_name: "",
          batch_date: "",
          start_time: "",
          end_time: "",
          max_students: "",
        });
        setShowAddBatchForm(false);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error adding new batch",
        text: error.message,
      });
    }
  }

  const filteredBatches = batches.filter((batch) => batch.course_id === courseId);

  const handleEditClick = (batchId) => {
    setSelectedBatchId(batchId);
    const batch = filteredBatches.find((b) => b.batch_id === batchId);
    setEditedBatch(batch);
    setOriginalBatch(batch);
  };

  const handleSaveChanges = () => {
    saveEditedBatch();
  };

  const handleCancelEdit = () => {
    setEditedBatch(null);
    setSelectedBatchId(null);
  };

  return (
    <div className="admin-batches">
      <h3>Batches for Course ID: {courseId || "No Course Selected"}</h3>
      {filteredBatches.length > 0 ? (
        filteredBatches.map((batch) => (
          <div key={batch.batch_id}>
            <List.Item>
              <List.Item.Meta
                title={batch.batch_name}
                description={`Date: ${batch.batch_date}, Time: ${batch.start_time} - ${batch.end_time}, Max Students: ${batch.max_students}`}
              />
              <Button onClick={() => handleEditClick(batch.batch_id)}>Edit</Button>
              <Button danger onClick={() => deleteBatch(batch.batch_id)} style={{ marginLeft: "10px" }}>
                Delete
              </Button>
            </List.Item>
            {selectedBatchId === batch.batch_id && editedBatch && (
              <div className="batch-edit-form">
                <Form layout="vertical">
                  <Form.Item label="Batch Name">
                    <Input value={editedBatch.batch_name} onChange={(e) => setEditedBatch({ ...editedBatch, batch_name: e.target.value })} />
                  </Form.Item>
                  <Form.Item label="Batch Date">
                    <Input
                      type="date"
                      value={editedBatch.batch_date.slice(0, 10)}
                      onChange={(e) => setEditedBatch({ ...editedBatch, batch_date: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item label="Start Time">
                    <Input
                      type="time"
                      value={editedBatch.start_time}
                      onChange={(e) => setEditedBatch({ ...editedBatch, start_time: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item label="End Time">
                    <Input type="time" value={editedBatch.end_time} onChange={(e) => setEditedBatch({ ...editedBatch, end_time: e.target.value })} />
                  </Form.Item>
                  <Form.Item label="Max Students">
                    <Input
                      type="number"
                      value={editedBatch.max_students}
                      onChange={(e) =>
                        setEditedBatch({
                          ...editedBatch,
                          max_students: parseInt(e.target.value, 10),
                        })
                      }
                    />
                  </Form.Item>
                  <Button type="primary" onClick={handleSaveChanges}>
                    Save Changes
                  </Button>
                  <Button type="default" onClick={handleCancelEdit}>
                    Cancel
                  </Button>
                </Form>
              </div>
            )}
          </div>
        ))
      ) : (
        <p>No batches found for this course.</p>
      )}

      <Button type="primary" onClick={() => setShowAddBatchForm(!showAddBatchForm)} style={{ marginTop: "20px" }}>
        {showAddBatchForm ? "Hide Add Batch Form" : "Add Batches"}
      </Button>

      {showAddBatchForm && (
        <div className="batch-add-form" style={{ marginTop: "20px" }}>
          <h3>Add New Batch</h3>
          <Form layout="vertical">
            <Form.Item label="Batch Name">
              <Input value={newBatch.batch_name} onChange={(e) => setNewBatch({ ...newBatch, batch_name: e.target.value })} />
            </Form.Item>
            <Form.Item label="Batch Date">
              <Input type="date" value={newBatch.batch_date} onChange={(e) => setNewBatch({ ...newBatch, batch_date: e.target.value })} />
            </Form.Item>
            <Form.Item label="Start Time">
              <Input type="time" value={newBatch.start_time} onChange={(e) => setNewBatch({ ...newBatch, start_time: e.target.value })} />
            </Form.Item>
            <Form.Item label="End Time">
              <Input type="time" value={newBatch.end_time} onChange={(e) => setNewBatch({ ...newBatch, end_time: e.target.value })} />
            </Form.Item>
            <Form.Item label="Max Students">
              <Input
                type="number"
                value={newBatch.max_students}
                onChange={(e) => setNewBatch({ ...newBatch, max_students: parseInt(e.target.value, 10) })}
              />
            </Form.Item>
            <Button type="primary" onClick={addNewBatch}>
              Add Batch
            </Button>
          </Form>
        </div>
      )}

      <Button type="default" onClick={onClose} style={{ marginTop: "10px" }}>
        Close
      </Button>
    </div>
  );
};

export default AdminBatches;
