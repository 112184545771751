import React from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import Navbar from "./navbar";
import Footer from "./home/vrddhim-footer";

const TermsAndConditions = () => {
  window.scrollTo(0, 0);

  return (
    <section className="tandc" style={{ backgroundColor: "#ffff" }}>
      <Navbar />
      <div className="container">
        <div className="container my-3">
          <h2 className="elegant-heading text-center">Terms and Conditions</h2>

          <div className="card shadow-lg mb-4">
            <div className="card-body">
              <h4 className="text-secondary mb-3">
                <i className="bi bi-person-fill"></i> 1. Introduction
              </h4>
              <p className="graceful-paragraph text-dark">
                These Terms and Conditions govern your use of the <strong>Vrddhim</strong> platform. By registering or accessing the platform, you are
                agreeing to these terms, so please read them carefully. If you disagree with any part of the terms, you must not proceed with the
                registration or use of the platform.
              </p>
              <p className="graceful-paragraph text-dark">
                We reserve the right to modify these terms at any time, and you agree to review them periodically. Continuing to use the platform
                after any updates indicates acceptance of the revised terms.
              </p>
            </div>
          </div>

          <div className="card shadow-lg mb-4">
            <div className="card-body">
              <h4 className="text-secondary mb-3">
                <i className="bi bi-box-arrow-in-right"></i> 2. Account Creation and Responsibilities
              </h4>
              <ul className="list-group mb-4">
                <li className="list-group-item">
                  <strong className="badge bg-primary me-2">1</strong>
                  When creating an account, you must provide accurate information such as Username/Email ID, Password, and Phone Number. Other
                  optional details may include Date of Birth and Location.
                </li>
                <li className="list-group-item">
                  <strong className="badge bg-primary me-2">2</strong>
                  You are responsible for maintaining the confidentiality of your login credentials. Any activity that occurs under your account is
                  your responsibility.
                </li>
                <li className="list-group-item">
                  <strong className="badge bg-primary me-2">3</strong>
                  Users must ensure that the provided email and phone number remain valid for future communications.
                </li>
                <li className="list-group-item">
                  <strong className="badge bg-primary me-2">4</strong>
                  You may not create multiple accounts for fraudulent purposes or abuse the functionality of the platform.
                </li>
                <li className="list-group-item">
                  <strong className="badge bg-primary me-2">5</strong>
                  You must notify us immediately of any unauthorized use of your account or security breach.
                </li>
              </ul>
            </div>
          </div>

          <div className="card shadow-lg mb-4">
            <div className="card-body">
              <h4 className="text-secondary mb-3">
                <i className="bi bi-laptop"></i> 3. Platform Usage Guidelines
              </h4>
              <ul className="list-group mb-4">
                <li className="list-group-item">
                  <strong className="badge bg-success me-2">1</strong>
                  <strong>Fair Usage:</strong> Users should not engage in activities that cause harm or disruption to the platform.
                </li>
                <li className="list-group-item">
                  <strong className="badge bg-success me-2">2</strong>
                  <strong>Multiple Logins:</strong> The system allows only one active session per user. If you log in from another browser, your
                  previous session will be automatically logged out.
                </li>
                <li className="list-group-item">
                  <strong className="badge bg-success me-2">3</strong>
                  <strong>Prohibited Activities:</strong> Activities such as hacking, unauthorized access, or exploiting platform vulnerabilities are
                  strictly prohibited and will lead to termination of the account.
                </li>
                <li className="list-group-item">
                  <strong className="badge bg-success me-2">4</strong>
                  <strong>Content Guidelines:</strong> The user is responsible for ensuring that any content they submit (e.g., comments, reviews)
                  complies with laws and does not contain any harmful or offensive materials.
                </li>
              </ul>
            </div>
          </div>

          <div className="card shadow-lg mb-4">
            <div className="card-body">
              <h4 className="text-secondary mb-3">
                <i className="bi bi-credit-card"></i> 4. Payment and Subscription
              </h4>
              <ul className="list-group mb-4">
                <li className="list-group-item">
                  <strong className="badge bg-danger me-2">1</strong>
                  <strong>Payment Gateway:</strong> All payments made for courses or services are processed via secure payment gateways. We do not
                  store your payment information.
                </li>
                <li className="list-group-item">
                  <strong className="badge bg-danger me-2">2</strong>
                  <strong>Non-Refundable Policy:</strong> Once a course or service is purchased and accessed, the fees are non-refundable.
                </li>
                <li className="list-group-item">
                  <strong className="badge bg-danger me-2">3</strong>
                  <strong>Auto-Renewal:</strong> For subscription-based services, automatic renewal is applied unless the user cancels the
                  subscription before the renewal date.
                </li>
                <li className="list-group-item">
                  <strong className="badge bg-danger me-2">4</strong>
                  <strong>Taxes:</strong> All transactions may be subject to applicable taxes, and users are responsible for paying those taxes as
                  required by their jurisdiction.
                </li>
              </ul>
            </div>
          </div>

          <div className="card shadow-lg mb-4">
            <div className="card-body">
              <h4 className="text-secondary mb-3">
                <i className="bi bi-file-earmark-lock-fill"></i> 5. Privacy and Data Collection
              </h4>
              <ul className="list-group mb-4">
                <li className="list-group-item">
                  <strong className="badge bg-info me-2">1</strong>
                  <strong>Data Collection:</strong> We collect data related to user activities, including account creation, course enrollments,
                  purchases, and usage patterns.
                </li>
                <li className="list-group-item">
                  <strong className="badge bg-info me-2">2</strong>
                  <strong>Data Security:</strong> We employ encryption and other security measures to protect your data, but we cannot guarantee full
                  protection from all security threats.
                </li>
                <li className="list-group-item">
                  <strong className="badge bg-info me-2">3</strong>
                  <strong>Cookies:</strong> Our platform uses cookies to enhance user experience. You can choose to disable cookies, but certain
                  features may not function properly.
                </li>
                <li className="list-group-item">
                  <strong className="badge bg-info me-2">4</strong>
                  <strong>Data Sharing:</strong> We do not sell user data to third parties. However, we may share data with trusted partners for the
                  purpose of improving services or complying with legal requirements.
                </li>
              </ul>
            </div>
          </div>

          <div className="card shadow-lg mb-4">
            <div className="card-body">
              <h4 className="text-secondary mb-3">
                <i className="bi bi-check-circle-fill"></i> 6. Course Enrollment and Certificates
              </h4>
              <ul className="list-group mb-4">
                <li className="list-group-item">
                  <strong className="badge bg-primary me-2">1</strong>
                  <strong>Course Completion:</strong> Users are required to complete the entire course to receive a completion certificate.
                </li>
                <li className="list-group-item">
                  <strong className="badge bg-primary me-2">2</strong>
                  <strong>Attendance:</strong> The platform tracks user attendance for classes. Failing to attend certain sessions may result in loss
                  of certification eligibility.
                </li>
                <li className="list-group-item">
                  <strong className="badge bg-primary me-2">3</strong>
                  <strong>Certificate Issuance:</strong> Once the course is completed, the certificate will be made available for download within 3-5
                  business days.
                </li>
                <li className="list-group-item">
                  <strong className="badge bg-primary me-2">4</strong>
                  <strong>Course Retakes:</strong> If a user fails to complete the course, they may have the option to retake the course at a
                  discounted fee.
                </li>
                <li className="list-group-item">
                  <strong className="badge bg-primary me-2">5</strong>
                  <strong>Missed Sessions:</strong> Sessions missed will not not be continued in the next session.
                </li>
              </ul>
            </div>
          </div>

          <div className="card shadow-lg mb-4">
            <div className="card-body">
              <h4 className="text-secondary mb-3">
                <i className="bi bi-people-fill"></i> 7. Code of Conduct
              </h4>
              <ul className="list-group mb-4">
                <li className="list-group-item">
                  <strong className="badge bg-warning me-2">1</strong>
                  <strong>Respectful Interaction:</strong> Users must interact respectfully with instructors and peers. Harassment or inappropriate
                  behavior will not be tolerated.
                </li>
                <li className="list-group-item">
                  <strong className="badge bg-warning me-2">2</strong>
                  <strong>Copyrighted Material:</strong> Users are prohibited from sharing, distributing, or replicating any course material without
                  prior permission from the platform or instructor.
                </li>
                <li className="list-group-item">
                  <strong className="badge bg-warning me-2">3</strong>
                  <strong>Cheating:</strong> Engaging in dishonest practices during quizzes, exams, or assignments may lead to account suspension or
                  termination.
                </li>
              </ul>
            </div>
          </div>

          <div className="card shadow-lg mb-4">
            <div className="card-body">
              <h4 className="text-secondary mb-3">
                <i className="bi bi-exclamation-triangle-fill"></i> 8. Termination of Service
              </h4>
              <ul className="list-group mb-4">
                <li className="list-group-item">
                  <strong className="badge bg-danger me-2">1</strong>
                  <strong>Account Suspension:</strong> We reserve the right to suspend or terminate your account if you violate these terms, engage in
                  fraudulent activity, or disrupt the platform’s operations.
                </li>
                <li className="list-group-item">
                  <strong className="badge bg-danger me-2">2</strong>
                  <strong>Refunds for Termination:</strong> If your account is terminated due to a breach of these terms, you are not entitled to a
                  refund for any unused services or subscriptions.
                </li>
                <li className="list-group-item">
                  <strong className="badge bg-danger me-2">3</strong>
                  <strong>Reactivation:</strong> You may request reactivation of your account by contacting support, though approval is at our sole
                  discretion.
                </li>
              </ul>
            </div>
          </div>

          <div className="text-center ">
            <p className="graceful-paragraph">
              <i className="bi bi-info-circle"></i> If you have any questions or concerns regarding these Terms and Conditions, please contact our
              support team at <a href="mailto:support@vrddhim.com">support@vrddhim.com</a>.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default TermsAndConditions;
