// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cards-section {
  background: white;
}

.cards-container .card {
  background-color: rgb(234, 243, 234) !important;
  border: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 10px;
}
.cards-container .card:hover {
  transform: translateY(-10px); /* Lift effect on hover */
  box-shadow: 0 10px 20px rgba(0, 128, 0, 0.3); /* Greenish shadow */
}
.cards-container .card .card-body {
  padding: 2rem;
}
.cards-container .card .card-body .icon {
  font-size: 3rem;
  color: #595959; /* Green color for icons */
}
.cards-container .card .card-body .card-title {
  font-size: 1.75rem; /* Increased title size */
  margin-bottom: 1.5rem;
  color: #595959; /* Darker green */
  font-weight: 700;
}
.cards-container .card .card-body .card-text {
  font-size: 1rem;
  color: #4e4e4e; /* Slightly darker text color */
  line-height: 1.6;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .cards-container .card {
    margin-bottom: 20px;
  }
  .cards-container .card-title {
    font-size: 1.5rem;
  }
  .cards-container .card-text {
    font-size: 0.95rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/pages/Cards.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AAEE;EACE,+CAAA;EACA,YAAA;EACA,qDAAA;EACA,mBAAA;AACJ;AACI;EACE,4BAAA,EAAA,yBAAA;EACA,4CAAA,EAAA,oBAAA;AACN;AAEI;EACE,aAAA;AAAN;AAEM;EACE,eAAA;EACA,cAAA,EAAA,0BAAA;AAAR;AAGM;EACE,kBAAA,EAAA,yBAAA;EACA,qBAAA;EACA,cAAA,EAAA,iBAAA;EACA,gBAAA;AADR;AAIM;EACE,eAAA;EACA,cAAA,EAAA,+BAAA;EACA,gBAAA;AAFR;;AAQA,2BAAA;AACA;EACE;IACE,mBAAA;EALF;EAYA;IACE,iBAAA;EAVF;EAaA;IACE,kBAAA;EAXF;AACF","sourcesContent":[".cards-section {\r\n  background: #ffff;\r\n}\r\n.cards-container {\r\n  .card {\r\n    background-color: rgb(234, 243, 234) !important;\r\n    border: none;\r\n    transition: transform 0.3s ease, box-shadow 0.3s ease;\r\n    border-radius: 10px;\r\n\r\n    &:hover {\r\n      transform: translateY(-10px); /* Lift effect on hover */\r\n      box-shadow: 0 10px 20px rgba(0, 128, 0, 0.3); /* Greenish shadow */\r\n    }\r\n\r\n    .card-body {\r\n      padding: 2rem;\r\n\r\n      .icon {\r\n        font-size: 3rem;\r\n        color: #595959; /* Green color for icons */\r\n      }\r\n\r\n      .card-title {\r\n        font-size: 1.75rem; /* Increased title size */\r\n        margin-bottom: 1.5rem;\r\n        color: #595959; /* Darker green */\r\n        font-weight: 700;\r\n      }\r\n\r\n      .card-text {\r\n        font-size: 1rem;\r\n        color: #4e4e4e; /* Slightly darker text color */\r\n        line-height: 1.6;\r\n      }\r\n    }\r\n  }\r\n}\r\n\r\n/* Responsive Adjustments */\r\n@media (max-width: 768px) {\r\n  .cards-container .card {\r\n    margin-bottom: 20px;\r\n  }\r\n\r\n  // .cards-container .icon {\r\n  //   font-size: 2.5rem;\r\n  // }\r\n\r\n  .cards-container .card-title {\r\n    font-size: 1.5rem;\r\n  }\r\n\r\n  .cards-container .card-text {\r\n    font-size: 0.95rem;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
