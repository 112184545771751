import React from "react";
import styles from "../../styles/pages/course-overview-card.module.scss";
import { FaClock } from "react-icons/fa";

const SubjectCard = ({ subject, image, department, progress, daysLeft, profiles }) => {
  return (
    <div className={styles.card}>
      <div className={styles.imageContainer}>
        <img src={image} alt={subject} className={styles.image} />
      </div>
      <div className={styles.content}>
        <h3 className={styles.subject}>{subject}</h3>
        <p className={styles.department}>{department}</p>
        <div className={styles.progressInfo}>
          <span>Progress</span>
          <span className={styles.progressPercentage}>{progress}%</span>
        </div>
        <div className={styles.progressBar}>
          <div className={styles.progress} style={{ width: `${progress}%` }}></div>
        </div>
        <div className={styles.footer}>
          <div className={styles.daysLeft}>
            <FaClock className={styles.clockIcon} />
            <span>{daysLeft} Days Left</span>
          </div>
          <div className={styles.profiles}>
            {(profiles || []).map((profile, index) => (
              <img key={index} src={profile} alt={`Profile ${index + 1}`} className={styles.profileImage} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubjectCard;
