// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
}
.payment-container h2 {
  color: #333;
  margin-bottom: 20px;
  font-size: 28px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}
.payment-container button {
  background-color: #3399cc;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.payment-container button:hover {
  background-color: #287a9b;
}
.payment-container button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.payment-container .loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3399cc; /* Blue */
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin-right: 10px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/pages/Payments.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;EACA,yBAAA;AACF;AACE;EACE,WAAA;EACA,mBAAA;EACA,eAAA;EACA,yBAAA;EACA,qBAAA;AACJ;AAEE;EACE,yBAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,eAAA;EACA,sCAAA;AAAJ;AAEI;EACE,yBAAA;AAAN;AAGI;EACE,sBAAA;EACA,mBAAA;AADN;AAME;EACE,yBAAA,EAAA,eAAA;EACA,6BAAA,EAAA,SAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,kCAAA;EACA,kBAAA;AAJJ;AAOE;EACE;IACE,uBAAA;EALJ;EAOE;IACE,yBAAA;EALJ;AACF","sourcesContent":[".payment-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n  background-color: #f5f5f5;\n\n  h2 {\n    color: #333;\n    margin-bottom: 20px;\n    font-size: 28px;\n    text-transform: uppercase;\n    letter-spacing: 1.5px;\n  }\n\n  button {\n    background-color: #3399cc;\n    color: white;\n    padding: 10px 20px;\n    border: none;\n    border-radius: 5px;\n    font-size: 18px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n\n    &:hover {\n      background-color: #287a9b;\n    }\n\n    &:disabled {\n      background-color: #ccc;\n      cursor: not-allowed;\n    }\n  }\n\n  // Loader style for the processing state\n  .loader {\n    border: 4px solid #f3f3f3; /* Light grey */\n    border-top: 4px solid #3399cc; /* Blue */\n    border-radius: 50%;\n    width: 24px;\n    height: 24px;\n    animation: spin 1s linear infinite;\n    margin-right: 10px;\n  }\n\n  @keyframes spin {\n    0% {\n      transform: rotate(0deg);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
