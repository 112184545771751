import React from "react";
import { Link } from "react-router-dom";
import "../../styles/pages/aboutbanner.scss";

const VrddhimStory = () => {
  return (
    <div className="vrddhim-about-banner">
      <div className="container story-container background-primary">
        <h2 className="text-center elegant-heading"> Story of Vrddhim </h2>
        <div className="row align-items-center mt-3">
          {/* Left Section */}
          <div className="col-md-6">
            <img src="https://www.hollandhelix.com/content/uploads/shining-book-5.jpg" alt="Vrddhim Experience" className="img-fluid" />
          </div>

          {/* Right Section */}
          <div className="col-md-6 text-center text-md-start">
            <span className="heading-span mb-1">A New Era of Learning and Growth</span>
            <p className="lead mb-4">
              Vrdhhim is a Sanskrit word which means growth &amp; progress. At our academy, we seek to keep this balance of learning &amp;
              development. Vrddhim offers unique real- time learning experiences in the most comfortable atmosphere for you to get the best and
              balance your learning journey with great results.
            </p>
            <Link to="/about">
              <button className="btn btn-primary px-4 py-2">About Us</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VrddhimStory;
