// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-page {
  background-color: #f8f9fa;
  min-height: 100vh;
}

.profile-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 20px;
  transition: transform 0.2s;
}

.profile-user-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #50c654;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: white;
  margin: 0 auto 20px;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.avatar-gloss {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  filter: blur(5px);
}

.profile-details {
  padding: 10px;
}

.profile-name {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}

.profile-info {
  font-size: 1rem;
  color: #666;
  margin: 5px 0;
}

.profile-actions {
  margin-top: 20px;
}

.btn-gloss {
  background: #50c654;
  color: white;
  transition: background 0.3s;
}

.btn-gloss:hover {
  background: #38ab3c;
}

.loading-state {
  margin-top: 50px;
}`, "",{"version":3,"sources":["webpack://./src/components/Profile.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,iBAAA;AACF;;AAEA;EACE,sBAAA;EACA,mBAAA;EACA,wCAAA;EACA,kBAAA;EACA,aAAA;EACA,0BAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,kBAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,uCAAA;AACF;;AAEA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,oCAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,iBAAA;EACA,iBAAA;EACA,WAAA;AACF;;AAEA;EACE,eAAA;EACA,WAAA;EACA,aAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,mBAAA;EACA,YAAA;EACA,2BAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".profile-page {\n  background-color: #f8f9fa;\n  min-height: 100vh;\n}\n\n.profile-card {\n  background-color: #fff;\n  border-radius: 10px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  text-align: center;\n  padding: 20px;\n  transition: transform 0.2s;\n}\n\n.profile-user-avatar {\n  width: 100px;\n  height: 100px;\n  border-radius: 50%;\n  background-color: #50c654;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 24px;\n  color: white;\n  margin: 0 auto 20px;\n  position: relative;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);\n}\n\n.avatar-gloss {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  border-radius: 50%;\n  background: rgba(255, 255, 255, 0.2);\n  filter: blur(5px);\n}\n\n.profile-details {\n  padding: 10px;\n}\n\n.profile-name {\n  font-size: 1.5rem;\n  font-weight: bold;\n  color: #333;\n}\n\n.profile-info {\n  font-size: 1rem;\n  color: #666;\n  margin: 5px 0;\n}\n\n.profile-actions {\n  margin-top: 20px;\n}\n\n.btn-gloss {\n  background: #50c654;\n  color: white;\n  transition: background 0.3s;\n}\n\n.btn-gloss:hover {\n  background: darken(#50c654, 10%);\n}\n\n.loading-state {\n  margin-top: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
