export const subjects = [
    {
      subject: "English in 100 days!",
      image: "https://images.unsplash.com/photo-1543109740-4bdb38fda756?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8Z3JhbW1hciUyMGVuZ2xpc2h8ZW58MHx8MHx8fDA%3D", 
      department: "Grammar",
      progress: 75,
      daysLeft: 3,
      profiles: [
        "https://images.pexels.com/photos/445109/pexels-photo-445109.jpeg?auto=compress&cs=tinysrgb&w=600", 
        "https://images.pexels.com/photos/845457/pexels-photo-845457.jpeg?auto=compress&cs=tinysrgb&w=600", 
        "https://images.pexels.com/photos/927451/pexels-photo-927451.jpeg?auto=compress&cs=tinysrgb&w=600", 
      ],
    },
    {
      subject: "Mastery in english",
      image: "https://images.unsplash.com/photo-1457369804613-52c61a468e7d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8Z3JhbW1hciUyMGVuZ2xpc2h8ZW58MHx8MHx8fDA%3D",
      department: "Rhymes",
      progress: 85,
      daysLeft: 4,
      profiles: [
        "https://images.pexels.com/photos/886285/pexels-photo-886285.jpeg?auto=compress&cs=tinysrgb&w=600", 
        "https://images.pexels.com/photos/819530/pexels-photo-819530.jpeg?auto=compress&cs=tinysrgb&w=600", 
        "https://images.pexels.com/photos/354951/pexels-photo-354951.jpeg?auto=compress&cs=tinysrgb&w=600", 
      ],
    },
    
  ];
  