// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.services {
  background-color: #f0f9f0;
  padding: 50px 0;
}
.services .service-card {
  background-color: #f5fdf5;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 128, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, border 0.3s ease-in-out;
  border: 2px solid transparent;
}
.services .service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 30px rgba(0, 128, 0, 0.3);
  border: 2px solid #2d572c;
}
.services .service-card .icon {
  margin-bottom: 20px;
}
.services .service-card .icon img {
  width: 100px;
  height: auto;
  filter: drop-shadow(0px 5px 10px rgba(0, 128, 0, 0.2));
  transition: transform 0.3s ease-in-out;
}
.services .service-card .icon img:hover {
  transform: scale(1.1);
}
.services .service-card h4 {
  font-size: 24px;
  color: #000;
  margin-bottom: 10px;
}
.services .service-card p {
  font-size: 16px;
  color: #000;
}
@media (max-width: 768px) {
  .services .service-card {
    padding: 15px;
  }
  .services .service-card h4 {
    font-size: 20px;
  }
  .services .service-card p {
    font-size: 14px;
  }
  .services .service-card .icon img {
    width: 80px;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/pages/whychoose.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,eAAA;AACF;AACE;EACE,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,2CAAA;EACA,kBAAA;EACA,4FAAA;EACA,6BAAA;AACJ;AACI;EACE,4BAAA;EACA,2CAAA;EACA,yBAAA;AACN;AAEI;EACE,mBAAA;AAAN;AAEM;EACE,YAAA;EACA,YAAA;EACA,sDAAA;EACA,sCAAA;AAAR;AAEQ;EACE,qBAAA;AAAV;AAKI;EACE,eAAA;EACA,WAAA;EACA,mBAAA;AAHN;AAMI;EACE,eAAA;EACA,WAAA;AAJN;AAQE;EACE;IACE,aAAA;EANJ;EAQI;IACE,eAAA;EANN;EASI;IACE,eAAA;EAPN;EAUI;IACE,WAAA;EARN;AACF","sourcesContent":[".services {\n  background-color: #f0f9f0;\n  padding: 50px 0;\n\n  .service-card {\n    background-color: #f5fdf5;\n    padding: 20px;\n    border-radius: 12px;\n    box-shadow: 0 4px 20px rgba(0, 128, 0, 0.1);\n    text-align: center;\n    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, border 0.3s ease-in-out;\n    border: 2px solid transparent;\n\n    &:hover {\n      transform: translateY(-10px);\n      box-shadow: 0 8px 30px rgba(0, 128, 0, 0.3);\n      border: 2px solid #2d572c; // Green border highlight\n    }\n\n    .icon {\n      margin-bottom: 20px;\n\n      img {\n        width: 100px;\n        height: auto;\n        filter: drop-shadow(0px 5px 10px rgba(0, 128, 0, 0.2));\n        transition: transform 0.3s ease-in-out;\n\n        &:hover {\n          transform: scale(1.1);\n        }\n      }\n    }\n\n    h4 {\n      font-size: 24px;\n      color: #000; // Changed to black\n      margin-bottom: 10px;\n    }\n\n    p {\n      font-size: 16px;\n      color: #000; // Changed to black\n    }\n  }\n\n  @media (max-width: 768px) {\n    .service-card {\n      padding: 15px;\n\n      h4 {\n        font-size: 20px;\n      }\n\n      p {\n        font-size: 14px;\n      }\n\n      .icon img {\n        width: 80px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
